import { useEffect } from "react";
import { DatePicker, Radio } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import AddCreativeContributor from "./Modal/AddCreativeContributor";
import CoAuthorTable from "./CoAuthorsTable";
import Genre from "./MultipleOptions/Genre";
import Subgenre from "./MultipleOptions/SubGenre";
import "./songDetailStyle.css";
import WorkType from "./MultipleOptions/WorkType";
import Role from "./MultipleOptions/common/commonFields/Role";
import PublishserResidency from "./MultipleOptions/common/publisherFields/PublisherResidency";
import PublisherName from "./MultipleOptions/common/publisherFields/PublisherName";
import PublisherISNI from "./MultipleOptions/common/publisherFields/PublisherISNI";
import PublisherIPI from "./MultipleOptions/common/publisherFields/PublisherIPI";
import WorksTitleTooltip from "./MultipleOptions/common/Tooltip/WorksTitleTooltip";
import CreationLocationTooltip from "./MultipleOptions/common/Tooltip/CreationLocationTooltip";
import { ErrorMessage } from "@hookform/error-message";
import { useForm, Controller, FormProvider } from "react-hook-form";
import ISWCTooltip from "./MultipleOptions/common/Tooltip/ISWCTooltip";
import languages from "./languages";
import ExplicitContent from "./MultipleOptions/common/commonFields/ExplicitContent";
import InputMask from "react-input-mask";

export default function SongDetail() {
  const methods = useForm({
    defaultValues: {
      iswc: "",
      publisher: {
        isni: "",
        ipi: "",
      },
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = methods;

  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setSubmitting(true);
  };

  //Contributor's
  const [showCoAuthorModal, setShowCoAuthorModal] = useState(false);

  const coAuthorModalHandler = () => {
    setShowCoAuthorModal(!showCoAuthorModal);
  };

  const [coAuthor, setCoAuthor] = useState([]);

  const addAuthor = (coAuthorValue) => {
    const data = [...coAuthor, coAuthorValue];
    setCoAuthor(data);
  };

  const [percentLeft, setPercentLeft] = useState([100]);

  useEffect(() => {
    if (!coAuthor.length) return;
    const percentUsed = coAuthor.reduce(
      (acc, val) => parseFloat(acc) + parseFloat(val.ownership),
      0
    );
    setPercentLeft(100 - percentUsed);
  }, [coAuthor]);

  //worksTitleTooltip
  const [worksTitleTooltip, setWorksTitleTooltip] = useState(false);
  const showWorksTitleTooltip = () => {
    setWorksTitleTooltip(true);
  };
  const closeWorksTitleTooltip = () => {
    setWorksTitleTooltip(false);
  };

  //CreationLocationTooltip

  const [creationLocationTooltip, setCreationLocationTooltip] = useState(false);
  const showCreationLocationTooltip = () => {
    setCreationLocationTooltip(true);
  };
  const closeCreationLocationTooltip = () => {
    setCreationLocationTooltip(false);
  };

  //ISWCTooltip
  const [iswcTooltip, setIswcTooltip] = useState(false);

  const showIswcTooltip = () => {
    setIswcTooltip(true);
  };
  const closeIswcTooltip = () => {
    setIswcTooltip(false);
  };

  const isSongReleased = watch("isSongReleased");

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" rounded-lg ">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-gray-300 border bg-white rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-yellow-500 hover:text-yellow-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          // onChange={onChangeFile}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      .m4a .mp3 .flac .wav up to 10MB
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="worksTitle"
                  className=" block text-sm font-medium leading-5 text-gray-700"
                >
                  Works Title*
                  <WorksTitleTooltip
                    worksTitleTooltip={worksTitleTooltip}
                    showWorksTitleTooltip={showWorksTitleTooltip}
                    closeWorksTitleTooltip={closeWorksTitleTooltip}
                  />
                </label>
                <div className="mt-1 rounded-md ">
                  <input
                    {...register("worksTitle", {
                      required: "This field is mandatory",
                    })}
                    id="worksTitle"
                    className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="worksTitle"
                    className="m-0 p-0 text-red-500"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="dateCreated"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Date Created*
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="dateCreated"
                    rules={{
                      required: {
                        value: true,
                        message: "This field is mandatory",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        style={{ borderRadius: "8px" }}
                        selected={value}
                        onChange={onChange}
                        className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 h-10"
                      />
                    )}
                  />

                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="dateCreated"
                    className="mb-0 text-red-500"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="creationLocation"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Creation Location*
                  <CreationLocationTooltip
                    showCreationLocationTooltip={showCreationLocationTooltip}
                    creationLocationTooltip={creationLocationTooltip}
                    closeCreationLocationTooltip={closeCreationLocationTooltip}
                  />
                </label>
                <div className="mt-1 rounded-md ">
                  <input
                    {...register("creationLocation", {
                      required: "This field is mandatory",
                    })}
                    id="creationLocation"
                    className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="creationLocation"
                    className="m-0 p-0 text-red-500"
                  />
                </div>
              </div>

              {/* <ISWC /> */}
              <section className="sm:col-span-3">
                <label
                  htmlFor="iswc"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  ISWC#
                  <ISWCTooltip
                    showIswcTooltip={showIswcTooltip}
                    closeIswcTooltip={closeIswcTooltip}
                    iswcTooltip={iswcTooltip}
                  />
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <Controller
                    name="iswc"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputMask
                        mask="T-999999999-9"
                        value={value}
                        onChange={onChange}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="tel"
                            className="focus:ring-transparent focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </div>
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="iswc"
                  className="mb-0 text-red-500"
                />
              </section>

              <div className="sm:col-span-3 sm:row-span-2">
                <label
                  htmlFor="lyrics"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Lyrics
                </label>
                <div className="mt-1 rounded-md">
                  <textarea
                    name="lyrics"
                    {...register("lyrics")}
                    className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    rows="6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lyricsLanguage"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Lyrics Language
                </label>
                <div className="mt-1 rounded-md">
                  <select
                    id="lyricsLang"
                    {...register("lyricsLanguage")}
                    autoComplete="lyricsLang"
                    defaultValue="en"
                    // onChange={(e) => props.setAns1(e.target.value)}
                    className=" mt-1 focus:ring-transparent focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    {languages.map((lang) => (
                      <option key={lang.code} value={lang.code}>
                        {lang.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* <LyricsLang /> */}
              <Role fieldName="role" />

              {/* <ExplicitContent /> */}
              <ExplicitContent />

              <WorkType />

              <Genre />

              <Subgenre />

              <PublisherName fieldName="publisher.name" />

              <PublisherISNI
                title="Publihser-ISNI#"
                fieldName="publisher.isni"
              />

              <PublisherIPI title="Publisher-IPI#" fieldName="publisher.ipi" />

              <PublishserResidency fieldName="publisher.isQuebecBased" />

              <div className="sm:col-span-3 sm:row-span-2">
                <label
                  htmlFor="headOfficeAddress"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Head Office Address
                </label>
                <div className="mt-1 rounded-md">
                  <textarea
                    name="headOfficeAddress"
                    {...register("headOfficeAddress")}
                    className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    rows="6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  Is the song already been released?
                </label>
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="isSongReleased"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Radio.Group
                        onBlur={onBlur}
                        onChange={onChange}
                        selected={value}
                        inputRef={ref}
                        style={{ width: "100%", fontSize: "14px" }}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}> No</Radio>
                      </Radio.Group>
                    )}
                  />
                </div>
              </div>

              {isSongReleased && (
                <div className="sm:col-span-3">
                  <label
                    htmlFor="release Date"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Release Date
                  </label>
                  <div className="mt-1">
                    <Controller
                      control={control}
                      name="release Date"
                      rules={{
                        required: {
                          value: true,
                          message: "This field is mandatory",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          style={{ borderRadius: "8px" }}
                          selected={value}
                          onChange={onChange}
                          className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 h-10"
                        />
                      )}
                    />

                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="release Date"
                      className="mb-0 text-red-500"
                    />
                  </div>
                </div>
              )}

              {/* <UnLocode fieldName="recordingHeadOffice" /> */}
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="border-t border-gray-300 my-6"></div>

      <div className="mt-3">
        <div className="flex justify-between">
          <p className="text-xl font-semibold">Contributors</p>

          <span className="inline-flex rounded-md">
            <input
              onClick={coAuthorModalHandler}
              className="bg-gray-200 shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:ring-transparent focus:border-blue-300 shadow:none-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
              type="button"
              value="Add"
            />
          </span>
        </div>
        <CoAuthorTable coAuthor={coAuthor} percentLeft={percentLeft} />
      </div>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-end rounded-b space-x-3">
            <span className="inline-flex rounded-md">
              <input
                type="submit"
                className="bg-gray-200 shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:ring-transparent focus:border-blue-300 shadow:none-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                value="Save as draft"
                disabled={isSubmitting}
              />
            </span>
            <span className="inline-flex rounded-md">
              <input
                type="submit"
                className="bg-gray-200 shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:ring-transparent focus:border-blue-300 shadow:none-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                value="Checkout"
                disabled={isSubmitting}
              />
            </span>
          </div>
        </form>
      </FormProvider>

      {showCoAuthorModal ? (
        <AddCreativeContributor
          handleModal={coAuthorModalHandler}
          setCoAuthor={addAuthor}
          percentLeft={percentLeft}
        />
      ) : null}
    </div>
  );
}
