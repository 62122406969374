import React from "react";
import { useFormContext } from "react-hook-form";

export default function AdminNotes() {
  const { register } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Admin Notes
        </h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="adminNotes"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Notes
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("adminNotes", { required: false })}
              id="adminNotes"
              autoComplete="adminNotes"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
