import { EnumField } from "./../Components/CommonArea/CommonComponents/EnumFlagFormField/model";
import { AccountType } from "./userStore";

interface ProfilePreferences extends EnumField {
  accountTypes: AccountType[];
}

export const profilePreferences: ProfilePreferences[] = [
  {
    title: "Display Artistic Name",
    description:
      "Displays Artistic Name in public profile (instead of First and Last Name).",
    accountTypes: ["Member"],
  },
  {
    title: "Display Profile Picture",
    description: "Displays photo in public profile.",
    accountTypes: ["Member", "Associate"],
  },
  {
    title: "Display Songs",
    description: "Display SongLibrary songs in public profile.",
    accountTypes: ["Member", "Associate"],
  },
  {
    title: "Display Contact Email",
    description: "Displays contact email in public profile.",
    accountTypes: ["Member"],
  },
  {
    title: "Display Artistic Information",
    description: "Displays Artistic Information in public profile.",
    accountTypes: ["Member"],
  },
  {
    title: "Display Interview Questions",
    description: "Displays interview questions and answers in public profile.",
    accountTypes: ["Member"],
  },
  {
    title: "Display Social Media",
    description: "Displays social media handles in public profile.",
    accountTypes: ["Member", "Associate"],
  },
  {
    title: "Display Pronouns",
    description: "Displays Pronouns in public profile.",
    accountTypes: ["Member"],
  },
  {
    title: "Display Manager Information",
    description: "Displays Manager Information in public profile.",
    accountTypes: ["Member"],
  },
  {
    title: "Display Publisher Information",
    description: "Displays Publisher Information in public profile.",
    accountTypes: ["Member"],
  },
  {
    title: "Display Associate Information",
    description: "Displays Associate Information in public profile.",
    accountTypes: ["Associate"],
  },
  {
    title: "Display Contact Information",
    description:
      "Displays contact email and contact person information in public profile.",
    accountTypes: ["Associate"],
  },
];
