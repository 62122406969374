import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function IPITooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="It has the pattern H-NNNNNNNNN-C, where H is a header, N is a nine-digits identification number, C is a single number check digit."
    >
      <i className="far fa-info-circle" onClick={props.showIpiTooltip}></i>

      <Drawer
        title={props.title}
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeIpiTooltip}
        visible={props.ipiTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          It has the pattern H-NNNNNNNNN-C, where H is a header, N is a
          nine-digits identification number, C is a single number check digit.
        </p>
        <h1>Description</h1>
        <p>
          The Interested Parties Information (IPI) code is CISAC’s proprietary
          identifier administered by the Swiss collective SUISA.
        </p>
        <h1>Use</h1>
        <p>
          It is used to identify authors and publishers. We recommend consulting
          your profile on your collective’s web site [SOCAN, SACEM, etc.] to
          find your IPI and use it for all your future communications and
          indexing endeavours. You can also find it in the ISWC database by
          entering the title of one of your previously registered works.
        </p>
        <p>
          The IPI is a multi-level (2) identifier and can be of two types: a
          Base or a Name. A Base IPI number is unique and associated to a legal
          entity or a natural person. A Name is a number that can be associated
          to an alias and will be linked to a Base. A Base can have many Name
          associated to it.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
