import React from "react";
import { useState } from "react";

export default function Operations() {
  const [isMailChimpAOn, setIsMailChimpAOn] = useState(false);
  const [isVerticalResponseAOn, setIsVerticalResponseAOn] = useState(false);
  const [isSandgridAOn, setIsSandgridAOn] = useState(false);
  // const [isRegularServiceGOn, setIsRegularServiceGOn] = useState(false);

  const [isMailChimpBOn, setIsMailChimpBOn] = useState(false);
  const [isVerticalResponseBOn, setIsVerticalResponseBOn] = useState(false);
  const [isSendgridBOn, setIsSendgridBOn] = useState(false);

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <table className="min-w-full divide-y divide-gray-200 table-auto border-2 border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs border-r-2 border-gray-200 leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Transaction Emails
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white">
              <td className="px-6  whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
                MailChimp
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <div className="text-indigo-600 hover:text-indigo-900">
                  <span
                    role="checkbox"
                    aria-checked={isMailChimpAOn}
                    tabIndex="0"
                    onClick={() => setIsMailChimpAOn(!isMailChimpAOn)}
                    className={`${
                      isMailChimpAOn ? "bg-yellow-400" : "bg-gray-200"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        isMailChimpAOn ? "translate-x-5" : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                </div>
              </td>
            </tr>
            <tr className="bg-white">
              <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
                Vertical Response
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <div className="text-indigo-600 hover:text-indigo-900">
                  <span
                    role="checkbox"
                    aria-checked={isVerticalResponseAOn}
                    tabIndex="0"
                    onClick={() =>
                      setIsVerticalResponseAOn(!isVerticalResponseAOn)
                    }
                    className={`${
                      isVerticalResponseAOn ? "bg-yellow-400" : "bg-gray-200"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        isVerticalResponseAOn
                          ? "translate-x-5"
                          : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                </div>
              </td>
            </tr>
            <tr className="bg-white">
              <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
                Sendgrid
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <div className="text-indigo-600 hover:text-indigo-900">
                  <span
                    role="checkbox"
                    aria-checked={isSandgridAOn}
                    tabIndex="0"
                    onClick={() => setIsSandgridAOn(!isSandgridAOn)}
                    className={`${
                      isSandgridAOn ? "bg-yellow-400" : "bg-gray-200"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        isSandgridAOn ? "translate-x-5" : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="min-w-half divide-y divide-gray-200 table-auto border-2 border-gray-200">
          <thead>
            <tr>
              <th className=" px-6 py-3 bg-gray-50 text-left text-xs border-r-2 border-gray-200 leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Transaction Emails
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white">
              <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
                MailChimp
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <div className="text-indigo-600 hover:text-indigo-900">
                  <span
                    role="checkbox"
                    aria-checked={isMailChimpBOn}
                    tabIndex="0"
                    onClick={() => setIsMailChimpBOn(!isMailChimpBOn)}
                    className={`${
                      isMailChimpBOn ? "bg-yellow-400" : "bg-gray-200"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        isMailChimpBOn ? "translate-x-5" : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                </div>
              </td>
            </tr>
            <tr className="bg-white">
              <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
                Vertical Response
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <div className="text-indigo-600 hover:text-indigo-900">
                  <span
                    role="checkbox"
                    aria-checked={isVerticalResponseBOn}
                    tabIndex="0"
                    onClick={() =>
                      setIsVerticalResponseBOn(!isVerticalResponseBOn)
                    }
                    className={`${
                      isVerticalResponseBOn ? "bg-yellow-400" : "bg-gray-200"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        isVerticalResponseBOn
                          ? "translate-x-5"
                          : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                </div>
              </td>
            </tr>
            <tr className="bg-white">
              <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
                Sendgrid
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                <div className="text-indigo-600 hover:text-indigo-900">
                  <span
                    role="checkbox"
                    aria-checked={isSendgridBOn}
                    tabIndex="0"
                    onClick={() => setIsSendgridBOn(!isSendgridBOn)}
                    className={`${
                      isSendgridBOn ? "bg-yellow-400" : "bg-gray-200"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        isSendgridBOn ? "translate-x-5" : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <table className="min-w-half divide-y divide-gray-200 mt-3 table-auto border-2 border-gray-200">
        <thead>
          <tr>
            <th className=" px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Synchronisation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="bg-gray-200 shadow cursor-pointer font-medium hover:bg-yellow-400 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  {/* className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150" */}
                  Sync Analytics
                </button>
              </span>
            </td>
          </tr>
          <tr className="bg-white">
            <td className="px-6  pb-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-500">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="bg-gray-200 shadow cursor-pointer font-medium hover:bg-yellow-400 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  {/* className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-300 hover:bg-yellow-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150" */}
                  Sync Bookkeeping
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
