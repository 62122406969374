import { useState, useEffect } from "react";
import ProfileCard from "../../UserProfileComponents/ProfileCard";
import ContactInfoOverlay from "../PublicPreviewOverlays/ContactInfoOverlay";
import ProfileQuickLinks from "./PublicProfileQuickLinks";
import axios from "axios";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

export default function PublicProfile() {
  const [open, setOpen] = useState(false);

  let params = useParams();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const resp = await axios.get(
          `/public-users/{Username}/?username=${params.username}`
        );
        setData(resp.data);
        setLoading(false);
      } catch {
        setData(null);
      }
    };
    fetchUser();
  }, [params.username]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-width: 3px;
  `;
  return (
    <>
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <ClipLoader
                loading={loading}
                size={150}
                css={override}
                color="#FBBF24"
              />
            </div>
          ) : (
            <>
              <ProfileCard displayName={data?.displayName} setOpen={setOpen} open={open} user={data} />

              <ProfileQuickLinks user={data} />
            </>
          )}
        </div>
        <ContactInfoOverlay open={open} setOpen={setOpen} contactInfo={data} />
      </div>
    </>
  );
}
