import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function ArtisticContactInfo(props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const data = {
    heading: props.heading,
    firstName: props.firstName,
    lastName: props.lastName,
    companyName: props.companyName,
    email: props.email,
    phone: props.phone,
  };

  return (
    <div className="mt-5">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {data.heading}
        </h3>
      </div>

      <div className=" grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor={data.firstName}
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            {...register(`${data.firstName}`, {
              required: "This field is mandatory",
            })}
            id={data.firstName}
            autoComplete={data.firstName}
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={data.firstName}
            className="text-red-500"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor={data.lastName}
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            {...register(`${data.lastName}`, {
              required: "This field is mandatory",
            })}
            id={data.lastName}
            autoComplete={data.lastName}
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={data.lastName}
            className="text-red-500"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor={data.companyName}
            className="block text-sm font-medium text-gray-700"
          >
            Company Name
          </label>
          <input
            type="text"
            {...register(`${data.companyName}`, {
              required: "This field is mandatory",
            })}
            id={data.companyName}
            autoComplete={data.companyName}
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={data.companyName}
            className="text-red-500"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor={data.email}
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            {...register(`${data.email}`, {
              required: "This field is mandatory",
            })}
            id={data.email}
            autoComplete={data.email}
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={data.email}
            className="text-red-500"
          />
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor={data.phone}
            className="block text-sm font-medium text-gray-700"
          >
            Mobile
          </label>
          <input
            type="tel"
            {...register(`${data.phone}`, {
              required: "This field is mandatory",
              minLength: 10,
              maxLength: 35
            })}
            id={data.phone}
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={data.phone}
            className="text-red-500"
          />
        </div>
      </div>
    </div>
  );
}
