import { Button } from "antd";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, Route, Switch, useHistory, Prompt } from "react-router-dom";
import { userStore } from "../../../../../store/userStore";
import ArtisticDetails from "../../../SharedComponents/EditDetailsForm/ArtisticDetails";
import BillingDetails from "../../../SharedComponents/EditDetailsForm/BillingDetails";
import PersonalDetails from "../../../SharedComponents/EditDetailsForm/PersonalDetails";
import SocialMediaDetails from "../../../SharedComponents/EditDetailsForm/SocialMediaDetails";
import PlanInfo from "../../../SharedComponents/PlanInfo";
import MemberSettings from "./MemberSettings";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MemberProfileForm() {
  const user = userStore((state) => state.user);
  const loading = userStore((state) => state.loading);

  const methods = useForm({ defaultValues: user });
  const { handleSubmit, reset, formState } = methods;

  const tabs = [
    {
      name: "Personal",
      pathname: "/editDetails",
      current: false,
    },
    {
      name: "Artistic",
      pathname: "/editDetails/artistic",
      current: false,
    },
    {
      name: "Social Media",
      pathname: "/editDetails/social",
      current: false,
    },
    {
      name: "Settings",
      pathname: "/editDetails/settings",
      current: false,
    },
    {
      name: "Plan",
      pathname: "/editDetails/plans",
      current: false,
    },
    // {
    //   name: "Billing",
    //   pathname: "/editDetails/billing",
    //   current: false,
    // },
  ];

  const onSubmit = (data) => {
    userStore.getState().updateUser(data);
  };
  const history = useHistory();

  const handlePath = (e) => {
    history.push(e);
  };

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  return (
    <>
      <div className="px-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-medium text-white">Account Details</h1>
      </div>

      <div className="bg-white rounded-lg flex">
        <main className="flex-1 overflow-y-auto focus:outline-none">
          <div className="relative  max-w-7xl  mx-auto xl:px-0">
            <div className="pt-5">
              <div className="px-4 sm:px-6 md:px-10">
                <div className="py-4">
                  {/* Tabs */}
                  <div className="lg:hidden">
                    <label htmlFor="selected-tab" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="selected-tab"
                      name="selected-tab"
                      onChange={(e) => handlePath(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm rounded-md"
                      defaultValue={tabs.find((tab) => tab.current.name)}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name} value={tab.pathname}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden lg:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                          <NavLink
                            exact
                            key={tab.name}
                            to={tab.pathname}
                            className={classNames(
                              tab.current
                                ? "border-yellow-400 text-yellow-500"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            activeClassName="border-yellow-400 text-yellow-500"
                            activeStyle={{ color: "#FBBF24" }}
                          >
                            {tab.name}
                          </NavLink>
                        ))}
                      </nav>

                      <Prompt
                        when={formState.isDirty}
                        message={() =>
                          `Your information is not saved. Are you sure you want to proceed?`
                        }
                      />
                    </div>
                  </div>
                  <div className="py-8">
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Switch>
                          <Route exact path="/editDetails">
                            <PersonalDetails />
                          </Route>
                          <Route path="/editDetails/artistic">
                            <ArtisticDetails />
                          </Route>
                          <Route path="/editDetails/social">
                            <SocialMediaDetails />
                          </Route>
                          <Route path="/editDetails/settings">
                            <MemberSettings />
                          </Route>
                          <Route path="/editDetails/plans">
                            <PlanInfo />
                          </Route>
                          <Route path="/editDetails/billing">
                            <BillingDetails />
                          </Route>
                        </Switch>
                        <Button
                          className="mt-5"
                          htmlType="submit"
                          shape="round"
                          size="large"
                          loading={loading}
                          type="primary"
                        >
                          Save
                        </Button>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
