import { MailOutlined, QuestionCircleOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "../../../../../Appp.less";

export default function MembersTable(props) {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1
  });

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  const fetchUsers = async (params) => {
    setLoading(true);
    
    const response = await axios.get('/users', { params });

    setUsers(response.data.users);
    setTotalResults(response.data.totalResults);

    setLoading(false);
  }

  useEffect(() => {
    fetchUsers(pagination);
  }, [pagination])

  const handleTableChange = (paginationObj, filters, sorter) => {
    setPagination({
      pageSize: paginationObj.pageSize,
      pageNumber: paginationObj.current
    });
  };

  const requestPasswordResetForUser = (emailAddress) => {
    toast.promise(
      axios.post('/users/password/requestreset', { emailAddress }),
       {
         loading: 'Sending...',
         success: `Password reset link sent to user's email address`,
       }
     );
  }

  const archiveUser = (id) => {
    toast.promise(
      axios.delete(`/users/${id}`).then(_ => fetchUsers(pagination)),
       {
         loading: 'Sending...',
         success: `User archived!`,
       }
     );
  }

  const columns = [
    {
      title: "User",
      render: (_, user) => (
        <>
          <span> #{user.id} {user.firstName} {user.lastName} </span>
          <br/>
          <a href={`mailto:${user.emailAddress}`}>{user.emailAddress}</a>
        </>
      )
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Phone",
      dataIndex: ["contactDetail", "phone"],
    },
    {
      title: "Type",
      dataIndex: "accountType",
    },
    {
      title: "Status",
      render: (_, user) => (
        <span>
          {user.isActive ? 'Active' : 'Inactive'}
        </span>
      )
    },
    {
      title: "Renewal Date",
      dataIndex: "renewalDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, user) => (
        <>
          <Popconfirm title="Would you like to send password reset to link to the user？" okText="Yes" cancelText="No" onConfirm={() => requestPasswordResetForUser(user.emailAddress)}>
            <MailOutlined />
          </Popconfirm>
          {!user.isArchived && <Popconfirm title="Are you sure you want to archive the user？" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => archiveUser(user.id)}>
            <UserDeleteOutlined style={{ color: 'red', marginLeft: '1rem' }} />
          </Popconfirm>}
        </>
      )
    },
  ];

  return (
    <> 
      <Table
        size="middle"
        className="overflow-y-scroll text-gray-500 mb-3 rounded-lg"
        columns={columns}
        dataSource={users}
        rowKey={"id"}
        loading={loading}
        pagination={{
          pageSize: pagination.pageSize,
          pageNumber: pagination.pageNumber,
          pageSizeOptions: ["10", "30", "50"],
          total: totalResults
        }}
        locale={{ emptyText: " " }}
        onChange={handleTableChange}
      />
    </>
  );
}
