import React from "react";
import { Drawer } from "antd";
import SendMessage from "./SendMessage";
import "./style.css";

export default function MessageBroadcastDrawer(props) {
  return (
    <div className="flex flex-wrap content-center">
      <Drawer
        className="antd-drawer"
        height={256}
        width={"37rem"}
        closable={false}
        onClose={props.closeMessageBroadcastOverlay}
        visible={props.isMessageBroadcastOpen}
      >
        <header className="p-4 sm:px-6 bg-yellow-400 rounded-lg">
          <div className="flex justify-between">
            <h2 className="text-lg font-medium text-white  ">
              Message Broadcast
            </h2>
            <div className="h-7 flex ">
              <button
                aria-label="Close panel"
                onClick={() => props.closeMessageBroadcastOverlay()}
                className="text-white transition ease-in-out ml-7  focus:outline-none"
              >
                {/* <!-- Heroicon name: x --> */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </header>
        {/* <!-- Main --> */}
        <div className="px-4 py-5 sm:px-0 sm:py-0">
          <SendMessage />
        </div>
      </Drawer>
    </div>
  );
}
