import { Link } from "react-router-dom";

export default function RecentCollaborations(props) {
  const recentcollaborations = props.recentcollaborations;
  const heading = props.heading;
  const message = props.message;
  return (
    <section>
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-6">
          <h2
            className="text-base font-medium text-gray-900"
            id="recent-collaborations-title"
          >
            {heading}
          </h2>
          <div className="flow-root mt-5">
            <ul className="-my-5 divide-y divide-gray-200">
              {recentcollaborations.map((person) => (
                <li key={person.handle} className="py-5">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8 rounded-full"
                        src={person.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {person.name}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {"@" + person.handle}
                      </p>
                    </div>
                    <div>
                      <Link
                        to={person.pathname}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="inline-flex hover:text-gray-800 items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white "
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {Array.isArray(recentcollaborations) &&
          recentcollaborations.length ? (
            <div className="mt-6">
              <Link
                to="/member"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="w-full hover:text-gray-800 flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white"
              >
                View all
              </Link>
            </div>
          ) : (
            <p>{message}</p>
          )}
        </div>
      </div>
    </section>
  );
}
