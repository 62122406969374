import { Switch, Route } from "react-router-dom";
import MainScreen from "../CommonArea/CommonComponents/MainScreen";
import SongLibrary from "./MemberComponents/SongLibrary";
// import SongVault from "./MemberComponents/SongVault";
import { FolderIcon, UsersIcon } from "@heroicons/react/outline";
import AssociateProfileForm from "./AssociateProfile/AssociateProfileForm";
import MemberProfileForm from "./MemberComponents/MemberProfile/MemberProfileForm";
import AssociateProfileDashboard from "./AssociateProfile/AssociateProfileDashboard";
import MemberProfileDashboard from "./MemberComponents/MemberProfile/MemberProfileDashboard";
import { userStore } from "../../store/userStore";
import { authStore } from "../../store";
import SongVault from "./MemberComponents/SongVault";
import Dashboard from "../AdminPanel/AdminComponents/Dashboard";
import { HomeIcon } from "@heroicons/react/solid";
import AdminProfile from "../AdminPanel/AdminComponents/AdminProfile";
import AccountManagement from "../AdminPanel/AdminComponents/AccountManagement";
import PromoCodeCreation from "../AdminPanel/AdminComponents/AccountManagement/PromoCodeCreation";

export default function ProfilePanel() {
  const navigation = [
    {
      name: "My Profile",
      icon: UsersIcon,
      pathname: "/",
      state: "Enabled",
      accountType: 'Member'
    },
    {
      name: "SongLibrary",
      icon: FolderIcon,
      pathname: "/songLibrary",
      state: "Disabled",
      accountType: 'Member'
    },
    {
      name: "SongVault",
      icon: FolderIcon,
      pathname: "/songVault",
      state: "Disabled",
      accountType: 'Member'
    },
    {
      name: "SongPitch",
      icon: FolderIcon,
      pathname: "/songPitch",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Pro SongWorks",
      icon: FolderIcon,
      pathname: "/proSongworks",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Regional Writers' Group (RWG)",
      icon: FolderIcon,
      pathname: "/rwg",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "SongBird North (SBN)",
      icon: FolderIcon,
      pathname: "/sbn",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Mentorship",
      icon: FolderIcon,
      pathname: "/mentorship",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Writers' Room",
      icon: FolderIcon,
      pathname: "/writersRoom",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Premier Content",
      icon: FolderIcon,
      pathname: "/premierContent",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Billing & Payments",
      icon: FolderIcon,
      pathname: "/billing",
      state: "Unavailable",
      accountType: 'Member'
    },
    {
      name: "Dashboard",
      icon: HomeIcon,
      pathname: "/",
      state: "Enabled",
      accountType: 'Staff'
    },
    {
      name: "My Profile",
      icon: UsersIcon,
      pathname: "/profile",
      state: "Unavailable",
      accountType: 'Staff'
    },
    {
      name: "Accounts Management",
      icon: FolderIcon,
      state: 'Enabled',
      pathname: "/accountManagement",
      accountType: 'Staff',
    },
    {
      name: "Promo Codes",
      icon: UsersIcon,
      pathname: "/promo-codes",
      state: "Disabled",
      accountType: 'Staff'
    },
  ];

  const user = userStore((state) => state.user);
  const authUser = authStore((state) => state.user);
  const profileImageUrl = userStore(
    (state) => state.profileImageUrl ?? authUser?.profileUrl
  );

  const accountType = userStore((state) => state.user.accountType);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MainScreen
        mainScreen={
          <Switch>
            <Route path="/songLibrary" component={SongLibrary} />
            <Route path="/SongVault" component={SongVault} />
            {accountType === "Member" && (
              <>
                <Route exact path="/">
                  <MemberProfileDashboard
                    user={user}
                    profileImageUrl={profileImageUrl}
                  />
                </Route>
                <Route path="/editDetails">
                  <MemberProfileForm />
                </Route>
              </>
            )}

            {accountType === "Staff" && (
              <>
                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route path="/profile">
                  <AdminProfile />
                </Route>
                <Route path="/accountManagement">
                  <AccountManagement />
                </Route>
              </>
            )}

            {accountType === "Associate" && (
              <>
                <Route exact path="/">
                  <AssociateProfileDashboard
                    user={user}
                    profileImageUrl={profileImageUrl}
                  />
                </Route>
                <Route path="/editDetails">
                  <AssociateProfileForm />
                </Route>
              </>
            )}
          </Switch>
        }
        navigation={navigation}
      />
    </div>
  );
}
