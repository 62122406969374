import { DatePicker } from "antd";
import { useFormContext, Controller } from "react-hook-form";

export default function StaffDatePicker(props) {
  const { control } = useFormContext();

  const data = {
    fieldName: props.fieldName,
  };
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={data.fieldName}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Date
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">
          <Controller
            control={control}
            name={data.fieldName}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                style={{
                  borderRadius: "0.4rem",
                  cursor: "pointer",
                  fontSize: "17px",
                  padding: "0.4rem",
                }}
                className="text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                onBlur={onBlur}
                onChange={onChange}
                selected={value}
                inputRef={ref}
              />
            )}
          />
        </span>
      </div>
    </div>
  );
}
