import PublicProfile from "./PublicProfile";
import { Route, Switch } from "react-router-dom";
import PublicProfileSearch from "./PublicProfileSearch";
import Header from "../MainScreen/Header";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

// import Logo from "../../Assets/songwritersca.png";

export default function PublicPreview() {
  const history = useHistory();

  const handleBackButton = () => {
    history.goBack();
  };
  const handleGoBack = (
    <button
      onClick={handleBackButton}
      className="flex text-white text-xl focus:outline-none"
    >
      <ArrowSmLeftIcon className=" h-7 w-7" aria-hidden="true" />
      Go Back
    </button>
  );

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100  ">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header handleGoBack={handleGoBack} />
        <main className="-mt-24 flex-1 relative overflow-y-auto focus:outline-none">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
            <Switch>
              <Route exact path="/users/">
                <PublicProfileSearch />
              </Route>

              <Route exact path="/users/:username">
                <PublicProfile />
              </Route>
            </Switch>
          </div>
        </main>
      </div>
    </div>
  );
}
