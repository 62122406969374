import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function CreationLocationTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Three letters. You will be able to use various dropdown menus with multiple choices: fra (French), eng (English), und (undetermined or other), mul (multilingual)..."
    >
      <i
        className="far fa-info-circle"
        onClick={props.showCreationLocationTooltip}
      ></i>
      <Drawer
        title="Creation Location"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeCreationLocationTooltip}
        visible={props.creationLocationTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          Three letters. You will be able to use various dropdown menus with
          multiple choices: fra (French), eng (English), und (undetermined or
          other), mul (multilingual).
        </p>
        <h1>Description</h1>
        <p>
          Languages are registered in compliance with the ISO-639-2 code. The
          ISO-639-2 code refers to a standardized list of languages where you
          will find a three-letter code for each indexed language. For example,
          the code for French is fra and the code for English is eng. All the
          codes are available here:
          https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes.
        </p>
        <p>
          ISO is the International Standard Organisation whose mission is to
          develop and publish international standards for all sectors.
        </p>

        <h1>Use</h1>
        <p>This field allows multiple entries.</p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
