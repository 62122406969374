import ProfileCard from "../../../CommonArea/CommonComponents/UserProfileComponents/ProfileCard";
import coverPicture from "../../../../assets/songwritersca_goldbackground.jpg";

export default function AdminProfile() {
  const profile = {
    name: "Ricardo Cooper",
    email: "ricardo.cooper@example.com",
    avatar:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    backgroundImage: coverPicture,
    fields: [
      ["Phone", "(555) 123-4567"],
      ["Email", "ricardocooper@example.com"],
      ["Title", "Senior Front-End Developer"],
      ["Team", "Product Development"],
      ["Location", "San Francisco"],
      ["Sits", "Oasis, 4th floor"],
      ["Salary", "$145,000"],
      ["Birthday", "June 8, 1990"],
    ],
  };
  return (
    <div>
      <div className="flex-shrink-0 flex items-center "></div>
      <div className="flex-shrink-0 text-white font-medium flex items-center text-3xl pt-1 mb-3">
        My Profile
      </div>
      <ProfileCard profile={profile} />
    </div>
  );
}
