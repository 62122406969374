import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { axiosErrorHandler } from "./config/axios.config";
import { Toaster } from "react-hot-toast";
var qs = require("qs");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
axios.interceptors.response.use((response) => response, axiosErrorHandler);
axios.defaults.paramsSerializer = (params) =>
  qs.stringify(params, { indices: false });

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Toaster />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
