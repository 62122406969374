import { Switch, Route, NavLink } from "react-router-dom";
import PendingTable from "../Common/PendingTable";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import SongDetail from "../../../CommonArea/CommonComponents/RegisterNewSong/SongDetail";
import RegisteredSongLibraryTable from "./RegisteredSongLibraryTable";
import StorageBar from "./StorageBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SongLibraryTables() {
  const tabs = [
    {
      name: "Registered",
      pathname: "/songLibrary",
      current: false,
    },
    {
      name: "Pending",
      pathname: "/songLibrary/pending",
      current: false,
    },
  ];

  const history = useHistory();
  const handlePath = (e) => {
    history.push(e);
  };
  const registerSong = () => {
    history.push("/songLibrary/upload");
  };

  return (
    <>
      <div className=" flex justify-between">
        <h1 className="text-3xl font-medium text-white">SongLibrary</h1>
        <span className="inline-flex justify-center rounded-md shadow-sm mb-5">
          <Button
            shape="round"
            size="large"
            type="default"
            onClick={() => registerSong()}
          >
            Upload New Song
          </Button>
        </span>
      </div>

      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <div className="bg-white rounded-lg flex">
            <main className="flex-1 overflow-y-auto focus:outline-none">
              <div className="relative  max-w-8xl  mx-auto xl:px-0">
                <div className="pt-5">
                  <div className="px-4 sm:px-6 md:px-10">
                    {/* Tabs */}
                    <div className="lg:hidden">
                      <label htmlFor="selected-tab" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="selected-tab"
                        name="selected-tab"
                        onChange={(e) => handlePath(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm rounded-md"
                        defaultValue={tabs.find((tab) => tab.current.name)}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name} value={tab.pathname}>
                            {tab.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden lg:block">
                      <div className="sm:border-b sm:border-gray-200">
                        <nav className="-mb-px flex space-x-8">
                          {tabs.map((tab) => (
                            <NavLink
                              exact
                              key={tab.name}
                              to={tab.pathname}
                              className={classNames(
                                tab.current
                                  ? "border-yellow-400 text-yellow-500"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                              )}
                              activeClassName="border-yellow-400 text-yellow-500"
                              activeStyle={{ color: "#FBBF24" }}
                            >
                              {tab.name}
                            </NavLink>
                          ))}
                        </nav>
                      </div>
                    </div>

                    <div className="py-8">
                      <Switch>
                        <Route exact path="/songLibrary">
                          <RegisteredSongLibraryTable />
                        </Route>
                        <Route path="/songLibrary/pending">
                          <PendingTable />
                        </Route>
                        <Route path="/songLibrary/upload">
                          <SongDetail />
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <h2 className="sr-only" id="section-2-title">
              Section title
            </h2>
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                <StorageBar />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
