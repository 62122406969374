import { useState } from "react";
import { Space, Popconfirm, Tooltip, Table } from "antd";

export default function PendingTableData() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL],
  };

  const columns = [
    {
      title: "Works Title",
      key: "worksTitle",
      dataIndex: "worksTitle",
    },
    {
      title: "Author",
      key: "author",
      dataIndex: "author",
    },
    {
      title: "Co-Author(s)",
      key: "coAuthor",
      dataIndex: "coAuthor",
    },
    {
      title: "Upload Date",
      key: "uploadDate",
      dataIndex: "uploadDate",
      sorter: (a, b) => a.uploadDate - b.uploadDate,
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      render: () => (
        <Space size="middle" className="text-lg">
          <span className="text-yellow-500">
            <Tooltip placement="topLeft" title="Edit">
              <i className="fas fa-pencil-alt"></i>
            </Tooltip>
          </span>
          <span className="text-green-500">
            <Tooltip placement="topLeft" title="Copy">
              <i className="far fa-copy"></i>
            </Tooltip>
          </span>

          <span className="text-red-500">
            <Popconfirm
              title="Are you sure you want to delete this file？"
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topLeft" title="Delete">
                <i className="far fa-trash-alt"></i>
              </Tooltip>
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: `0`,
      worksTitle: `Founder`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      uploadDate: `2012-05-20`,

      timeLeft: `in 5 minutes`,
    },
    {
      key: `1`,
      worksTitle: `Chungus`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      uploadDate: `2012-05-20`,

      timeLeft: `in 48 minutes`,
    },
    {
      key: `2`,
      worksTitle: `Tuttofare`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      uploadDate: `2012-05-20`,

      timeLeft: `in 2 hours 2 minutes`,
    },
    {
      key: `3`,
      worksTitle: `Better Half`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      uploadDate: `2012-05-20`,

      timeLeft: `in 7 hours 5 minutes`,
    },
    {
      key: `4`,
      worksTitle: `Head Chef`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      uploadDate: `2012-05-20`,

      timeLeft: `in 9 hours 40 minutes`,
    },
  ];

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <Table
        className="overflow-y-scroll text-gray-500 mb-3 border border-gray-300 p-5 rounded-lg mt-2"
        columns={columns}
        rowSelection={rowSelection}
        dataSource={data}
        scroll={{ y: 540 }}
        locale={{ emptyText: " " }}
      />

      <button
        className={`${hasSelected ? "inline mt-2" : "hidden"}
            inline-flex items-center mr-2 px-2.5 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400`}
        type="primary"
        disabled={!hasSelected}
      >
        Checkout
      </button>
    </div>
  );
}
