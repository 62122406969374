import ArtisticQuickLink from "./ArtisticQuickLink";
import SongLibraryQuickLink from "./SongLibraryQuickLink";

export default function PublicProfileQuickLinks(props) {
  return (
    <section aria-labelledby="quick-links-title">
      <div className="rounded-lg  overflow-hidden  divide-y divide-gray-200 sm:divide-y-0  sm:grid sm:grid-cols-2 sm:gap-px">
        <SongLibraryQuickLink />
        <ArtisticQuickLink artisticInfo={props.user?.artisticInfo} />
      </div>
    </section>
  );
}
