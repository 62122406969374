import { MailIcon, UserIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import avatar from "../../Assets/avatar.png";
import coverPicture from "../../../../assets/songwritersca_goldbackground.jpg";

export default function ProfileCard(props) {
  const editDetailsLink = props.editDetailsLink;
  const history = useHistory();

  const editDetails = () => {
    history.push(editDetailsLink);
  };

  const user = props.user;
  const displayName = props.displayName;
  const open = props.open;
  const setOpen = props.setOpen;

  const dateObj = new Date(user?.createTime);
  const year = dateObj.getUTCFullYear();

  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[dateObj.getUTCMonth()];

  return (
    <div>
      <div>
        <img
          className="h-32 w-full object-cover rounded-t-lg lg:h-48"
          src={coverPicture}
          alt=""
        />
      </div>
      <div className="max-w-8xl bg-white mx-auto px-4 sm:px-6 lg:px-8 rounded-b">
        <div className="-mt-12 py-3 sm:-mt-16 sm:flex sm:items-end sm:space-x-2">
          <div>
            <img
              className="h-24 w-24 rounded-full object-cover ring-4 ring-white sm:h-32 sm:w-32"
              src={user?.profileImageUrl ? user?.profileImageUrl : avatar}
              onError={(e) => {
                e.target.src = avatar;
              }}
              alt=""
            />
          </div>
          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 className="text-xl mt-3 font-bold text-gray-900 capitalize">
                {displayName}
                {user?.pronouns && (
                  <span className="capitalize ml-1">({user?.pronouns})</span>
                )}
              </h1>
              {user?.createTime && (
                <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                  Member Since: {month} {year}
                </span>
              )}
            </div>
            {props.isMember ? (
              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                <button
                  type="button"
                  onClick={() => history.push(`/users/${user?.username}`)}
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <UserIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Public View</span>
                </button>

                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  onClick={() => editDetails()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 -ml-1 mr-2 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  <span>Edit Details</span>
                </button>
              </div>
            ) : (
              <div className="mt-6 flex items-center justify-center lg:flex-row md:flex-row sm:flex-col sm:space-x-4">
                <SocialLinks user={user} />
                <div>
                  <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    <MailIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Contact</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="hidden sm:block md:hidden  min-w-0 flex-1">
          <h1 className="text-xl font-bold text-gray-900 truncate">
            {user?.firstName} {user?.lastName}
            {user?.createTime && (
              <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                Member Since: {month} {year}
              </span>
            )}
          </h1>
        </div>
      </div>
    </div>
  );
}
