import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";

export default function AdminRegistrationAccountInfo(props) {
  const { register, watch } = useFormContext();

  const password = useRef({});
  password.current = watch("password", "");

  const profileUrl = useRef({});
  profileUrl.current = watch("username", "");

  const renewalDetails = useRef({});
  renewalDetails.current = watch("renewalDetails", "");

  return (
    <div className="space-y-6 sm:space-y-5">
      <h3 className="text-xl leading-6 font-medium text-gray-900 ">
        Account Information
      </h3>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="accountType"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Account Type
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="accountType"
            {...register("accountType", {
              required: false,
            })}
            autoComplete="accountType"
            defaultValue=""
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Account Type
            </option>
            <option value="member">Member</option>
            <option value="associate">Associate</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t  sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="profileType"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Profile Type
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="profileType"
            {...register("profileType", {
              required: false,
            })}
            autoComplete="profileType"
            // onChange={(e) => props.setProfileType(e.target.value)}
            defaultValue=""
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Profile Type
            </option>
            <option value="contact">Contact</option>
            <option value="volunteer">Volunteer</option>
            <option value="resource_leader">Resource Leader</option>
            <option value="board">Board</option>
            <option value="company_representative">
              Company Representative
            </option>
            <option value="funder">Funder</option>
            <option value="sponsor">Sponsor</option>
            <option value="donor">Donor</option>
            <option value="vendor">Vendor</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t  sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="role"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Role
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="role"
            {...register("role", {
              required: false,
            })}
            autoComplete="role"
            defaultValue=""
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Role
            </option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
            <option value="employee">Employee</option>
            <option value="developer">Developer</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="status"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Status
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="status"
            {...register("status", {
              required: false,
            })}
            autoComplete="status"
            defaultValue=""
            className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Status
            </option>

            <option value="student">Student</option>
            <option value="senior">Senior</option>
            <option value="regular">Regular</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Username
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("username", {
              required: false,
              minLength: {
                value: 6,
                message: "The username should be at least 6 characters long",
              },
              maxLength: {
                value: 30,
                message:
                  "The username contains more than 30 characters. Please make it shorter",
              },
              pattern: {
                value: /^[a-zA-Z0-9.]*$/i,
                message:
                  "The username cannot contain an ampersand (&), equals sign (=), underscore (_), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>), or more than one period (.) in a row.",
              },
            })}
            id="username"
            autoComplete="username"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="accountInfo_profileUrl"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Profile URL
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              songwriters.ca/
            </span>
            <input
              type="text"
              name="accountInfo_profileUrl"
              id="accountInfo_profileUrl"
              autoComplete="accountInfo_profileUrl"
              disabled
              value={profileUrl.current}
              className="max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-r-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Password
        </label>

        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="password"
            {...register("password", {
              required: false,
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/,
                message:
                  "At least 8 characters—the more characters, the better. Include at least one uppercase letter. Include at least one number. Include at least one special character, e.g., ! @ # ? ]",
              },
            })}
            id="password"
            autoComplete="password"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />

          <p className="mt-2 text-sm text-gray-500 sm:">
            At least 8 characters—the more characters, the better. <br />
            A mixture of both uppercase and lowercase letters. <br />A mixture
            of letters and numbers. <br /> Include of at least one special
            character, e.g., ! @ # ? ]
          </p>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="confirmPassword"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Confirm Password
        </label>

        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="password"
            {...register("confirmPassword", {
              required: false,
              validate: (value) =>
                value === password.current || "The passwords do not match",
            })}
            id="confirmPassword"
            autoComplete="confirmPassword"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>
    </div>
  );
}
