import "../../../../../App.css";
import { Space, Popconfirm, Checkbox } from "antd";
import SongTable from "../../Table";
import { useState, useEffect } from "react";
import AddCreativeContributor from "./Modal/AddCreativeContributor";

export default function CoAuthorsTable(props: any) {
  const [coAuthorData, setCoAuthorData] = useState(props.coAuthor);
  const [percentLeft, setPercentLeft] = useState(props.percentLeft);

  useEffect(() => {
    setCoAuthorData(props.coAuthor);
  }, [props.coAuthor]);

  useEffect(() => {
    setPercentLeft(props.percentLeft);
  }, [props.percentLeft]);

  const [showCoAuthorModal, setShowCoAuthorModal] = useState(false);

  const coAuthorModalHandler = () => {
    setShowCoAuthorModal(!showCoAuthorModal);
  };

  const columns = [
    {
      title: "Name",
      key: "Name",
      dataIndex: "Name",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Contribution in %",
      key: "ownership",
      dataIndex: "ownership",
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      render: (_: any, record: { key: React.Key }) => (
        <Space size="middle" className="text-lg">
          {record.key === 0 ? null : (
            <span className="text-red-500">
              <Popconfirm
                title="Are you sure you want to remove this contributor？"
                okText="Yes"
                cancelText="No"
              >
                <Checkbox checked={true} />
              </Popconfirm>
            </span>
          )}
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: 0,
      Name: "Zoe Cunningham",
      ownership: percentLeft,
      email: "zoe@songwriters.ca",
    },
  ];

  coAuthorData.map((detail: any, index: number) => [
    ...data,
    data.push({
      key: index + 1,
      Name: `${detail.firstName} ${detail.lastName}`,
      ownership: detail.ownership,
      email: detail.email,
    }),
  ]);

  return (
    <div>
      <SongTable columns={columns} data={data} />
      {showCoAuthorModal ? (
        <AddCreativeContributor
          handleModal={coAuthorModalHandler}
          percentLeft={percentLeft}
        />
      ) : null}
    </div>
  );
}
