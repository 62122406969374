import { SearchIcon } from "@heroicons/react/solid";
import { useForm, Controller } from "react-hook-form";
import { Radio } from "antd";

export default function PublicPreviewFilters(props) {
  const { register, handleSubmit, control, getValues } = useForm({
    defaultValues: {
      publicProfile: {
        accountType: "Member",
        genres: [],
      },
    },
  });

  const genre = {
    A: [
      { name: "Acoustic" },
      { name: "Adult" },
      { name: "Alternative" },
      { name: "Adult Alternative" },
      { name: "Ambient" },
      { name: "Americana" },
      { name: "Arranging" },
    ],
    B: [{ name: "Beat Making" }, { name: "Blues" }],
    C: [
      { name: "Childrens" },
      { name: "Christian/Gospel" },
      { name: "Classical" },
      { name: "Contemporary" },
      { name: "Contemporary Instrumental" },
      { name: "Contemporary Roots" },
      { name: "Country" },
    ],
    D: [{ name: "Dance/Electronic" }],
    F: [{ name: "Folk" }, { name: "Francophone" }, { name: "Funk" }],
    H: [{ name: "Hip-Hop" }],
    I: [
      { name: "Indie Rock" },
      { name: "Indigenous" },
      { name: "Instrumental" },
      { name: "International" },
    ],
    J: [{ name: "Jazz" }],
    M: [
      { name: "Metal/Hard" },
      { name: "Music for Video/Film" },
      { name: "Music for Visual Media" },
      { name: "Musical Theatre" },
    ],
    O: [{ name: "Other" }],
    P: [{ name: "Pop" }, { name: "Punk" }],
    R: [{ name: "R&B" }, { name: "Rap" }, { name: "Reggae" }, { name: "Rock" }],
    S: [{ name: "Soul" }, { name: "Spoken Word" }],
    T: [{ name: "Top Linear" }, { name: "Traditional Roots" }],
    W: [{ name: "World" }],
  };

  const onSubmit = (data) => {
    const filters = {...data.publicProfile};
    props.filters(filters);
  };

  const onGenreChange = () => {
    const filters = {...getValues('publicProfile')};
    props.filters(filters);
  };

  return (
    <>
      <aside className=" xl:order-first xl:flex xl:flex-col flex-shrink-0 sm:w-96 sm:border-r sm:border-gray-200">
        <div className="sm:px-6 sm:pt-6 sm:pb-4">
          <span className="text-2xl font-medium">Members Directory</span>
          <form
            className="mt-6 flex flex-col space-y-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <div className="flex-1 min-w-0">
                <label htmlFor="publicProfile.name" className="sr-only">
                  Search by name
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="search"
                    {...register("publicProfile.search", {
                      required: false,
                    })}
                    id="publicProfile.name"
                    className="focus:ring-yellow-500 focus:border-yellow-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="flex-1 min-w-0">
                <label htmlFor="publicProfile.city" className="sr-only">
                  Search by city
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="search"
                    {...register("publicProfile.city", {
                      required: false,
                    })}
                    id="publicProfile.city"
                    className="focus:ring-yellow-500 focus:border-yellow-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="City"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex-1 min-w-0">
                <label htmlFor="publicProfile.region" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>

                  <select
                    id="publicProfile.region"
                    {...register("publicProfile.region", {
                      required: false,
                    })}
                    defaultValue=""
                    className="focus:ring-yellow-500 focus:border-yellow-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="" disabled>
                      Region
                    </option>
                    <option value="any">Any...</option>
                    <option value="alberta">Alberta</option>
                    <option value="britishColumbia">British Columbia</option>
                    <option value="manitoba">Manitoba</option>
                    <option value="newBrunswick">New Brunswick</option>
                    <option value="newfoundland">
                      Newfoundland & Labrador
                    </option>
                    <option value="novaScotia">Nova Scotia</option>
                    <option value="northwestTerritories">
                      Northwest Territories
                    </option>
                    <option value="nunavut">Nunavut</option>
                    <option value="ontario">Ontario</option>
                    <option value="princeEdward">Prince Edward Island</option>
                    <option value="quebec">Quebec</option>
                    <option value="saskatchewan">Saskatchewan</option>
                    <option value="yukonTerritory">Yukon Territory</option>
                    <option value="outside">Outside Canada</option>
                  </select>
                </div>
              </div>
            </div>

            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                <Controller
                  control={control}
                  name="publicProfile.accountType"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Radio.Group
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                    >
                      <Radio value="Member">Member</Radio>
                      <Radio value="Associate">Associate</Radio>
                    </Radio.Group>
                  )}
                />
              </span>
            </dd>
            <input
              type="submit"
              className=" bg-gray-200 text-gray-800 shadow font-medium hover:bg-yellow-400 cursor-pointer items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
              value="Search"
            />
          </form>
          <form onChange={onGenreChange}>
            <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="genre">
              <div className="mt-5 text-sm text-gray-600 ">
                <h2 className="text-lg font-medium text-gray-900">Genre</h2>
              </div>
              {Object.keys(genre).map((letter) => (
                <div key={letter} className="relative">
                  <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                    <h3>{letter}</h3>
                  </div>
                  <ul className="relative z-0 divide-y divide-gray-200">
                    {genre[letter].map((person) => (
                      <li key={person.name} className="p-3">
                        <div className="flex sm:mt-0 mt-5">
                          <label
                            htmlFor={person.name}
                            className="ml-2 block text-sm leading-5 text-gray-900"
                          >
                            <input
                              value={person.name}
                              type="checkbox"
                              {...register("publicProfile.genres[]")}
                              className="form-checkbox focus:ring-yellow-400 border border-gray-300 h-4 w-4 text-yellow-500 mr-2 transition duration-150 ease-in-out rounded"
                            />
                            {person.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>
          </form>
        </div>
      </aside>
    </>
  );
}
