import { useFormContext } from "react-hook-form";

export default function Funder() {
  const { register } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">Funder</h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
          <label
            htmlFor="funder.eventCodes"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Event Code
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("funder.eventCodes", {
                required: false,
              })}
              id="funder.eventCodes"
              autoComplete="funder.eventCodes"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.description"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="funder.description"
              {...register("funder.description", {
                required: false,
              })}
              rows="3"
              className="focus:ring-yellow-300 focus:border-yellow-300 max-w-lg focus:ring-0 shadow-sm block w-full focus:outline-none p-2 sm:text-sm border border-gray-300 rounded-md"
            />

            <p className="mt-2 text-sm text-gray-500 sm:w-6/12">
              Description the nature of the event(s)/activity and way that the
              funder supported this
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:border-t sm:border-gray-200">
          <label
            htmlFor="funder.mandate"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Funder Mandate
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("funder.mandate", {
                required: false,
              })}
              id="funder.mandate"
              autoComplete="funder.mandate"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:border-t sm:border-gray-200">
          <label
            htmlFor="funder.participantsCount"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Participant Numbers
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("funder.participantsCount", {
                required: false,
              })}
              id="funder.participantsCount"
              autoComplete="funder.participantsCount"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.acknowledgement"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Acknowledgement
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="funder.acknowledgement"
              {...register("funder.acknowledgement", {
                required: false,
              })}
              rows="3"
              className="focus:ring-yellow-300 focus:border-yellow-300 max-w-lg focus:ring-0 shadow-sm block w-full focus:outline-none p-2 sm:text-sm border border-gray-300 rounded-md"
            />

            <p className="mt-2 text-sm text-gray-500 sm:w-6/12">
              Please describe how S.A.C. has credited this association in
              publicity or printed materials (ie: weblink to www.songwriters.ca
              logo on all printed materials, S.A.C. ad in event program, verbal
              recognition at event, S.A.C. representative onsite, space to
              displays S.A.C. material /promo literature, email to your list
              with S.A.C recognition, etc.)
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.promotionMediaUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Publicity
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="funder.promotionMediaUrls"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span className="text-yellow-400"> Upload a file</span>
                        <input
                          id="funder.promotionMediaUrls"
                          type="file"
                          className="sr-only"
                          {...register("funder.promotionMediaUrls", {
                            required: false,
                          })}
                        />
                      </label>
                      <p className="pl-1 text-gray-600">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      Each file is up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500 sm:w-6/12">
              Upload promotion and advertising media assets related to the
              funded activity or event.
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.broadcastUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Recordings / Broadcast
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="funder.broadcastUrls"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span className="text-yellow-400"> Upload a file</span>
                        <input
                          id="funder.broadcastUrls"
                          type="file"
                          className="sr-only"
                          {...register("funder.broadcastUrls", {
                            required: false,
                          })}
                        />
                      </label>
                      <p className="pl-1 text-gray-600">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      DOCX, PDF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500 sm:w-6/12">
              Upload audio and/or audio-visual recordings or broadcasts
              resulting from the funded project or event
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.fundingHistory"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Funding History
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("funder.fundingHistory", {
                required: false,
              })}
              id="funder.fundingHistory"
              autoComplete="funder.fundingHistory"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.agreementUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Funder Agreement
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="funder.agreementUrls"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span className="text-yellow-400"> Upload a file</span>
                        <input
                          id="funder.agreementUrls"
                          type="file"
                          className="sr-only"
                          {...register("funder.agreementUrls", {
                            required: false,
                          })}
                        />
                      </label>
                      <p className="pl-1 text-gray-600">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      DOCX, PDF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="funder.applicationUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Funder Application
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="funder.applicationUrls"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span className="text-yellow-400"> Upload a file</span>
                        <input
                          id="funder.applicationUrls"
                          type="file"
                          className="sr-only"
                          {...register("funder.applicationUrls", {
                            required: false,
                          })}
                        />
                      </label>
                      <p className="pl-1 text-gray-600">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      DOCX, PDF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
