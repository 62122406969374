import React, { useState } from "react";
import { Select } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import PROTooltip from "./common/Tooltip/PROTooltip";

const children = [
  {
    label: "SOCAN",
    value: "SOCAN",
  },
  {
    label: "ASCAP",
    value: "ASCAP",
  },
  {
    label: "BMI",
    value: "BMI",
  },
];

const PRO = (props) => {
  const { control } = useFormContext();

  const [ProTooltip, setProTooltip] = useState(false);
  const showProTooltip = () => {
    setProTooltip(true);
  };
  const closeProTooltip = () => {
    setProTooltip(false);
  };
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="biography"
        className="block text-sm font-medium leading-5 text-gray-700 mb-1"
      >
        PRO
        <PROTooltip
          showProTooltip={showProTooltip}
          ProTooltip={ProTooltip}
          closeProTooltip={closeProTooltip}
        />
      </label>

      <Controller
        control={control}
        name={props.fieldName}
        render={({ field: { onChange, value } }) => (
          <Select
            mode="tags"
            allowClear
            style={{ padding: "2px" }}
            bordered={false}
            placeholder="Please select"
            onChange={onChange}
            className="mt-1 w-full focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md border border-gray-300 block transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            value={value}
          >
            {children.map((child) => (
              <Select.Option value={child.value} key={child.value}>
                {child.label}
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </div>
  );
};

export default PRO;
