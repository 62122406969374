import { DatePicker } from "antd";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";

export function Input({ register, label, name, rules, type, ...rest }) {
  const { watch } = useFormContext();

  const defaultValue = watch(name);

  switch (type) {
    case "year":
      return (
        <InputYear
          register={register}
          label={label}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          {...rest}
        />
      );
    default:
      return (
        <>
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <input
            type={type}
            key={name}
            defaultValue={defaultValue}
            {...register(name)}
            {...rest}
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </>
      );
  }
}

function InputYear({ register, label, name, defaultValue, rules, ...rest }) {

  const { control } = useFormContext();

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            selected={value}
            picker="year"
            defaultValue={defaultValue?.isNaN ? moment(defaultValue, 'YYYY'): ''}
            {...rest}
            onChange={(val) => onChange(val?.format('YYYY'))}
            className="focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block sm:w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5 h-10"
          />
        )}
      />
    </>
  );
}
