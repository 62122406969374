import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import ProfileCard from "../../../CommonArea/CommonComponents/UserProfileComponents/ProfileCard";
import Dashboard from "../../../CommonArea/CommonComponents/UserProfileComponents/Dashboard";
import ProfileQuickLinks from "../../../CommonArea/CommonComponents/UserProfileComponents/ProfileQuickLinks";
import {
  PlayIcon,
  UserCircleIcon,
  ChartBarIcon,
} from "@heroicons/react/outline";
import Announcements from "../../../CommonArea/CommonComponents/UserProfileComponents/Announcements";
import RecentCollaborations from "../../../CommonArea/CommonComponents/UserProfileComponents/RecentCollaboarations";

const actions = [
  {
    icon: CashIcon,
    name: "Benefits/Toolkit",
    description: "All you need to know about your membership.",
    active: false,
    external: false,
    pathname: "/benifits",
    iconForeground: "text-green-700",
    iconBackground: "bg-green-50",
  },
  {
    icon: BadgeCheckIcon,
    name: "Billing & Payments",
    description: "Keep track of your purchases, refunds and other important information.",
    active: false,
    external: false,
    pathname: "/billingPayments",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    icon: ClockIcon,
    name: "Events",
    description: "Dive into the world of S.A.C. events, concerts, webinars and more.",
    active: true,
    external: true,
    pathname: "/events",
    iconForeground: "text-blue-700",
    iconBackground: "bg-blue-50",
  },
  {
    icon: UsersIcon,

    name: "Community (Discussion lists/forums)",
    description: "Make connections, discuss important topics. Let’s make a difference together.",
    active: false,
    external: false,
    pathname: "/community",
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    icon: AcademicCapIcon,

    name: "Courses (LMS)",
    description: "Learn about the craft and business of songwriting, and how to protect your rights with us.",
    active: false,
    external: false,
    pathname: "/courses",
    iconForeground: "text-red-700",
    iconBackground: "bg-red-50",
  },
  {
    icon: ReceiptRefundIcon,
    name: "Contracts/Policies",
    description: "Don’t know which legal template to use? We can help.",
    active: false,
    external: false,
    pathname: "/contracts",
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];
const companyConnections = [];
const announcements = [];

const cards = [
  {
    name: "Public SongLibrary",
    pathname: "/songLibrary",
    pathDetails: "View all",
    icon: PlayIcon,
  },

  {
    name: "Member Status",
    pathname: "",
    pathDetails: "Active",
    icon: UserCircleIcon,
  },
  {
    name: "Social Media ",
    pathname: "/editDetails/social",
    pathDetails: "Show handles",
    icon: ChartBarIcon,
  },
];

export default function AssociateProfileDashboard(props) {
  const tooltip = props.tooltip;
  return (
    <main className="">
      <div className="mx-auto max-w-8xl ">
        <div className=" flex-shrink-0 flex items-center text-white font-medium text-3xl">
          Associate Profile
        </div>

        {/* Main 3 column grid */}
        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 mt-3">
          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <ProfileCard
              isMember={true}
              editDetailsLink="/editDetails"
              user={props.user}
              profileImageUrl={props.profileImageUrl}
            />

            {/* Dashboard */}
            <section>
              <Dashboard cards={cards} />
            </section>

            {/* Actions panel */}

            <ProfileQuickLinks actions={actions} tooltip={tooltip} />
          </div>

          {/* Right column */}
          <div className="grid grid-cols-1 gap-4">
            {/* Announcements */}
            <Announcements announcements={announcements} />

            {/* Recent collaborations */}
            <RecentCollaborations
              recentcollaborations={companyConnections}
              heading="Company Connections"
              message="No connections yet."
            />
          </div>
        </div>
      </div>
    </main>
  );
}
