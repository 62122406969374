import Header from "./Header";
import Sidebar from "./Sidebar";

export default function MainScreen(props) {
  const navigation = props.navigation;

  return (
    <>
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <Sidebar navigation={navigation} />
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header navigation={navigation} heading={props.heading} />

        <main className="-mt-36 flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
              {props.mainScreen}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
