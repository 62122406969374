import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function AssociateAddress(props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const field = props.field;
  const addressLine1 = props.addressLine1;
  const addressLine2 = props.addressLine2;
  return (
    <>
      <div className="sm:col-span-6">
        <label
          htmlFor="line1"
          className="block text-sm font-medium text-gray-700 sm:mt-px"
        >
          {addressLine1}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register(`${field}.line1`, {
              required: false,
            })}
            autoComplete="line1"
            // disabled={props.disabled}
            placeholder="House Number and Street Name"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={`${field}.line1`}
            className="text-red-500"
          />
        </div>
      </div>

      <div className="sm:col-span-6">
        <label
          htmlFor="line2"
          className="block text-sm font-medium text-gray-700 sm:mt-px"
        >
          {addressLine2}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register(`${field}.line2`, {
              required: false,
            })}
            autoComplete="line2"
            placeholder="Apartment, suite, unit etc. (optional)"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="city"
          className="block text-sm font-medium text-gray-700 sm:mt-px"
        >
          City
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register(`${field}.city`, {
              required: false,
            })}
            autoComplete="city"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={`${field}.city`}
            className="text-red-500"
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="province"
          className="block text-sm font-medium text-gray-700"
        >
          Province
        </label>

        <select
          {...register(`${field}.province`)}
          defaultValue=""
          // onChange={(e) => props.setAns1(e.target.value)}
          className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
        >
          <option value="" disabled>
            Province
          </option>
          {/* <option value="any">Any...</option> */}
          <option value="alberta">Alberta</option>
          <option value="britishColumbia">British Columbia</option>
          <option value="manitoba">Manitoba</option>
          <option value="newBrunswick">New Brunswick</option>
          <option value="newfoundland">Newfoundland & Labrador</option>
          <option value="northwestTerritories">Northwest Territories</option>
          <option value="novaScotia">Nova Scotia</option>
          <option value="nunavut">Nunavut</option>
          <option value="ontario">Ontario</option>
          <option value="princeEdward">Prince Edward Island</option>
          <option value="quebec">Quebec</option>
          <option value="saskatchewan">Saskatchewan</option>
          <option value="yukonTerritory">Yukon</option>
          <option value="outside">Outside Canada</option>
        </select>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="country"
          className="block text-sm font-medium text-gray-700 sm:mt-px"
        >
          Country
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            {...register(`${field}.country`, {
              required: false,
            })}
            autoComplete="country"
            defaultValue=""
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          >
            <option value="" disabled>
              Select Country
            </option>
            <option value="canada">Canada</option>
            <option value="usa">USA</option>
            <option value="mexico">Mexico</option>
          </select>
          <ErrorMessage
            as="p"
            errors={errors}
            name={`${field}.country`}
            className="text-red-500"
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="postalCode"
          className="block text-sm font-medium text-gray-700 sm:mt-px"
        >
          Postal Code
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register(`${field}.postalCode`, {
              required: false,
            })}
            autoComplete="postalCode"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name={`${field}.postalCode`}
            className="text-red-500"
          />
        </div>
      </div>
    </>
  );
}
