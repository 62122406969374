import { Route, Switch } from "react-router-dom";
import SongDetail from "../../../CommonArea/CommonComponents/RegisterNewSong/SongDetail";
import SongVaultTables from "./SongVaultTables";

export default function SongVault() {
  return (
    <Switch>
      <Route path="/songVault">
        <SongVaultTables />
      </Route>
      <Route path="/songVault/registerSong">
        <SongDetail />
      </Route>
    </Switch>
  );
}
