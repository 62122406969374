import React from "react";
import { Table } from "antd";

export default function SongTable(props) {
  return (
    <div>
      <Table
        className="overflow-y-scroll text-gray-500 mb-3 border border-gray-300 p-5 rounded-lg mt-2"
        columns={props.columns}
        dataSource={props.data}
        scroll={{ y: 540 }}
        locale={{ emptyText: " " }}
      />
    </div>
  );
}
