import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { FilterIcon } from "@heroicons/react/solid";
import PublicProfileSearchResults from "./PublicProfileSearchResults";
import PublicPreviewFilters from "./PublicPreviewFilters";
import axios from "axios";
import { authStore } from "../../../../../store";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

export default function PublicProfileSearch() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [data, setData] = useState([]);

  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 10, pageNumber: 1 });

  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    try {
      const params = { ...filters, ...pagination };
      const resp = await axios.get("/public-users", { params });
      setData(resp.data);
    } catch {
      setData([]);
    }
    setLoading(false);
  };

  const paginationChange = (pageNumber, pageSize) => {
    setPagination({ pageSize, pageNumber });
  };

  const authUser = authStore((state) => state.user);

  const profileImageUrl = data?.profileImageUrl ?? authUser?.profileUrl;

  useEffect(() => {
    setLoading(true);
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination]);
  const override = css`
    display: block;
    margin: 0 auto;
    border-width: 3px;
  `;
  return (
    <div className="h-screen  overflow-y-hidden flex bg-white rounded-lg">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="absolute max-w-md min-h-screen bg-white focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <PublicPreviewFilters />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}

      <div className="flex flex-col min-w-0 flex-1 ">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            {/* Breadcrumb */}
            <nav
              className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden"
              aria-label="Breadcrumb"
            >
              <button
                onClick={() => setSidebarOpen(true)}
                className="focus:outline-none inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
              >
                <FilterIcon
                  className="-ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Filters</span>
              </button>
            </nav>

            <article className="p-5">
              <span className="text-2xl font-medium">Search Results</span>

              {/* Profile header */}
              <div className="border-r border-gray-200">
                {loading ? (
                  <div className="flex justify-center items-center sm:mt-80 mt-40">
                    <ClipLoader
                      loading={loading}
                      size={150}
                      css={override}
                      color="#FBBF24"
                    />
                  </div>
                ) : Array.isArray(data.users) && data?.users.length ? (
                  <PublicProfileSearchResults
                    people={data}
                    profileImageUrl={profileImageUrl}
                    filters={filters}
                    currentPage={pagination.pageNumber}
                    paginationChange={paginationChange}
                  />
                ) : (
                  <p className="text-xl mt-5">No result found.</p>
                )}
              </div>
            </article>
          </main>
          <div className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 overflow-y-scroll border-r border-gray-200">
            <PublicPreviewFilters filters={(value) => setFilters(value)} />
          </div>
        </div>
      </div>
    </div>
    // </FormProvider>
  );
}
