import { Route, Switch } from "react-router-dom";
import CreateNewAccount from "./CreateNewAccount";
import ManageAccount from "./ManageAccount";

export default function AccountManagement() {
  return (
    <>
      <div className="px-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-medium text-white">Account Management</h1>
      </div>

      <div className="bg-white rounded-lg flex">
        <main className="flex-1 overflow-y-auto focus:outline-none">
          <div className="relative  max-w-8xl  mx-auto xl:px-0">
            <div className="pt-5">
              <div className="px-4 sm:px-6 md:px-10">
                <div className="py-4">
                  {/* Tabs */}
                    <Switch>
                      <Route exact path="/accountManagement">
                        <ManageAccount />
                      </Route>
                      <Route path="/accountManagement/register">
                        <CreateNewAccount />
                      </Route>
                    </Switch>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
