import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function PROTooltip(props) {
  return (
    <span className="pl-1 hover:text-yellow-400" data-tip="Drop-down menu...">
      <i className="far fa-info-circle" onClick={props.showProTooltip}></i>
      <Drawer
        title="PRO"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeProTooltip}
        visible={props.ProTooltip}
      >
        <h1>Entry Rule</h1>
        <p>Drop-down menu</p>
        <h1>Description</h1>
        <p>
          A performance rights organisation (PRO), also known as a performing
          rights society, provides intermediary functions, particularly
          collection of royalties, between copyright holders and parties who
          wish to use copyrighted works publicly in locations such as shopping
          and dining venues. Legal consumer purchase of works, such as buying
          CDs from a music store, confer private performance rights. PROs
          usually only collect royalties when use of a work is incidental to an
          organisation's purpose. Royalties for works essential to an
          organisation's purpose, such as theaters and radio, are usually
          negotiated directly with the rights holder.
        </p>

        <p>
          In some countries PROs are called copyright collectives or copyright
          collecting agencies. A copyright collective is more general than a PRO
          as it is not limited to performances and includes reproduction rights
          organisations (RROs). RROs represent works distributed via mediums
          such as CD, audiocassette, or computer file rather than use of works
          in public settings. (Source:
          https://en.wikipedia.org/wiki/Performance_rights_organisation)
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
