import { Button } from "antd";
import { useState, useCallback, useEffect } from "react";
import SlideOvers from "./SlideOvers";
import { useHistory } from "react-router-dom";

import MembersTable from "./MembersTable";
import axios from "axios";

export default function ManageAccount() {
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);

  const openOverlay = () => {
    setIsUserProfileOpen(true);
  };

  const closeOverlay = () => {
    setIsUserProfileOpen(false);
  };

  const [isMessageBroadcastOpen, setIsMessageBroadcastOpen] = useState(false);

  const closeMessageBroadcastOverlay = () => {
    setIsMessageBroadcastOpen(false);
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsUserProfileOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const history = useHistory();

  const handleRegistration = () => {
    history.push("/accountManagement/register");
  };

  return (
    <div>
      <div className=" space-y-6 sm:space-y-5">
      <div className="md:flex md:items-center md:justify-between mb-3">
          <h3 className="text-xl leading-6 font-medium text-gray-900 mt-3">
            Accounts
          </h3>
          <div className="flex md:mt-0 md:ml-4">
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={handleRegistration}
            >
              Register New Account
            </Button>
          </div>
          </div>
      </div>
      <div className="bg-white rounded-xl">
        <div>
          {isUserProfileOpen || isMessageBroadcastOpen ? (
            <button
              tabIndex="-1"
              className="fixed inset-0 h-full w-full bg-black opacity-5 cursor-default z-50"
            ></button>
          ) : null}
          <SlideOvers
            isUserProfileOpen={isUserProfileOpen}
            closeOverlay={() => closeOverlay()}
            isMessageBroadcastOpen={isMessageBroadcastOpen}
            closeMessageBroadcastOverlay={() => closeMessageBroadcastOverlay()}
            openOverlay={() => openOverlay()}
          />
        </div>

        <div className="mt-3">
          <MembersTable openOverlay={() => openOverlay()}/>
        </div>
      </div>
    </div>
  );
}
