import { useState } from "react";
import PlansSharedData from "./PlansSharedData";

const plansList = [
  {
    name: "6-Song Packet(s)",
    priceMonthly: "$.99 / yr",
    limit: "",
  },
];

export default function SongLibrayPlans() {
  const [showPlans, setShowPlans] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(plansList[0]);

  return (
    <>
      <PlansSharedData
        plansList={plansList}
        showPlans={showPlans}
        setShowPlans={setShowPlans}
        heading="SongLibrary"
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        fieldName="songLibrary"
      />
    </>
  );
}
