import React from "react";
import { useState } from "react";

export default function SendMessage() {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="">
      <div className="bg-white px-5 py-1 rounded-lg">
        <div className="sm:col-span-6  sm:row-span-3">
          <label
            htmlFor="history"
            className="inline-block text-md font-medium leading-5 text-cool-gray-900 hover:text-yellow-300 "
          >
            <span className="inline-flex rounded-md  py-2 ">
              <button
                onClick={handleSubmit}
                type="button"
                className="inline-flex items-center px-4 py-1 border border-gray-300  leading-6 font-medium text-sm rounded-md text-gray-700 bg-white hover:text-white hover:bg-yellow-400 focus:outline-none focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
              >
                Chat History
              </button>
            </span>
          </label>

          <div>
            <div className="mt-1 rounded-md">
              <textarea
                className="form-textarea inline-block border border-gray-300 p-2 rounded-md w-half text-md text-gray-500 focus:outline-none focus:ring-yellow-400 focus:border-yellow-400"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="8"
                cols="58"
                placeholder="Registration for Song-Camp 2019 is now open! Click here to sign up"
              />
            </div>
            <span className="inline-flex rounded-md py-1 ">
              <button
                onClick={handleSubmit}
                type="button"
                className="inline-flex items-center px-4 py-1 border border-gray-300 bg-yellow-400 text-sm leading-6 font-medium rounded-md text-white hover:text-white hover:bg-yellow-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 focus:bg-yellow-500 transition ease-in-out duration-150"
              >
                Send
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
