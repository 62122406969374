export default function SocialLinks(props) {
  const socialMediaUrls = props.user?.socialMediaUrls;

  const Icons = [
    {
      name: "Facebook",
      // src: facebookIcon,
      show: socialMediaUrls?.facebook,
      pathname: socialMediaUrls?.facebook,
      icon: <i className="fab fa-facebook"></i>,
    },
    {
      name: "Instagram",
      icon: <i className="fab fa-instagram"></i>,
      show: socialMediaUrls?.instagram,
      pathname: socialMediaUrls?.instagram,
    },
    {
      name: "Twitter",
      icon: <i className="fab fa-twitter"></i>,
      show: socialMediaUrls?.twitter,
      pathname: socialMediaUrls?.twitter,
    },
    {
      name: "LinkedIn",
      icon: <i className="fab fa-linkedin"></i>,
      show: socialMediaUrls?.linkedin,
      pathname: socialMediaUrls?.linkedin,
    },
    {
      name: "Youtube",
      icon: <i className="fab fa-youtube"></i>,
      show: socialMediaUrls?.youTube,
      pathname: socialMediaUrls?.youTube,
    },
    {
      name: "Apple Music",
      icon: <i className="fas fa-music"></i>,
      show: socialMediaUrls?.appleMusic,
      pathname: socialMediaUrls?.appleMusic,
    },
    {
      name: "Spotify",
      icon: <i className="fab fa-spotify"></i>,
      show: socialMediaUrls?.spotify,
      pathname: socialMediaUrls?.spotify,
    },
    {
      name: "SoundCloud",
      icon: <i className="fab fa-soundcloud"></i>,
      show: socialMediaUrls?.soundCloud,
      pathname: socialMediaUrls?.soundCloud,
    },
  ];

  return (
    <div>
      <dl className="flex space-x-4 pt-4">
        {Icons.map((item) =>
          item.show ? (
            <a
              href={item.pathname}
              target="_blank"
              rel="noreferrer"
              key={item.name}
              className="bg-white rounded-lg overflow-hidden text-2xl"
            >
              {item.icon}
            </a>
          ) : null
        )}
      </dl>
    </div>
  );
}
