import { Button } from "antd";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface Props {
  fieldName: string;
  label: string;
  shouldUnregister: boolean;
  children: React.ReactElement;
}

const FormFieldArray = (props: Props): JSX.Element => {
  const { register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: props.fieldName,
    shouldUnregister: props.shouldUnregister,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields, append]);

  return (
    <fieldset className="border border-solid border-gray-400 p-3 px-4">
      <legend className="text-sm font-bold text-gray-700 flex justify-between items-center">
        {props.label}
        <Button onClick={() => append({})}>Add</Button>
      </legend>
      {fields.map((field, index) => (
        <fieldset className="border border-solid border-gray-200 p-3 my-2" key={index}>
          <legend className="text-sm font-medium text-gray-700 flex justify-end items-center">
            {
              <Button
                danger
                disabled={index === 0}
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            }
          </legend>
          {React.Children.map(props.children, (child: React.ReactElement) => {
            return child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    name: `${props.fieldName}.${index}.${child.props.name}`,
                    register: register,
                    rules: { required: index > 0 },
                    key: field.id,
                  },
                })
              : child;
          })}
        </fieldset>
      ))}
    </fieldset>
  );
};

export default FormFieldArray;
