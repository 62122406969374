import { Link } from "react-router-dom";
import letterBox from "../../../Assets/letterBox.jpg";

export default function EmailVerification() {
  return (
    <>
      <div className="sidebarBgImg min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-7">
            <div className="px-5 mb-5">
              <img src={letterBox} alt="letterBox" />
            </div>
            <h1 className="text-center text-2xl">
              Please, verify your email address.
            </h1>
            <h2 className=" text-lg flex justify-center">
              A verification link has been sent to your email.
            </h2>
            <div className="border border-t mb-3"></div>
            <p className="text-gray-900">
              Please click on the link that has just been sent to your email
              account to verify your email and continue the registration
              process.
            </p>
            <p className="flex justify-center">
              <Link className="focus:outline-none" to="/">
                <span className="text-yellow-500 mx-1">Back to Login</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
