import { ErrorMessage } from "@hookform/error-message";
import { Button, DatePicker, Modal } from "antd";
import "antd/dist/antd.css";
import { DateTime } from "luxon";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { userStore } from "../../../../../../store/userStore";
import { authStore } from "../../../../../../store/authStore";
import Logo from "../../../../Assets/songwritersca.png";

import StudentAccount from "./StudentAccount";

export default function CreateMemberEditInfo() {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const accountType = userStore((state) => state.user.accountType);
  const status = userStore((state) => state.user.status);

  const [isFirstNameEdit, setIsFirstNameEdit] = useState(false);

  const [isLastNameEdit, setIsLastNameEdit] = useState(false);

  const loading = userStore((state) => state.loading);

  const history = useHistory();

  const logOut = () => {
    authStore
      .getState()
      .logout()
      .then(() => {
        history.push("/login");
      });
  };

  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);

  const onSubmit = async (data) => {
    let payload;
    let resp;

    switch (accountType) {
      case "Member":
        let dob;
        switch (status) {
          case "Student":
            dob = new DateTime.fromJSDate(data.dateOfBirth.toDate()).toFormat(
              "yyyy-MM-dd"
            );
            payload = { ...data, dateOfBirth: dob };

            resp = await userStore.getState().updateUser(payload);

            if (resp) {
              setIsStudentModalOpen(true);
            }
            break;

          default:
            dob = new DateTime.fromJSDate(data.dateOfBirth.toDate()).toFormat(
              "yyyy-MM-dd"
            );
            payload = { ...data, dateOfBirth: dob };

            resp = await userStore.getState().updateUser(payload);
            if (resp) {
              history.push("/profileWiz");
            }
        }
        break;

      default:
        payload = { ...data };
        resp = await userStore.getState().updateUser(payload);
        userStore.getState().updateUser(data);
        if (resp) {
          history.push("/profileWiz");
        }
    }
  };

  return (
    <div className="sidebarBgImg min-h-screen py-12 sm:px-6 lg:px-8 ">
      <div className="sm:mx-auto sm:w-8/12 rounded p-10 bg-gray-100">
        <div className="flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            <img className="h-8 w-auto" src={Logo} alt="Workflow" />
          </h3>
          <Button onClick={logOut} size="large" shape="round">
            Logout
          </Button>
        </div>

        <div className="mt-5 border-t border-gray-300">
          <dl className="divide-y divide-gray-200">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pt-4 sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  First Name
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {!isFirstNameEdit ? (
                      <div>{getValues("firstName")}</div>
                    ) : (
                      <input
                        className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        type="text"
                        {...register("firstName", {
                          required: "This field is mandatory",
                        })}
                      />
                    )}
                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="firstName"
                      className="mb-0 text-red-500"
                    />
                  </span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        if (getValues("firstName")) {
                          setIsFirstNameEdit(!isFirstNameEdit);
                        }
                      }}
                      className="rounded-md font-medium text-yellow-500 hover:text-yellow-600 focus:outline-none focus:ring-yellow-400 focus:ring-2 focus:ring-offset-2"
                    >
                      {isFirstNameEdit ? "Done" : "Update"}
                    </button>
                  </span>
                </dd>
              </div>

              <div className="py-3 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                <dt className="text-sm font-medium text-gray-500">Last Name</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {!isLastNameEdit ? (
                      <div>{getValues("lastName")}</div>
                    ) : (
                      <input
                        className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        type="text"
                        {...register("lastName", {
                          required: "This field is mandatory",
                        })}
                      />
                    )}
                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="lastName"
                      className="mb-0 text-red-500"
                    />
                  </span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        if (getValues("lastName")) {
                          setIsLastNameEdit(!isLastNameEdit);
                        }
                      }}
                      className="rounded-md font-medium text-yellow-500 hover:text-yellow-600 focus:outline-none focus:ring-yellow-400 focus:ring-2 focus:ring-offset-2"
                    >
                      {isLastNameEdit ? "Done" : "Update"}
                    </button>
                  </span>
                </dd>
              </div>

              <div className="py-3 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow ">
                    <div>{getValues("emailAddress")}</div>
                  </span>
                </dd>
              </div>

              <div className="py-3 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                <dt className="text-sm font-medium text-gray-500">Username</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    <input
                      className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      type="text"
                      {...register("username", {
                        required: "This field is mandatory",
                        minLength: {
                          value: 6,
                          message: "Username needs to be atleast 6 characters",
                        },
                      })}
                    />
                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="username"
                      className="mb-0 text-red-500"
                    />
                  </span>
                </dd>
              </div>
              {accountType === "Member" && (
                <>
                  <div className="py-3 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                    <dt className="text-sm font-medium text-gray-500">
                      Date of birth
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">
                        <Controller
                          control={control}
                          name="dateOfBirth"
                          rules={{
                            required: {
                              value: true,
                              message: "This field is mandatory",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              inputReadOnly
                              selected={value}
                              onChange={onChange}
                              placeholder="YYYY-MM-DD"
                              className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block sm:w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5 h-10"
                            />
                          )}
                        />
                        <ErrorMessage
                          as="p"
                          errors={errors}
                          name="dateOfBirth"
                          className="mb-0 text-red-500"
                        />
                      </span>
                    </dd>
                  </div>
                </>
              )}
              {status === "Student" ? <StudentAccount /> : null}

              <div className="flex justify-end mt-3">
                <Button
                  className="mt-5"
                  htmlType="submit"
                  size="large"
                  shape="round"
                  loading={loading}
                  type="primary"
                >
                  Save
                </Button>
              </div>
            </form>
          </dl>
        </div>
      </div>

      <Modal
        visible={isStudentModalOpen}
        closable={false}
        centered={true}
        keyboard={false}
        maskClosable={false}
        onOk={() => logOut()}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => logOut()}
          >
            Done
          </Button>,
        ]}
      >
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Request Recieved
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              We have recieved your documents, we will verify them and if you
              are eligible, we will send you a link to your email for account
              creation.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
