import { useFormContext } from "react-hook-form";

export default function Board() {
  const { register } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">Board</h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
          <label
            htmlFor="boards.title"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Title
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("boards.title", {
                required: false,
              })}
              id="boards.title"
              autoComplete="boards.title"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="boards.startDate"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Start Date
          </label>
          <div className="mt-1 rounded-md sm:col-span-2">
            <input
              type="text"
              {...register("boards.startDate", {
                required: false,
              })}
              id="boards.startDate"
              autoComplete="boards.startDate"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="boards.endDate"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            End Date
          </label>
          <div className="mt-1 rounded-md sm:col-span-2">
            <input
              type="text"
              {...register("boards.endDate", {
                required: false,
              })}
              id="boards.endDate"
              autoComplete="boards.endDate"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <div className="flex items-center">
            <input
              {...register("boards.alumni", {
                required: false,
              })}
              type="checkbox"
              className="focus:ring-yellow-400 form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded"
            />

            <label
              htmlFor="boards.alumni"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              Alumni
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
