import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../Assets/songwritersca.png";
import axios from "axios";
import toast from "react-hot-toast";
import { InformationCircleIcon } from "@heroicons/react/solid";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();

  const onSubmit = async (data) => {
    setSubmitting(true);
    axios
      .post("/users/password/requestReset", data)
      .then(() => {
        history.push("/login");
        toast.success(`Please check your inbox for further instructions`);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="py-12 bg-yellow-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            <div>
              <svg
                className="w-10 text-yellow-500"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 225 225"
              >
                <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                  <g>
                    <path
                      id="Layer0_0_1_STROKES"
                      className="st0"
                      d="M173.8,151.5l13.6-13.6 M35.4,89.9l29.1-29 M89.4,34.9v1 M137.4,187.9l-0.6-0.4     M36.6,138.7l0.2-0.2 M56.1,169.1l27.7-27.6 M63.8,111.5l74.3-74.4 M87.1,188.1L187.6,87.6 M110.8,114.5l57.8-57.8"
                    />
                  </g>
                </g>
              </svg>
            </div>
            {/* <div className="text-2xl text-yellow-800 tracking-wide ml-2 font-semibold">
              Songwriters Association of Canada
            </div> */}
          </div>
        </div>
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-yellow-500 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
          >
            Forgot Password
          </h2>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Enter your valid email before proceed to password reset.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="text-sm font-bold text-gray-700 tracking-wide">
                  Email Address
                </div>
                <input
                  {...register("emailAddress", {
                    required: "This field is mandatory",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Given email-id is not valid",
                    },
                  })}
                  className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:ring-yellow-400 focus:border-yellow-500 mt-2"
                  type="email"
                  placeholder="Enter your email"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="emailAddress"
                  className="w-6/12 text-red-500"
                />
              </div>

              <div className="mt-10">
                <input
                  type="submit"
                  className="bg-yellow-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600
                                shadow-lg"
                  value="Submit"
                  disabled={submitting}
                />
              </div>
            </form>
            <div className="mt-12 flex justify-center text-sm font-display font-semibold text-gray-700 ">
              Click here to
              <Link to="/login">
                <p className="cursor-pointer ml-2 text-yellow-500 hover:text-yellow-600">
                  Sign in
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex sidebarBgImg items-center justify-center bg-yellow-100 flex-1 min-h-screen">
        <div className="max-w-xs opacity-90 transform duration-200 hover:scale-110 cursor-pointer">
          <img className="h-18 w-auto" src={Logo} alt="Workflow" />
        </div>
      </div>
    </div>
  );
}
