import SongLibraryPlans from "./SongLibraryPlans";
// import { userStore } from "../../../../store/userStore";
// import { useState } from "react";
// import MemberTypePlans from "./MemberTypePlans";
// import AssociatePlans from "./AssociatePlan";

export default function PlanInfo() {
  // const accountType = userStore((state) => state.user.accountType);

  // const [accountType] = useState("member");

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Plan Information
        </h3>
      </div>
      <div className="space-y-4 ">
        {/* {accountType === "member" ? <MemberTypePlans /> : <AssociatePlans />} */}
        <SongLibraryPlans />
      </div>
    </>
  );
}
