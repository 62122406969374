import { useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import PRO from "../MultipleOptions/PRO";
import PublishserResidency from "../MultipleOptions/common/publisherFields/PublisherResidency";
import Role from "../MultipleOptions/common/commonFields/Role";
import CoAuthorNotice from "../MultipleOptions/coAuthorNotice";
import PublisherName from "../MultipleOptions/common/publisherFields/PublisherName";
import PublisherISNI from "../MultipleOptions/common/publisherFields/PublisherISNI";
import PublisherIPI from "../MultipleOptions/common/publisherFields/PublisherIPI";
import IPNTooltip from "../MultipleOptions/common/Tooltip/IPNTooltip";
import ISRC from "../MultipleOptions/common/commonFields/ISRC";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "react-input-mask";

export default function AddCreativeContributor(props) {
  const methods = useForm({
    defaultValues: {
      coAuthor: {
        firstName: "",
        ipi: "",
        isni: "",
        ipn: "",
        isrc: "",
        permissions: "viewOnly",
        publisher: {
          isni: "",
          ipi: "",
        },
      },
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
  } = methods;

  const percentLeft = props.percentLeft;

  const onSubmit = (data) => {
    if (percentLeft !== 1) {
      props.handleModal();
      props.setCoAuthor(data.coAuthor);
    }
  };

  const [ipn, setIpn] = useState(false);
  const showIpn = () => {
    setIpn(true);
  };
  const closeIpn = () => {
    setIpn(false);
  };

  //PublisherDetails

  const emailVerification = watch("coAuthor.sendRegistrationCopy");
  return (
    <>
      <div className="w-full justify-center items-center  overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full p-5 my-6 mx-auto max-w-6xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-xl font-semibold text-gray-600">
                Add Creative Contributor(s)
              </h3>
              <button
                type="button"
                // className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={props.handleModal}
                className="text-gray-600 h-6 w-6 -mt-1 text-2xl block outline-none focus:outline-none"
              >
                <span className="text-gray-600 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 p-5">
                  {/* <div className="flex"> */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="coAuthor.firstName"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Co-Author First Name
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        {...register("coAuthor.firstName", {
                          required: "This field is required",
                        })}
                        id="coAuthor.firstName"
                        className="p-2 focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                      <ErrorMessage
                        as="p"
                        errors={errors}
                        name="coAuthor.firstName"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="coAuthor.lastName"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Co-Author Last Name
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        name="coAuthor.lastName"
                        {...register("coAuthor.lastName", {
                          required: "This field is required",
                        })}
                        id="coAuthor.lastName"
                        className="p-2 focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                      <ErrorMessage
                        as="p"
                        errors={errors}
                        name="coAuthor.lastName"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="coAuthor.email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Co-Author Email
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        {...register("coAuthor.email", {
                          required: emailVerification
                            ? "This field is mandatory"
                            : null,
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Enter a valid email.",
                          },
                        })}
                        id="coAuthor.email"
                        className="p-2 focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                      <ErrorMessage
                        as="p"
                        errors={errors}
                        name="coAuthor.email"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  {/* </div> */}

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="coAuthor.ownership"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Co-Author Ownership
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        {...register("coAuthor.ownership", {
                          required: "This field is mandatory",
                          max: {
                            value: props.percentLeft - 1,
                            message: `You have only ${
                              props.percentLeft - 1
                            }% ownership left`,
                          },
                        })}
                        type="number"
                        step=".01"
                        id="coAuthor.ownership"
                        className="p-2 focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                      <ErrorMessage
                        as="p"
                        errors={errors}
                        name="coAuthor.ownership"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <PublisherIPI title="IPI#" fieldName="coAuthor.ipi" />

                  <PublisherISNI title="ISNI#" fieldName="coAuthor.isni" />

                  <section className="sm:col-span-3">
                    <label
                      htmlFor="coAuthor.ipn"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      IPN#
                      <IPNTooltip
                        ipn={ipn}
                        showIpn={showIpn}
                        closeIpn={closeIpn}
                      />
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <Controller
                        name="coAuthor.ipn"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputMask
                            mask="99999999"
                            value={value}
                            onChange={onChange}
                          >
                            {(inputProps) => (
                              <input
                                {...inputProps}
                                type="tel"
                                className="focus:ring-transparent focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </div>
                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="coAuthor.ipn"
                      className="mb-0 text-red-500"
                    />
                  </section>

                  <ISRC title="ISRC#" fieldName="coAuthor.isrc" />

                  <PublisherName fieldName="coAuthor.publisher.name" />

                  <PublisherISNI
                    title="Publisher-ISNI#"
                    fieldName="coAuthor.publisher.isni"
                  />

                  <PublisherIPI
                    title="Publisher-IPI#"
                    fieldName="coAuthor.publisher.ipi"
                  />

                  <PublishserResidency fieldName="coAuthor.publisher.isQuebecBased" />

                  <div className="sm:col-span-3 sm:row-span-2">
                    <label
                      htmlFor="coAuthor.headOfficeAddress"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Head Office Address
                    </label>
                    <div className="mt-1 rounded-md">
                      <textarea
                        name="coAuthor.headOfficeAddress"
                        {...register("coAuthor.headOfficeAddress")}
                        className="focus:outline-none focus:ring-transparent focus:border focus:border-yellow-300 shadow:none hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        rows="6"
                        placeholder="Enter some long form content."
                      />
                    </div>
                  </div>

                  <Role fieldName="coAuthor.role" />

                  <PRO fieldName="coAuthor.pro" />

                  <CoAuthorNotice fieldName="coAuthor.sendRegistrationCopy" />

                  {emailVerification ? (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="coAuthor.permissions"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Co-Author Permissions
                      </label>
                      <div
                        {...register("coAuthor.permissions")}
                        className="p-2 focus:outline-none focus:border focus:border-yellow-300 focus:shadow  rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        // value="View Only"
                      >
                        View Only
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="border-t mx-4"></div>

                <div className="flex items-center justify-end rounded-b p-2 ">
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={props.handleModal}
                  >
                    Close
                  </button>
                  <input
                    value="Add"
                    className="bg-yellow-400 text-white active:bg-white active:text-gray-700 font-bold uppercase text-sm px-6 py-2 rounded-lg hover:bg-yellow-400 shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                    // onClick={handleSubmit}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
