import { Select } from "antd";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  options?: string[];
  fields: string[];
}

function EnumFlagTagsFormField(props: Props): JSX.Element {
  const { control } = useFormContext();

  /** To process the list returned by select component and parse it to be assigned to the form  */
  const onValueChange = (v: number[]): number => {
    return v.reduce((acc, v) => acc + v, 0);
  }

  /** To convert form value in format that can be accepted by the Select component */
  const parseValue = (v: number): number[] => {
    const list: number[] = [];

    // TODO: cleanup and optimize
    props.options?.forEach((option, i) => {
      const value = Math.pow(2, i);
      if ((v & value) !== 0) {
        list.push(value);
      }
    });

    return list;
  }

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {props.label}
          </label>
          {/* Using styling and classes from existing components */}
          <Select
            style={{ width: "100%", padding: "2px" }}
            bordered={false}
            className="mt-1 w-full focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md border border-gray-300 block transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            mode="multiple"
            onChange={v => onChange(onValueChange(v))}
            value={parseValue(value)}
            placeholder={props.placeholder}
            options={props.options?.map((v, i) => ({ label: v, value: Math.pow(2, i) }))}
          ></Select>
        </>
      )}
    />
  );
}

export default EnumFlagTagsFormField;
