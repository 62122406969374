import React from "react";
import { useFormContext } from "react-hook-form";

export default function AdminRegistrationCommunication() {
  const { register } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Communications
        </h3>
      </div>

      <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="flex items-center">
          <input
            id="communication.newsletters"
            {...register("communication.newsletters", {
              required: false,
            })}
            type="checkbox"
            className="focus:ring-yellow-400 form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded"
          />

          <label
            htmlFor="communication.newsletters"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Subscribe to S.A.C. e-newsletter for advocacy alerts and program
            updates Subscribe to partner emails
          </label>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="flex items-center">
          <input
            id="communication.partnerEmails"
            {...register("communication.partnerEmails", {
              required: false,
            })}
            type="checkbox"
            className="focus:ring-yellow-400 form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded"
          />

          <label
            htmlFor="communication.partnerEmails"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Subscribe to partner emails
          </label>
        </div>
      </div>
    </div>
  );
}
