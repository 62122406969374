import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

export default function DynamicFieldSingle(props) {
  const { setValue, register, unregister, watch } = useFormContext();

  const values = watch(props.name) || [""];

  return (
    <fieldset>
      <legend className="text-sm font-medium text-gray-700 flex justify-between items-center">
        {props.label}
        <Button onClick={() => setValue(props.name, [...values, ""])}>
          Add
        </Button>
      </legend>

      {values?.map((_, index) => (
        <div key={index} className="flex justify-between items-center">
          <input
            type="text"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 w-full block sm:text-sm border-gray-300 rounded-md mt-1 mr-3"
            {...register(`${props.name}.${index}`, {
              required: index > 0 || values.length > 1,
            })}
          />
          <Button
            danger
            type="text"
            icon={<MinusCircleOutlined />}
            disabled={index === 0}
            onClick={() =>
              unregister(`${props.name}.${index}`, { keepValue: false })
            }
          ></Button>
        </div>
      ))}
    </fieldset>
  );
}

DynamicFieldSingle.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};
