import { toast } from "react-hot-toast";
import axios from "axios";
import create from "zustand";

export type AccountType = "Member" | "Associate" | "Staff";

export type UserDetails = {
  profileImageUrl: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  accountType: AccountType;
  dateOfBirth: string;
  region: string;
  role: string;
  status: string;
  username: string;
  salutation: string;
  pronouns: string;
  addressDetail: {
    line1: string;
    line2: string;
    postalCode: string;
    city: string;
    province: string;
    country: string;
  };
  contactDetail: {
    mobile: string;
    phone: string;
    website: string;
    businessPhone: string;
  };
  socialMediaUrls: {
    facebook: string;
    instagram: string;
    linkedin: string;
    soundCloud: string;
    spotify: string;
    twitter: string;
    youTube: string;
    appleMusic: string;
    videoURLs: string[];
    others: string[];
  };
  communicationPreferences: number;
  artisticInfo: {
    name: string;
    bands: {
      name: string;
      membersCount: number;
    }[];
    yearsExperience: string;
    biography: string;
    awards: {
      name: string;
      year: number;
    }[];
    genres: string[];
    albums: {
      name: string;
      year: number;
    }[];
    songPlacements: {
      title: string;
      year: number;
      details: string;
    }[];
    songsRecorded: [
      {
        title: string;
        year: number;
        details: string;
      }[]
    ];
    otherCredits: {
      title: string;
      year: number;
      details: string;
    }[];
    label: string;
    performingRightsOrganization: string;
    favoriteSingerWriter: string;
    iWrite: number;
    personalQuote: string;
    influences: string;
    soundsLike: string;
    interestedIn: number;
    prefersToWrite: number;
    proSongWorks: {
      name: string;
      city: string;
      date: Date;
    }[];
    regionalWritersGroup: string;
    songbirdNorth: {
      name: string;
      city: string;
      date: Date;
    }[];
    interviewQuestions: {
      questionId: number;
      answer: string;
    }[];
    manager: {
      firstName: string;
      lastName: string;
      companyName: string;
      email: string;
      phone: string;
    };
    publisher: {
      firstName: string;
      lastName: string;
      companyName: string;
      email: string;
      phone: string;
    };
  };
  education: {
    institution: string;
    studentNumber: string;
    studyYear: number;
    completionYear: number;
    studentCardFileId: number;
    degree: string;
    verificationStatus: string;
  };
  organization: {
    logoFileId: string;
    name: string;
    description: string;
    websiteUrl: string;
    status: string;
    startDate: string;
    headOfficeAddress: {
      line1: string;
      line2: string;
      postalCode: string;
      city: string;
      province: string;
      country: string;
    };
    billingAddress: {
      line1: string;
      line2: string;
      postalCode: string;
      city: string;
      province: string;
      country: string;
    };
    selfContact: true;
    contact: {
      firstName: string;
      lastName: string;
      companyName: string;
      title: string;
      email: string;
      phone: string;
    };
  };
  profilePreferences: number;
  isActive: boolean;
  isRegistrationCompleted: boolean;
  isOnboardingCompleted: boolean;
  permissions: string[];
  id: number;
  createdBy: number;
  createTime: string;
  archivedBy: number;
  archivedTime: string;
  isArchived: boolean;
};

type UserStore = {
  loading: boolean;
  user: UserDetails | null;
  reset: () => void;
  updateUser: (payload: Object) => Promise<boolean>;
  profileImageUrl: string;
};

// a quick implementation, needs to be optimized or redesigned
export const userStore = create<UserStore>((set, get, sub) => ({
  loading: true,
  user: null,
  profileImageUrl: "",
  reset: () => {
    set({loading: true, user: null, profileImageUrl: ""})
  },
  updateUser: async (payload: Object) => {
    set({ loading: true });
    try {
      const resp = await axios
        .put("/users/me", payload)
        .finally(() => set({ loading: false }));
      set({ user: resp.data,  profileImageUrl: resp.data?.['profileImageUrl'] });
      toast.success("Successfully updated!");
      return true;
    } catch {
      return false;
    }
  },
  fetchUser: async () => {
    set({ loading: true });
    try {
      const resp = await axios
        .get("/users/me")
        .finally(() => set({ loading: false }));
      set({ user: resp.data, profileImageUrl: resp.data?.['profileImageUrl'] });
    } catch {
      set({ user: null });
    }
  },
}));
