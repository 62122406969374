import { useState } from "react";
import Logo from "../../../../Assets/songwritersca.png";
import { Link } from "react-router-dom";
import PaymentSuccess from "../Modals/PaymentSuccess";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CreateMemberCardDetails from "./CreateMemberCardDetails";
import { Radio } from "antd";
import "react-credit-cards/es/styles-compiled.css";
import PayByChequeModal from "../Modals/PayByChequeModal";
// import NewCardDetails from "./NewCardDetails";

export default function CreateMemberInvoice(props) {
  const methods = useForm({});

  const {
    control,
    handleSubmit,
    watch,

    formState: { errors },
  } = methods;

  const data = {
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    status: props.status,
    renewalDetails: props.renewalDetails,
    proCode: props.proCode,
    accountType: props.accountType,
  };

  const paymentMethod = watch("createMember.paymentType");
  const [isPayByChequeModal, setIsPayByChequeModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = (data) => {
    if (paymentMethod === "payByCard") {
      setIsModalOpen(!isModalOpen);
    } else {
      setIsPayByChequeModal(!isPayByChequeModal);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="sidebarBgImg min-h-screen py-12 sm:px-6 lg:px-8 ">
        <div className="sm:mx-auto sm:w-8/12 rounded p-10 bg-gray-100">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              <img className="h-8 w-auto" src={Logo} alt="Workflow" />
            </h3>
          </div>

          <div className="mt-5 border-t border-gray-300">
            <dl className="divide-y divide-gray-200">
              <div className="w-2/4 py-4 sm:py-5">
                <div className="mb-2 md:mb-1 md:flex items-center">
                  <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                    First Name
                  </label>
                  <div className="capitalize">{data.firstName}</div>
                </div>

                <div className="mb-2 md:mb-1 md:flex items-center">
                  <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                    Last Name
                  </label>
                  <div className="capitalize">{data.lastName}</div>
                </div>

                <div className="mb-2 md:mb-1 md:flex items-center">
                  <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                    Email
                  </label>
                  <div className="">{data.email}</div>
                </div>

                <div className="mb-2 md:mb-1 md:flex items-center">
                  <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                    Date Of Birth
                  </label>
                  28/06/1989
                </div>

                {data.proCode ? (
                  <div className="mb-2 md:mb-1 md:flex items-center">
                    <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                      Pro Code
                    </label>
                    <div className="">{data.proCode}</div>
                  </div>
                ) : null}

                <div className="mb-2 md:mb-1 md:flex items-center">
                  <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                    Account Type
                  </label>
                  <div className="capitalize">{props.accountType}</div>
                </div>
                <div className="mb-2 md:mb-1 md:flex items-center">
                  <label className="w-32 block text-sm font-medium leading-5 text-gray-500 sm:py-2 py-4">
                    Renewal Details
                  </label>
                  Annual
                </div>
              </div>
              <div className="border-t border-gray-300"></div>

              <div className="py-2  w-full ">
                <div className="flex justify-between mb-3">
                  <div className="text-gray-800 text-right flex-1">
                    Subtotal
                  </div>
                  <div className="text-right w-40">
                    <div className="text-gray-800 font-medium"></div>
                  </div>
                </div>
                <div className="flex justify-between mb-3">
                  <div className="text-gray-800 text-right flex-1">
                    Subtotal Discount
                  </div>
                  <div className="text-right w-40">
                    <div className="text-gray-800 font-medium"></div>
                  </div>
                </div>
                <div className="flex justify-between mb-4">
                  <div className="text-sm text-gray-800 text-right flex-1">
                    GST(13%)
                  </div>
                  <div className="text-right w-40">
                    <div className="text-sm text-gray-600"></div>
                  </div>
                </div>

                <div className="">
                  <div className="flex justify-between">
                    <div className="text-sm text-gray-800 text-right flex-1">
                      Amount due
                    </div>
                    <div className="text-right w-40">
                      <div className="text-xl text-gray-800 font-bold"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 border-t border-gray-300 "></div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4">
                  <span className="text-gray-700">Payment Type</span>
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name="createMember.paymentType"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Radio.Group
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          defaultValue="payByCheque"
                        >
                          <Radio value="payByCard">Pay By Card</Radio>
                          <Radio value="payByCheque">Pay By Cheque</Radio>
                        </Radio.Group>
                      )}
                    />
                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="createMember.paymentType"
                      className="mb-0 text-red-500"
                    />
                  </div>
                  {watch("createMember.paymentType") === "payByCard" ? (
                    <CreateMemberCardDetails />
                  ) : // <NewCardDetails />
                  null}
                </div>

                <div className="flex justify-end mt-3">
                  <div className="flex items-center justify-end rounded-b mt-3">
                    <Link to="/onboarding">
                      <span className="inline-flex rounded-md">
                        <button className="bg-gray-200 text-gray-800 shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                          Back
                        </button>
                      </span>
                    </Link>
                  </div>
                  <div className="flex items-center justify-end rounded-b mt-3 ml-2">
                    <span className="inline-flex rounded-md">
                      <input
                        type="submit"
                        className="bg-gray-200 text-gray-800 shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                        value="Checkout"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </dl>
          </div>
        </div>
      </div>

      {isModalOpen ? (
        <PaymentSuccess
          isModalOpen={isModalOpen}
          // handleModal={() => handleModal()}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}

      {isPayByChequeModal ? (
        <PayByChequeModal
          isPayByChequeModal={isPayByChequeModal}
          setIsPayByChequeModal={setIsPayByChequeModal}
        />
      ) : null}
    </FormProvider>
  );
}
