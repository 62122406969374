import { useState } from "react";

import { Space, Popconfirm, Tooltip } from "antd";
import SongTable from "../../../../../CommonArea/CommonComponents/Table";

export default function RegisteredSongLibraryTable() {
  const columns = [
    {
      title: "Works Title",
      key: "songTitle",
      dataIndex: "songTitle",
    },
    {
      title: "Author",
      key: "author",
      dataIndex: "author",
    },
    {
      title: "Co-Author(s)",
      key: "coAuthor",
      dataIndex: "coAuthor",
    },
    {
      title: "Upload Date",
      key: "uploadDate",
      dataIndex: "uploadDate",
      sorter: (a, b) => a.uploadDate - b.uploadDate,
    },
    {
      title: "File Size",
      key: "fileSize",
      dataIndex: "fileSize",
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      render: () => (
        <Space size="middle" className="text-lg">
          <span className="text-blue-500">
            <Tooltip placement="topLeft" title="Play">
              <i className="far fa-play-circle"></i>
            </Tooltip>
          </span>

          <span className="text-yellow-500">
            <Tooltip placement="topLeft" title="Download">
              <i className="fas fa-download"></i>
            </Tooltip>
          </span>

          <span className="text-red-600">
            <Tooltip placement="topLeft" title="View Record">
              <i className="fas fa-file-pdf"></i>
            </Tooltip>
          </span>

          <span className="text-yellow-500">
            <Tooltip placement="topLeft" title="Edit">
              <i className="far fa-edit"></i>
            </Tooltip>
          </span>
          <span className="text-red-500">
            <Popconfirm
              title="Are you sure you want to delete this file？"
              onConfirm={() => handleDelete()}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topLeft" title="Delete">
                <i className="far fa-trash-alt"></i>
              </Tooltip>
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];

  const [dataSource, setDataSource] = useState({
    data: [
      {
        key: `blueRedBird`,
        songTitle: `Blue Red Bird`,
        author: `John Smith`,
        coAuthor: `Taylor Domino`,
        uploadDate: `2012-05-20`,
        fileSize: `4 MB`,
        songSrc:
          "http://dl2.djring.com/sd2.djring.com/128/508366/(DJJOhAL.Com).mp3",
      },
      {
        key: `flipFlop`,
        songTitle: `Flip Flop`,
        author: `John Smith`,
        coAuthor: `Taylor Domino`,
        uploadDate: `2011-02-20`,
        fileSize: `4 MB`,
      },
      {
        key: `RockPaperScissors`,
        songTitle: `Rock Paper Scissors`,
        author: `John Smith`,
        coAuthor: `Taylor Domino`,
        uploadDate: `2021-01-20`,
        fileSize: `4 MB`,
      },
    ],
  });

  const handleDelete = (key) => {
    const dataVal = [...dataSource.data];
    setDataSource({
      data: dataVal.filter((item) => item.key !== key),
    });
  };

  return (
    <div>
      <SongTable columns={columns} data={dataSource.data} />
    </div>
  );
}
