import React from "react";
import { useFormContext } from "react-hook-form";

export default function PublisherName(props) {
  const { register } = useFormContext();

  return (
    <div className="sm:col-span-3 w-full">
      <label
        htmlFor="publisherName"
        className="block text-sm font-medium leading-58 text-gray-700"
      >
        Publisher Name
      </label>
      <div className="mt-1 rounded-md">
        <input
          {...register(`${props.fieldName}`)}
          id="publisherName"
          className="focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        />
      </div>
    </div>
  );
}
