import { Pagination } from "antd";
import { Link } from "react-router-dom";
import avatar from "../../../Assets/avatar.png";

export default function PublicProfileSearchResults(props) {
  const people = props.people;

  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
        {people?.users.map((person) => (
          <li
            key={person.username}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
          >
            <div className="flex-1 flex flex-col p-8">
              <img
                className="w-32 h-32 object-cover flex-shrink-0 mx-auto rounded-full"
                src={person?.profileImageUrl ? person?.profileImageUrl : avatar}
                onError={(e) => {
                  e.target.src = avatar;
                }}
                alt=""
              />
              <h3 className="mt-6 text-gray-900 text-sm font-medium">
                {person.displayName}
              </h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-gray-500 text-sm capitalize">
                  {person.region}
                </dd>
                <dt className="sr-only">Genre</dt>
                <dd className="text-gray-500 text-sm">{person.genre}</dd>
                <dt className="sr-only">Role</dt>
                <dd className="mt-3">
                  <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                    Member Since:{" "}
                    {months[new Date(person.createTime).getUTCMonth()]}{" "}
                    {new Date(person.createTime).getUTCFullYear()}
                  </span>
                </dd>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                  <Link
                    to={`/users/${person.username}`}
                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Pagination defaultCurrent={1} current={props.currentPage} defaultPageSize={10} total={props.people?.totalResults} pageSizeOptions={[10, 20, 30]} onChange={props.paginationChange}/>
    </>
  );
}
