import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Radio } from "antd";
import ExplicitContentTooltip from "../Tooltip/ExplicitContentTooltip";

export default function ExplicitContent() {
  const { control } = useFormContext();

  //ExplicitContent

  const [explicitTooltip, setExplicitTooltip] = useState(false);
  const handleExplicitTooltip = () => {
    setExplicitTooltip(!explicitTooltip);
  };

  return (
    <div className="sm:col-span-3">
      <label className="block text-sm font-medium text-gray-700">
        Explicit Content
        <ExplicitContentTooltip
          explicitTooltip={explicitTooltip}
          showExplicitTooltip={handleExplicitTooltip}
          closeExplicitTooltip={handleExplicitTooltip}
        />
      </label>
      <div className="mt-2">
        <Controller
          control={control}
          name="explicitContent"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Radio.Group
              onBlur={onBlur}
              onChange={onChange}
              selected={value}
              inputRef={ref}
              style={{ width: "100%", fontSize: "14px" }}
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no"> No</Radio>
            </Radio.Group>
          )}
        />
      </div>
    </div>
  );
}
