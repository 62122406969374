import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import IPITooltip from "../Tooltip/IPITooltip";

export default function PublisherIPI(props) {
  const { control } = useFormContext();

  const [ipiTooltip, setIpiTooltip] = useState(false);
  const showIpiTooltip = () => {
    setIpiTooltip(true);
  };
  const closeIpiTooltip = () => {
    setIpiTooltip(false);
  };
  return (
    <div className="sm:col-span-3 w-full">
      <label
        htmlFor="IPI"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {props.title}

        <IPITooltip
          ipiTooltip={ipiTooltip}
          showIpiTooltip={showIpiTooltip}
          closeIpiTooltip={closeIpiTooltip}
        />
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <Controller
          name={props.fieldName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputMask mask="9-999999999-9" onChange={onChange} value={value}>
              {(inputProps) => (
                <input
                  {...inputProps}
                  type="tel"
                  className="focus:ring-transparent focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              )}
            </InputMask>
          )}
        />
      </div>
    </div>
  );
}
