import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { profilePreferences } from "../../../../../store/profilePreferences";
import EnumFlagFormComponent from "../../../../CommonArea/CommonComponents/EnumFlagFormField";

export default function MemberSettingsÎ() {
  const { register, watch } = useFormContext();

  const profileUrl = useRef({});
  profileUrl.current = watch("username", "");

  const communications = [
    {
      title:
        "Subscribe to S.A.C. e-newsletter for advocacy alerts and program updates",
      description: "",
    },
    { title: "Subscribe to partner emails", description: "" },
  ];
  return (
    <>
      <div>
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Settings
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
      </div>

      <ul className="mt-6 divide-y divide-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Profile Preferences
        </h3>
        <div as="li" className="py-4 flex items-center justify-between">
          <div className="flex flex-col">
            <div as="p" className="text-sm font-medium text-gray-900">
              Username
            </div>
            <div className="text-sm text-gray-500">
              Your unique app.songwriters.ca URL.
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-sm mb-0 mt-1">{`app.songwriters.ca/users/`}</p>
            <input
              type="text"
              {...register("username", {
                required: false,
              })}
              id="username"
              autoComplete="username"
              className="shadow-sm ml-1 focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            />
          </div>
        </div>

        <EnumFlagFormComponent
          name="profilePreferences"
          fields={profilePreferences}
          renderCondition={(v) => v.accountTypes.includes("Member")}
        />
      </ul>

      <ul className="mt-6 divide-y divide-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Communications
        </h3>

        <EnumFlagFormComponent
          name="communicationPreferences"
          fields={communications}
          renderCondition={(v) => true}
        />
      </ul>
    </>
  );
}
