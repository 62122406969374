import { useFormContext } from "react-hook-form";

export default function Donor() {
  const { register } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">Donor</h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="donor.charitableRecieptUrl"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Charitable Receipt
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="donor.charitableRecieptUrl"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span className="text-yellow-400"> Upload a file</span>
                    <input
                      id="donor.charitableRecieptUrl"
                      type="file"
                      className="sr-only"
                      {...register("donor.charitableRecieptUrl", {
                        required: false,
                      })}
                    />
                  </label>
                  <p className="pl-1 text-gray-600">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">DOCX, PDF up to 10MB</p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="donor.autoRenewal"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            AutoRenewal
          </label>
          <div className="flex items-center">
            <input
              id="donor.autoRenewal"
              {...register("donor.autoRenewal", {
                required: false,
              })}
              type="checkbox"
              className="focus:ring-yellow-300 form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded"
            />
            <label
              htmlFor="donor.autoRenewal"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              AutoRenewal
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
