import { Table } from "antd";

export default function ArchivedSongsTable() {
  const columns = [
    {
      title: "Works Title",
      key: "worksTitle",
      dataIndex: "worksTitle",
    },
    {
      title: "Author",
      key: "author",
      dataIndex: "author",
    },
    {
      title: "Co-Author(s)",
      key: "coAuthor",
      dataIndex: "coAuthor",
    },
    {
      title: "Registration Date",
      key: "registrationDate",
      dataIndex: "registrationDate",
    },
    {
      title: "Expired On",
      key: "expiredOn",
      dataIndex: "expiredOn",
    },
  ];
  const data = [
    {
      key: `0`,
      worksTitle: `Founder`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      registrationDate: `2018-05-22`,
      expiredOn: `2021-05-21`,
    },
    {
      key: `1`,
      worksTitle: `Chungus`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      registrationDate: `2018-05-22`,
      expiredOn: `2021-05-21`,
    },
    {
      key: `2`,
      worksTitle: `Tuttofare`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      registrationDate: `2018-05-22`,
      expiredOn: `2021-05-21`,
    },
    {
      key: `3`,
      worksTitle: `Better Half`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      registrationDate: `2018-05-22`,
      expiredOn: `2021-05-21`,
    },
    {
      key: `4`,
      worksTitle: `Head Chef`,
      author: `John Smith`,
      coAuthor: `Taylor Domino`,
      registrationDate: `2018-05-22`,
      expiredOn: `2021-05-21`,
    },
  ];

  return (
    <div>
      <Table
        className="overflow-y-scroll text-gray-500 mb-3 border border-gray-300 p-5 rounded-lg mt-2"
        columns={columns}
        dataSource={data}
        scroll={{ y: 540 }}
        locale={{ emptyText: " " }}
      />
    </div>
  );
}
