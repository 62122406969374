import { RadioGroup } from "@headlessui/react";
import { Controller, useFormContext } from "react-hook-form";

export default function PlansSharedData(props) {
  const heading = props.heading;
  const showPlans = props.showPlans;
  const setShowPlans = props.setShowPlans;
  const plansList = props.plansList;
  // const selectedPlan = props.selectedPlan;
  // const setSelectedPlan = props.setSelectedPlan;

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const { control } = useFormContext();
  return (
    <>
      <div className="relative bg-white  -space-y-px">
        <button
        type="button"
          onClick={() => setShowPlans(!showPlans)}
          className="relative border w-full p-4  flex flex-col hover:bg-yellow-50 hover:border-yellow-200 md:grid md:grid-cols-3 focus:outline-none"
        >
          <div className="flex items-center text-sm">
            <span className="ml-3 font-medium ">{heading}</span>
            {!showPlans ? (
              <span
                className="pointer-events-none absolute animate-bounce right-6 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            ) : null}
          </div>
        </button>

        {showPlans ? (
          <Controller
            control={control}
            name={`plan.${props.fieldName}`}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                  Pricing plans
                </RadioGroup.Label>
                <div className="relative bg-white rounded-md -space-y-px">
                  {plansList.map((plan, planIdx) => (
                    <RadioGroup.Option
                      key={plan.name}
                      value={plan}
                      className={({ checked }) =>
                        classNames(
                          planIdx === 0 ? "" : "",
                          planIdx === plansList.length - 1 ? "" : "",
                          checked
                            ? "bg-yellow-50 border-yellow-200 z-10"
                            : "border-gray-200",
                          "relative border p-4 px-10 flex flex-col cursor-pointer  md:grid md:grid-cols-3 focus:outline-none"
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <div className="flex items-center text-sm">
                            <span
                              className={classNames(
                                checked
                                  ? "bg-yellow-500 border-transparent"
                                  : "bg-white border-gray-300",
                                active
                                  ? "ring-2 ring-offset-2 ring-yellow-400"
                                  : "",
                                "h-4 w-4 rounded-full border flex items-center justify-center"
                              )}
                              aria-hidden="true"
                            >
                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <RadioGroup.Label
                              as="span"
                              className="ml-3 font-medium text-gray-900"
                            >
                              {plan.name}
                            </RadioGroup.Label>
                          </div>
                          <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                            <span
                              className={classNames(
                                checked ? "text-yellow-900" : "text-gray-900",
                                "font-medium"
                              )}
                            >
                              {plan.priceMonthly}
                            </span>
                          </RadioGroup.Description>
                          <RadioGroup.Description
                            className={classNames(
                              checked ? "text-yellow-700" : "text-gray-500",
                              "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                            )}
                          >
                            {plan.limit}
                          </RadioGroup.Description>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            )}
          />
        ) : null}
      </div>
    </>
  );
}
