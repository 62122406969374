import { useFormContext } from "react-hook-form";
import DynamicFieldSingle from "../../../../CommonArea/CommonComponents/UserInfoForms/MemberInfoForms/ArtisticInfoForm/DynamicFieldSingle";

export default function SocialMedia() {
  const { register } = useFormContext();

  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Social Media
        </h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.facebook"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Facebook
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.facebook", { required: false })}
              id="socialMediaUrls.facebook"
              autoComplete="socialMediaUrls.facebook"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.instagram"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Instagram
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.instagram", { required: false })}
              id="socialMediaUrls.instagram"
              autoComplete="socialMediaUrls.instagram"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.linkedin"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            LinkedIn
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.linkedin", { required: false })}
              id="socialMediaUrls.linkedin"
              autoComplete="socialMediaUrls.linkedin"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.soundCloud"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            SoundCloud
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.soundCloud", { required: false })}
              id="socialMediaUrls.soundCloud"
              autoComplete="socialMediaUrls.soundCloud"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.spotify"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Spotify
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.spotify", { required: false })}
              id="socialMediaUrls.spotify"
              autoComplete="socialMediaUrls.spotify"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.twitter"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Twitter
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.twitter", { required: false })}
              id="socialMediaUrls.twitter"
              autoComplete="socialMediaUrls.twitter"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="socialMediaUrls.youTube"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            YouTube
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("socialMediaUrls.youTube", { required: false })}
              id="socialMediaUrls.youTube"
              autoComplete="socialMediaUrls.youTube"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <DynamicFieldSingle
            name="socialMediaUrls.videoURLs"
            label="Video URL"
          />
        </div>

        <div>
          <DynamicFieldSingle name="socialMediaUrls.others" label="Others" />
        </div>
      </div>
    </div>
  );
}
