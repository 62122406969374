import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function PublisherResidencyTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Yes/No or depending on the various metadata entry software and interfaces available on the market..."
    >
      <i className="far fa-info-circle" onClick={props.showIsniTooltip}></i>

      <Drawer
        title="Is the Publisher based in Quebec"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeIsniTooltip}
        visible={props.isniTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          Yes/No or depending on the various metadata entry software and
          interfaces available on the market.
        </p>
        <h1>Description</h1>
        <p>
          The various “origin” type fields are used to identify content from
          Québec for puposes of discoverability, regulations and statistics.
        </p>
        <p>
          This identification is partly based on the origin of the publishers of
          the work as determined in the current field.
        </p>
        <p>
          The criteria used to determine the Québec origin of a track were
          elaborated by professional music associations (ADISQ, APEM, GMMQ,
          SPACQ and UDA). The creation of a validation process is being
          considered but does not exist yet.
        </p>
        <h1>Use</h1>
        <p>
          The city where the company's head office is located will be used as
          the descriptive field for the attribution of this status. This field
          will be linked to a UN/LOCODE.
        </p>
        <p>
          If the author or composer is not represented by a publisher, they are
          considered to be their own publisher.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
