import { ErrorMessage } from "@hookform/error-message";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { message } from "../../../../assets/info-modal-message";
import { authStore } from "../../../../store";
import Logo from "../../Assets/songwritersca.png";

export default function Login(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    Modal.info(message);
  }, []);

  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setSubmitting(true);
    await authStore
      .getState()
      .login(data.signin)
      .finally(() => setSubmitting(false));
    const authState = authStore.getState().authState;
    const user = authStore.getState().user;
    if (authState === "Authenticated") {
      toast.success(`Welcome ${user.displayName}`);
      props.history.push("/");
    }
  };

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-yellow-500 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
          >
            Sign in
          </h2>
          <div className="mt-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className=" font-bold text-gray-700 tracking-wide mb-1">
                  Email Address
                </div>
                <input
                  {...register("signin.userName", {
                    required: "This field is mandatory",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Given email-id is not valid",
                    },
                  })}
                  className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                  type="email"
                  placeholder="Enter your email"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="signin.username"
                  className="text-red-500"
                />
              </div>
              <div className="mt-8">
                <div className="flex justify-between items-center">
                  <div className=" font-bold text-gray-700 tracking-wide mb-1">
                    Password
                  </div>
                </div>
                <input
                  className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                  type="password"
                  {...register("signin.password", {
                    required: "Password is required",
                  })}
                  placeholder="Enter your password"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="signin.password"
                  className="text-red-500"
                />
              </div>
              <Link to="/forgotPassword">
                <div
                  className="text-xs font-display font-semibold text-yellow-500 hover:text-yellow-600 bg-white cursor-pointer my-5 float-right"
                  value="Forgot Password?"
                >
                  Forgot Password
                </div>
              </Link>
              <div className="mt-10">
                <input
                  type="submit"
                  className="bg-yellow-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600
                                shadow-lg"
                  value="Sign in"
                  disabled={isSubmitting}
                />
              </div>
            </form>
            <div className="mt-12 flex justify-center  font-display font-semibold text-gray-700 ">
              Don't have an account ?
              {/* <button onClick={props.handleIsLogin}> */}
              <Link to="/signup">
                <p className="cursor-pointer ml-2 text-yellow-500 hover:text-yellow-600">
                  Sign up
                </p>
              </Link>
              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex sidebarBgImg items-center justify-center bg-yellow-100 flex-1 min-h-screen">
        <div className="max-w-xs opacity-90 transform duration-200 hover:scale-110 cursor-pointer">
          <img className="h-18 w-auto" src={Logo} alt="Workflow" />
        </div>
      </div>
    </div>
  );
}
