import { Route, Switch } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import CreateMemberEditInfo from "./CreateMemberEditInfo";
import CreateMemberInvoice from "./CreateMemberInvoice";
import { userStore } from "../../../../../store/userStore";
import { useEffect } from "react";

export default function CreateMemberPayment() {
  const user = userStore(state=> state.user);

  const methods = useForm();
  const { reset } = methods;

  useEffect(() => {
    reset(user ?? {});
  }, [user, reset]);

  return (
    <FormProvider {...methods}>
      <Switch>
        <Route exact path="/onboarding">
          <CreateMemberEditInfo />
        </Route>
        <Route path="/onboarding/payment">
          <CreateMemberInvoice />
        </Route>
      </Switch>
    </FormProvider>
  );
}
