import { useState } from "react";
import ISNITooltip from "../Tooltip/ISNITooltip";
import InputMask from "react-input-mask";
import { useFormContext, Controller } from "react-hook-form";

export default function PublisherISNI(props) {
  const { control } = useFormContext();

  const [isniTooltip, setIsniTooltip] = useState(false);
  const showIsniTooltip = () => {
    setIsniTooltip(true);
  };
  const closeIsniTooltip = () => {
    setIsniTooltip(false);
  };

  return (
    <div className="sm:col-span-3 w-full ">
      <label
        htmlFor="ISNI"
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {props.title}
        <ISNITooltip
          isniTooltip={isniTooltip}
          showIsniTooltip={showIsniTooltip}
          closeIsniTooltip={closeIsniTooltip}
        />
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <Controller
          name={props.fieldName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="9999-9999-9999-9999"
              onChange={onChange}
              value={value}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  type="tel"
                  className="focus:ring-transparent focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              )}
            </InputMask>
          )}
        />
      </div>
    </div>
  );
}
