import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function WorksTitleTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Articles are placed last when entering the work title. The data is entered in uppercase characters without accents. Thus, the title of the work Un beau grand bateau will be entered as BEAU GRAND BATEAU UN.
      This method of entering a work title complies with the format of the large SOCAN database. Note: the track title must be entered differently, see Track – Title *..."
    >
      <i
        className="far fa-info-circle"
        onClick={props.showWorksTitleTooltip}
      ></i>

      <Drawer
        title="Song Title"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeWorksTitleTooltip}
        visible={props.worksTitleTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          Articles are placed last when entering the work title. The data is
          entered in uppercase characters without accents. Thus, the title of
          the work Un beau grand bateau will be entered as BEAU GRAND BATEAU UN.
          This method of entering a work title complies with the format of the
          large SOCAN database. Note: the track title must be entered
          differently, see Track – Title *.
        </p>
        <h1>Description</h1>
        <p>
          The track title is the name given to a sound recording. Do not look
          any further, it is indeed the title of a track!
        </p>
        <h1>Use</h1>
        <p>
          This is a mandatory field. The track title must not include details
          about the version because there is another specific field for this
          information (for example if the track is a live version or if it is
          featuring a guest artist).
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
