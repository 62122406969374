const genreOptions = [
  "Ambient",
  "Blues",
  "Classical",
  "Christian/Gospel",
  "Country",
  "Dance/Electronic",
  "Folk",
  "Jazz",
  "R&B",
  "Rap",
  "Reggae",
  "Rock",
  "Other",
  "Acoustic",
  "Adult Alternative",
  "Adult",
  "Contemporary",
  "Alternative",
  "Americana",
  "Arranging",
  "Beat Making",
  "Children's",
  "Contemporary Instrumental",
  "Contemporary roots",
  "Francophone",
  "Funk",
  "Hip-Hop",
  "Indie Rock",
  "Indigenous",
  "Instrumental",
  "International",
  "Metal/Hard",
  "Music For Video/Film",
  "Music for Visual Media",
  "Musical Theatre",
  "Pop",
  "Punk",
  "Soul",
  "Spoken Word",
  "Top Liner",
  "Traditional roots",
  "World",
];
export default genreOptions.sort();
