import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import AvatarUpload from "../../AvatarUpload";
import AssociateAddress from "./AssociateAddress";

export default function AssociateInfoForm() {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    unregister,
    getValues,
  } = useFormContext();

  const watchAddressLine1 = watch("organization.headOfficeAddress.line1");
  const watchAddressLine2 = watch("organization.headOfficeAddress.line2");
  const watchAddressCity = watch("organization.headOfficeAddress.city");
  const watchAddressProvince = watch("organization.headOfficeAddress.province");
  const watchAddressCountry = watch("organization.headOfficeAddress.country");
  const watchAddressPostalCode = watch(
    "organization.headOfficeAddress.postalCode"
  );

  const [isSelfContact, setIsSelfContact] = useState(true);

  const billingAddressHandler = () => {
    setValue("organization.billingAddress.line1", watchAddressLine1);
    setValue("organization.billingAddress.line2", watchAddressLine2);
    setValue("organization.billingAddress.city", watchAddressCity);
    setValue("organization.billingAddress.province", watchAddressProvince);
    setValue("organization.billingAddress.country", watchAddressCountry);
    setValue("organization.billingAddress.postalCode", watchAddressPostalCode);
  };

  const selfContactHandler = () => {
    setIsSelfContact(!isSelfContact);
    if (!!isSelfContact) {
      unregister("organization.contact");
    }
  };

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Associate Information
        </h3>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label
            htmlFor="profilePhoto"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Profile Photo
          </label>
          <AvatarUpload
            placeholderText="Upload Logo"
            imageUrl={getValues("profileImageUrl")}
          />
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Company / Business Name
          </label>

          <input
            type="text"
            {...register("organization.name", {
              required: false,
            })}
            id="name"
            autoComplete="name"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <ErrorMessage
            as="p"
            errors={errors}
            name="organization.name"
            className="text-red-500"
          />
        </div>

        <AssociateAddress
          field="organization.headOfficeAddress"
          addressLine1="Head Office Address(Line1)"
          addressLine2="Head Office Address(Line2)"
        />

        <div className="sm:col-span-6">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Phone
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("organization.phone", {
                required: false,
                minLength: 6,
                maxLength: 12,
              })}
              id="phone"
              autoComplete="phone"
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="organization.phone"
              className="text-red-500"
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="websiteURL"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Company Website
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className=" flex rounded-md shadow-sm">
              <input
                type="text"
                {...register("organization.websiteURL", {
                  required: false,
                })}
                id="websiteURL"
                autoComplete="websiteURL"
                className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
              />
            </div>
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="status"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Status
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              {...register("organization.status", {
                required: false,
              })}
              autoComplete="status"
              defaultValue=""
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            >
              <option value="" disabled>
                Select status
              </option>
              <option value="nonProfit">Non-Profit</option>
              <option value="charity">Charity</option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="organization.status"
              className="text-red-500"
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Description
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="description"
              {...register("organization.description")}
              rows="5"
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            />
            <p className="mt-2 text-sm text-gray-500">
              Write a few sentences about yourself.
            </p>
          </div>
        </div>

        <div className="sm:col-span-6">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={billingAddressHandler}
              className=" shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:ring-transparent focus:border-blue-300 shadow:none-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Copy the Head Office Address
            </button>
          </div>
        </div>

        <div className="sm:col-span-6">
          <span className="text-base font-bold">Billing Address</span>
        </div>
        <AssociateAddress
          field="organization.billingAddress"
          addressLine1="Address (Line1)"
          addressLine2="Address (Line2)"
        />

        <div className="sm:col-span-6">
          <div className="flex items-center">
            <input
              id="selfContact"
              {...register("organization.selfContact")}
              type="checkbox"
              checked={isSelfContact}
              onClick={selfContactHandler}
              className="form-checkbox focus:outline-none focus:ring-transparent h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded"
            />
            <label
              htmlFor="selfContact"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              Appoint other person as Associate Representative
            </label>
          </div>
        </div>

        {isSelfContact ? (
          <>
            <div className="sm:col-span-3">
              <label
                htmlFor="contact.firstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px"
              >
                First Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register("organization.contact.firstName", {
                    shouldUnregister: true,
                    required: "This field is mandatory",
                  })}
                  id="contact.firstName"
                  className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="organization.contact.firstName"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="contact.lastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px"
              >
                Last Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register("organization.contact.lastName", {
                    shouldUnregister: true,
                    required: "This field is mandatory",
                  })}
                  id="contact.lastName"
                  className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                />

                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="organization.contact.lastName"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="contact.title"
                className="block text-sm font-medium text-gray-700 sm:mt-px"
              >
                Title
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register("organization.contact.title", {
                    shouldUnregister: true,
                    required: false,
                  })}
                  id="contact.title"
                  className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="contactPhone"
                className="block text-sm font-medium text-gray-700 sm:mt-px"
              >
                Phone
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="tel"
                  {...register("organization.contact.phone", {
                    shouldUnregister: true,
                    required: false,
                    maxLength: 12,
                    minLength: 6,
                  })}
                  id="contactPhone"
                  className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="organization.contact.phone"
                  className="text-red-500"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 sm:mt-px"
              >
                Email
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register("organization.contact.email", {
                    shouldUnregister: true,
                    required: "This field is mandatory",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Given email-id is not valid",
                    },
                  })}
                  id="email"
                  className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="organization.contact.email"
                  className="text-red-500"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
