import { useFormContext } from "react-hook-form";
import DynamicFieldSingle from "./ArtisticInfoForm/DynamicFieldSingle";

export default function SocialMediaForm() {
  const { register } = useFormContext();

  return (
    <div className="">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Social Media
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Upload links to your Social Media profiles here!
        </p>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="facebook"
            className="block text-sm font-medium text-gray-700"
          >
            Facebook
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.facebook", { required: false })}
            id="facebook"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.instagram"
            className="block text-sm font-medium text-gray-700"
          >
            Instagram
          </label>
          <input
            type="text"
            {...register("socialMediaUrls.instagram", { required: false })}
            id="socialMediaUrls.instagram"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.linkedIn"
            className="block text-sm font-medium text-gray-700"
          >
            LinkedIn
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.linkedin", { required: false })}
            id="socialMediaUrls.linkedIn"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.soundCloud"
            className="block text-sm font-medium text-gray-700"
          >
            SoundCloud
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.soundCloud", { required: false })}
            id="socialMediaUrls.soundCloud"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.spotify"
            className="block text-sm font-medium text-gray-700"
          >
            Spotify
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.spotify", { required: false })}
            id="socialMediaUrls.spotify"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.twitter"
            className="block text-sm font-medium text-gray-700"
          >
            Twitter
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.twitter", { required: false })}
            id="socialMediaUrls.twitter"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.youTube"
            className="block text-sm font-medium text-gray-700"
          >
            YouTube
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.youTube", { required: false })}
            id="socialMediaUrls.youTube"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="socialMediaUrls.appleMusic"
            className="block text-sm font-medium text-gray-700"
          >
            Apple Music
          </label>

          <input
            type="text"
            {...register("socialMediaUrls.appleMusic", { required: false })}
            id="socialMediaUrls.appleMusic"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-6">
          <DynamicFieldSingle name="socialMediaUrls.others" label="Others" />
        </div>

        <div className="sm:col-span-6">
          <DynamicFieldSingle
            name="socialMediaUrls.videoURLs"
            label="Video URL"
          />
        </div>
      </div>
    </div>
  );
}
