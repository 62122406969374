import { Space, Tooltip } from "antd";
import SongTable from "../../../CommonArea/CommonComponents/Table";
import { useState } from "react";

export default function RegisteredSongsTable() {
  const columns = [
    {
      title: "Works Title",
      key: "worksTitle",
      dataIndex: "worksTitle",
    },
    {
      title: "Registration Date",
      key: "registrationDate",
      dataIndex: "registrationDate",
    },
    {
      title: "Expiry Date",
      key: "expiryDate",
      dataIndex: "expiryDate",
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      render: () => (
        <Space size="middle" className="text-lg">
          <span className="text-blue-500">
            <Tooltip placement="topLeft" title="Download">
              <i className="fas fa-download"></i>
            </Tooltip>
          </span>
          <span className="text-red-600">
            <Tooltip placement="topLeft" title="View Certificate">
              <i className="fas fa-file-pdf"></i>
            </Tooltip>
          </span>
          <span className="text-yellow-400">
            <Tooltip placement="topLeft" title="Renew Certificate">
              <i className="fas fa-sync-alt"></i>
            </Tooltip>
          </span>
          <span className="text-red-600">
            <Tooltip placement="topLeft" title="Cancel Subscription">
              <i className="fas fa-ban"></i>
            </Tooltip>
          </span>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: `blueRedBird`,
      worksTitle: `Blue Red Bird`,
      registrationDate: `2018-05-20`,
      expiryDate: `2021-10-23`,
    },
    {
      key: `flipFlop`,
      worksTitle: `Flip Flop`,
      registrationDate: `2018-05-20`,
      expiryDate: `2021-10-23`,
    },
    {
      key: `RockPaperScissors`,
      worksTitle: `Rock Paper Scissors`,
      registrationDate: `2018-05-20`,
      expiryDate: `2021-10-23`,
    },
  ];

  const [isSongsAvail] = useState(Array.isArray(data) && data.length);

  return (
    <div>
      {isSongsAvail ? (
        <SongTable columns={columns} data={data} />
      ) : (
        <p className="text-base">Sorry! No Song Available.</p>
      )}
    </div>
  );
}
