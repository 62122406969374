import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function GenreTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Controlled vocabulary and drop-down menu. In MetaMusic's model, it is possible to add subgenres for tracks, but not for products..."
    >
      <i className="far fa-info-circle" onClick={props.showGenreTooltip}></i>
      <Drawer
        title="Genre"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeGenreTooltip}
        visible={props.genreTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          Controlled vocabulary and drop-down menu. In MetaMusic's model, it is
          possible to add subgenres for tracks, but not for products.
        </p>
        <h1>Description</h1>
        <p>
          MetaMusic has ruled on an indicative list of music genres. This list
          is the same as the one used by RIDEAU, an arts presenters association.
          It also complies with the uses of most platforms.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
