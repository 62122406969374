import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../Assets/songwritersca.png";
import { Modal, Radio } from "antd";
import { useQuery } from "../../../../utils/use-query";
import EnumFlagFormComponent from "../../CommonComponents/EnumFlagFormField";
import { message } from "../../../../assets/info-modal-message";

export default function Signup(props) {
  const query = useQuery();

  const methods = useForm({
    defaultValues: {
      accountType: query.get("accountType")?.toLowerCase(),
      status: query.get("status")?.toLowerCase(),
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = methods;

  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setSubmitting(true);
    await axios.post("/register", data).finally(() => setSubmitting(false));
    history.push("/emailVerification");
  };

  const communications = [
    {
      title:
        "Subscribe to S.A.C. e-newsletter for advocacy alerts and program updates",
      description: "",
    },
    { title: "Subscribe to partner emails", description: "" },
  ];

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    Modal.info(message);
  }, []);

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-yellow-500 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
          >
            Sign up
          </h2>
          <div className="my-6">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                    First Name
                  </div>
                  <input
                    {...register("FirstName", {
                      required: "This field is mandatory",
                    })}
                    className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                    type="text"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="FirstName"
                    className=" text-red-500"
                  />
                </div>
                <div className="mt-6">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                      Last Name
                    </div>
                  </div>
                  <input
                    {...register("LastName", {
                      required: "This field is mandatory",
                    })}
                    className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                    type="text"
                    placeholder="Last Name"
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="LastName"
                    className=" text-red-500"
                  />
                </div>

                <div className="mt-6">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                      Email
                    </div>
                  </div>
                  <input
                    {...register("EmailAddress", {
                      required: "This field is mandatory",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Given email-id is not valid",
                      },
                    })}
                    className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                    type="text"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="EmailAddress"
                    className=" text-red-500"
                  />
                </div>

                <div className="mt-6">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                      Password
                    </div>
                  </div>
                  <input
                    {...register("password", {
                      required: "You must specify a password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/,
                        message:
                          "Please, include a mixture of both uppercase and lowercase letters, letters and numbers and at least one special character.",
                      },
                    })}
                    className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                    type="password"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="password"
                    className="text-red-500"
                  />

                  <p className="mt-2 text-sm text-gray-500">
                    At least 8 characters—the more characters, the better.
                    <br />
                    Include at least one uppercase letter. <br />
                    Include at least one number. <br />
                    Include at least one special character, e.g., ! @ # ? ]
                  </p>
                </div>

                <div className="mt-6">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                      Confirm Password
                    </div>
                  </div>
                  <input
                    className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                    type="password"
                    placeholder="Confirm Password"
                    {...register("confirmPassword", {
                      required: "This field is mandatory",
                      validate: (value) =>
                        value === password.current ||
                        "The passwords do not match",
                    })}
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="confirmPassword"
                    className="text-red-500"
                  />
                </div>

                <div className="mt-6">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                      Account Type
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name="accountType"
                    rules={{
                      required: "This field is mandatory",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Radio.Group
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                      >
                        <Radio value="member">Member</Radio>
                        <Radio value="associate">Associate</Radio>
                      </Radio.Group>
                    )}
                  />
                  <ErrorMessage
                    as="p"
                    errors={errors}
                    name="accountType"
                    className="text-red-500 m-0"
                  />
                </div>

                {watch("accountType") === "member" ? (
                  <div className="mt-6">
                    <div className="flex justify-between items-center">
                      <div className="text-sm font-bold text-gray-700 tracking-wide mb-1">
                        Status
                      </div>
                    </div>
                    <select
                      id="status"
                      {...register("status", {
                        required: "This field is mandatory",
                        shouldUnregister: true,
                      })}
                      defaultValue=""
                      className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500 focus:ring-yellow-400"
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="regular">Regular</option>
                      <option value="student">Student</option>
                      <option value="senior">Senior</option>
                    </select>
                    <ErrorMessage
                      as="p"
                      errors={errors}
                      name="status"
                      className="text-red-500 m-0"
                    />
                  </div>
                ) : null}

                <ul className="mt-6 divide-y divide-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Communications
                  </h3>

                  <EnumFlagFormComponent
                    name="communicationPreferences"
                    fields={communications}
                    renderCondition={(v) => true}
                  />
                </ul>

                <div className="mt-10">
                  <button
                    type="submit"
                    className="bg-yellow-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600
                                shadow-lg"
                    disabled={isSubmitting}
                  >
                    Sign up
                  </button>
                </div>
              </form>
            </FormProvider>
            <div className="mt-12 flex justify-center text-sm font-display font-semibold text-gray-700 text-center">
              Already have an account ?
              <Link to="/login">
                <p className="cursor-pointer ml-2 text-yellow-500 hover:text-yellow-600 ">
                  Sign in
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex sidebarBgImg items-center justify-center bg-yellow-100 flex-1 min-h-screen">
        <div className="max-w-xs opacity-90 transform duration-200 hover:scale-110 cursor-pointer">
          <img className="h-18 w-auto" src={Logo} alt="Workflow" />
        </div>
      </div>
    </div>
  );
}
