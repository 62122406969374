import { useState } from "react";
import { Select } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import RoleTooltip from "../Tooltip/RoleTooltip";

const roleChildren = [
  {
    label: "Lyricist(A)",
    value: "lyricist",
  },
  {
    label: "Composer(C)",
    value: "composer",
  },
  {
    label: "Songwriter(AC)",
    value: "songwriter",
  },
  {
    label: "Adapter(AD)",
    value: "adapter",
  },
  {
    label: "Arranger(AR)",
    value: "arranger",
  },
  {
    label: "Translator(TR)",
    value: "Translator",
  },
];

const Role = (props) => {
  const { control } = useFormContext();

  const [roleTooltip, setRoleTooltip] = useState(false);

  const handleRoleTooltip = () => {
    setRoleTooltip(!roleTooltip);
  };

  return (
    <>
      <div className="sm:col-span-3">
        <label
          htmlFor="biography"
          className="block text-sm font-medium leading-5 text-gray-700 mb-1"
        >
          Roles
          <RoleTooltip
            roleTooltip={roleTooltip}
            showRoleTooltip={handleRoleTooltip}
            closeRoleTooltip={handleRoleTooltip}
          />
        </label>

        <Controller
          control={control}
          name={props.fieldName}
          render={({ field: { onChange, value } }) => (
            <Select
              mode="tags"
              allowClear
              style={{ padding: "2px" }}
              bordered={false}
              placeholder="Please select"
              virtual={false}
              onChange={onChange}
              className="mt-1 w-full border border-gray-300 focus:outline-none focus:ring-yellow-400 focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md  "
              value={value}
            >
              {roleChildren.map((child) => (
                <Select.Option 
                value={child.value} key={child.value}>
                  {child.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </div>
    </>
  );
};

export default Role;
