import { useFormContext, Controller } from "react-hook-form";
import ArtisticPro from "./ArtisticPro";
import FormFieldArray from "../../../../../CommonArea/CommonComponents/FormFieldArray";
import { Input } from "../../../../../CommonArea/CommonComponents/FormComponents";
import { Select } from "antd";
import genreOptions from "../../../../../CommonArea/CommonComponents/CommonOptions/genreOptions";

export default function AdminArtisticInfo() {
  const { register, control } = useFormContext();

  const { Option } = Select;

  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Artistic Information
        </h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
          <label
            htmlFor="artisticInfo.name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Artistic Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("artisticInfo.name", { required: false })}
              id="artisticInfo.name"
              autoComplete="artisticInfo.name"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <FormFieldArray label="Bands" fieldName="artisticInfo.bands">
          <Input name="name" type="text" label="Name"></Input>
          <Input name="membersCount" type="number" label="MembersCount"></Input>
        </FormFieldArray>

        <FormFieldArray label="Awards" fieldName="artisticInfo.awards">
          <Input name="name" type="text" label="Award Name"></Input>
          <Input name="year" type="year" label="Award Year"></Input>
        </FormFieldArray>

        <FormFieldArray label="Albums" fieldName="artisticInfo.albums">
          <Input name="name" type="text" label="Name"></Input>
          <Input name="year" type="year" label="Year"></Input>
        </FormFieldArray>

        <FormFieldArray
          label="Other Credits"
          fieldName="artisticInfo.otherCredits"
        >
          <Input name="title" type="text" label="Title"></Input>
          <Input name="year" type="year" label="Year"></Input>
          <Input name="details" type="text" label="Details"></Input>
        </FormFieldArray>

        <FormFieldArray
          label="Song Placements"
          fieldName="artisticInfo.songPlacements"
        >
          <Input name="title" type="text" label="Title"></Input>
          <Input name="year" type="year" label="Year"></Input>
          <Input name="details" type="text" label="Details"></Input>
        </FormFieldArray>

        <FormFieldArray
          label="Songs Recorded"
          fieldName="artisticInfo.songsRecorded"
        >
          <Input name="title" type="text" label="Title"></Input>
          <Input name="year" type="year" label="Year"></Input>
          <Input name="details" type="text" label="Details"></Input>
        </FormFieldArray>

        <FormFieldArray label="Labels" fieldName="artisticInfo.labels">
          <Input name="name" type="text" label="Name"></Input>
          <Input name="year" type="year" label="Year"></Input>
        </FormFieldArray>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.yearsExperience"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Years of experience
          </label>

          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.yearsExperience"
              {...register("artisticInfo.yearsExperience", {
                required: false,
              })}
              autoComplete="artisticInfo.yearsExperience"
              defaultValue=""
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select
              </option>
              <option value="0-1">0-1</option>
              <option value="2-5">2-5</option>
              <option value="6-10">6-10</option>
              <option value="11-15">11-15</option>
              <option value="16-20">16-20</option>
              <option value="20+">20+</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.biography"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Biography
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="artisticInfo.biography"
              {...register("artisticInfo.biography", {
                required: false,
              })}
              rows="3"
              className="focus:ring-yellow-300 max-w-lg shadow-sm block w-full focus:outline-none p-2 focus:border-yellow-300 sm:text-sm border border-gray-300 rounded-md"
            />
            <p className="mt-2 text-sm text-gray-500">
              Write a few sentences about yourself.
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.genres"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Genre
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="artisticInfo.genres"
              render={({ field: { onChange, value } }) => (
                <Select
                  mode="tags"
                  allowClear
                  style={{ padding: "2px" }}
                  bordered={false}
                  placeholder="Please select"
                  onChange={onChange}
                  className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={value}
                >
                  {genreOptions.map((option, idx) => (
                    <Option key={idx} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.performingRightsOrganization"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Professional Rights Organization (PRO)
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.performingRightsOrganization"
              {...register("artisticInfo.performingRightsOrganization", {
                required: false,
              })}
              autoComplete="artisticInfo.performingRightsOrganization"
              defaultValue=""
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select PRO
              </option>
              <option value="socan">SOCAN</option>
              <option value="ascap">ASCAP</option>
              <option value="bmi">BMI</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.favoriteSingerWriter"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Favourite Singer / Songwriter
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("artisticInfo.favoriteSingerWriter", {
                required: false,
              })}
              id="artisticInfo.favoriteSingerWriter"
              autoComplete="artisticInfo.favoriteSingerWriter"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.iWrite"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            I Write
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.iWrite"
              {...register("artisticInfo.iWrite", {
                required: false,
              })}
              autoComplete="artisticInfo.iWrite"
              defaultValue=""
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select One
              </option>
              <option value="byMyself">By Myself</option>
              <option value="forMyself">For Myself</option>
              <option value="withOthers">With Others</option>
              <option value="forOthers">For Others</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.personalQuote"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Personal Quote
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("artisticInfo.personalQuote", { required: false })}
              id="artisticInfo.personalQuote"
              autoComplete="artisticInfo.personalQuote"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.influences"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Influences
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("artisticInfo.influences", { required: false })}
              id="artisticInfo.influences"
              autoComplete="artisticInfo.influences"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.soundsLike"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Sounds Like
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("artisticInfo.soundsLike", { required: false })}
              id="artisticInfo.soundsLike"
              autoComplete="artisticInfo.soundsLike"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.interestedIn"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Interested In
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interestedIn"
              {...register("artisticInfo.interestedIn", {
                required: false,
              })}
              autoComplete="artisticInfo.interestedIn"
              defaultValue=""
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select One
              </option>
              <option value="webCowriting">Web Cowriting</option>
              <option value="faceToFace">Face to Face</option>
              <option value="coWriting">Co Writing</option>
              <option value="networking">Networking</option>
              <option value="pitchingSongsToArtists">
                Pitching Songs to Artists
              </option>
              <option value="pitchingSongsToTv">
                Pitching Songs to Tv/Film/Etc.
              </option>
              <option value="signingWithLabel">Signing with a Label</option>
              <option value="gettingPublishingDeal">
                Getting a Publishing Deal
              </option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.prefersToWrite"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Prefer to Write
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              id="artisticInfo.prefersToWrite"
              {...register("artisticInfo.prefersToWrite", {
                required: false,
              })}
              autoComplete="artisticInfo.prefersToWrite"
              defaultValue=""
            >
              <option value="" disabled>
                Select One
              </option>
              <option value="lyrics">Lyrics</option>
              <option value="melody">Melody</option>
              <option value="chordPatterns">Chord Patterns</option>
              <option value="arrangements">Arrangements</option>
              <option value="beats">Beats</option>
              <option value="topline">Topline</option>
            </select>
          </div>
        </div>
        <ArtisticPro />
      </div>
    </div>
  );
}
