import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export default function ContactInfoOverlay(props) {
  const open = props.open;
  const setOpen = props.setOpen;

  const contactManagerInfo = props.contactInfo?.artisticInfo?.manager;
  const contactPublisherInfo = props.contactInfo?.artisticInfo?.publisher;
  const contactEmail = props.contactInfo?.emailAddress;

  const personal = {
    email: contactEmail,
  };

  const managerInfo = {
    firstName: contactManagerInfo?.firstName,
    lastName: contactManagerInfo?.lastName,
    company: contactManagerInfo?.companyName,
    email: contactManagerInfo?.email,
    phone: contactManagerInfo?.phone,
  };

  const publisherInfo = {
    firstName: contactPublisherInfo?.firstName,
    lastName: contactPublisherInfo?.lastName,
    company: contactPublisherInfo?.companyName,
    email: contactPublisherInfo?.email,
    phone: contactPublisherInfo?.phone,
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="z-50 fixed inset-0 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full bg-white p-8 overflow-y-auto">
                  <div className="pb-16 space-y-6">
                    <Dialog.Title className="text-lg font-medium text-gray-900">
                      Personal Information
                    </Dialog.Title>
                    {contactManagerInfo ||
                    contactPublisherInfo ||
                    contactEmail ? (
                      <>
                        {personal?.email && (
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Contact Information
                            </h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Email</dt>
                                <dd className="text-gray-900">
                                  {personal.email}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        )}
                        {managerInfo?.firstName && (
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Manager Information
                            </h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">First Name</dt>
                                <dd className="text-gray-900">
                                  {managerInfo?.firstName}
                                </dd>
                              </div>

                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Last Name</dt>
                                <dd className="text-gray-900">
                                  {managerInfo?.lastName}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Company</dt>
                                <dd className="text-gray-900">
                                  {managerInfo?.company}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Email</dt>
                                <dd className="text-gray-900">
                                  {managerInfo?.email}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Phone</dt>
                                <dd className="text-gray-900">
                                  {managerInfo?.phone}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        )}

                        {publisherInfo?.firstName && (
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Publisher Information
                            </h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">First Name</dt>
                                <dd className="text-gray-900">
                                  {publisherInfo?.firstName}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Last Name</dt>
                                <dd className="text-gray-900">
                                  {publisherInfo?.lastName}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Company</dt>
                                <dd className="text-gray-900">
                                  {publisherInfo?.company}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Email</dt>
                                <dd className="text-gray-900">
                                  {publisherInfo?.email}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Phone</dt>
                                <dd className="text-gray-900">
                                  {publisherInfo?.phone}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        )}
                      </>
                    ) : (
                      <p className="text-base text-black border-t pt-5">
                        No information available for this user.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
