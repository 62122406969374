import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, XIcon } from "@heroicons/react/outline";
import { MenuAlt2Icon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { authStore } from "../../../../store";
import { userStore } from "../../../../store/userStore";
import HeaderSvg from "./HeaderSvg";
import Sidebar from "./Sidebar";
import avatar from "../../Assets/avatar.png";
import { Button } from "antd";

export default function Header(props) {
  const [sidebarOpen, setSidebarOpen] = useState(props.sidebarOpen);

  const history = useHistory();

  const authStoreState = authStore((state) => state.authState);

  // workaround to fix image not re rednering when same value is passed, even when cache is disabled.
  // const profileImageKey = userStore(state => state.profileImageUrl + Date.now());

  const profileImageUrl = userStore(
    (state) => state.profileImageUrl ?? authStoreState.profileUrl
  );

  const logOut = () => {
    authStore
      .getState()
      .logout()
      .then(() => {
        history.push("/login");
      });
  };
  const handleLink = (link) => {
    history.push(link);
  };
  return (
    <Disclosure as="div" className="relative pb-32 overflow-hidden">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <Sidebar
                navigation={props.navigation}
                setSidebarOpen={setSidebarOpen}
              />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      <>
        <nav className=" bg-transparent relative z-10 lg:bg-transparent lg:border-none">
          <div className="px-6">
            <div className="relative h-16 flex items-center justify-between lg:border-b ">
              <div className="relative z-10 flex-shrink-0 flex h-16 items-center">
                <button
                  type="button"
                  className="px-4 shadow text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500 md:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div>{props?.handleGoBack}</div>
              </div>

              {authStoreState === "Authenticated" && (
                <div className="lg:block lg:ml-4">
                  <div className="flex items-center">
                    <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                      <div className="relative text-light-blue-100 focus-within:text-gray-400">
                        <Button
                          onClick={() => history.push("/users")}
                          shape="round"
                        >
                          Search Members
                        </Button>
                      </div>
                    </div>
                    <button className="flex-shrink-0 rounded-full p-1 text-blue-200  hover:text-white focus:outline-none  focus:ring-2 focus:ring-offset-2  focus:ring-white">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative flex-shrink-0 ml-4">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="rounded-full flex text-sm text-white focus:outline-none  focus:ring-2 focus:ring-offset-2  focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <img
                                className="rounded-full h-8 w-8 object-cover"
                                src={profileImageUrl ? profileImageUrl : avatar}
                                onError={(e) => {
                                  e.target.src = avatar;
                                }}
                                alt=""
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <ul>
                                <li className="block py-2 px-4 text-sm text-gray-700  hover:bg-gray-100">
                                  <button
                                    className="text-gray-700"
                                    onClick={() => handleLink("/")}
                                  >
                                    My Profile
                                  </button>
                                </li>
                                <li className="block py-2 px-4 text-sm text-gray-700  hover:bg-gray-100">
                                  <button
                                    className="text-gray-700"
                                    onClick={() =>
                                      handleLink("/editDetails/settings")
                                    }
                                  >
                                    Settings
                                  </button>
                                </li>
                                {/* <button */}

                                <li className=" py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                                  <button
                                    onClick={logOut}
                                    className="block w-full text-left"
                                  >
                                    Sign Out
                                  </button>
                                </li>
                              </ul>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
        <div
          className="bottom-0 inset-y-0 absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0"
          aria-hidden="true"
        >
          <div className="flex-grow bg-light-blue-900 bg-opacity-75" />
          <HeaderSvg />
          <div className="flex-grow bg-light-blue-800 bg-opacity-75" />
        </div>
        <header className="relative pt-5">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* <h1 className="text-3xl font-bold text-white">{props.heading}</h1> */}
          </div>
        </header>
      </>
    </Disclosure>
  );
}
