import { useFormContext } from "react-hook-form";

export default function ProfilePreferences() {
  const { register } = useFormContext();

  return (
    <>
      <div className="sm:border-t sm:border-gray-200 sm:pt-5 ">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Profile Preferences
        </h3>
        <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"></div>
        <div className="flex items-center mb-5">
          <input
            id="profilePreferences.displayArtisticName"
            {...register("profilePreferences.displayArtisticName", {
              required: false,
            })}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
          />
          <label
            htmlFor="profilePreferences.displayArtisticName"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Display Artistic Name
          </label>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="flex items-center mb-5">
          <input
            id="profilePreferences.displayProfile"
            {...register("profilePreferences.displayProfile", {
              required: false,
            })}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
          />

          <label
            htmlFor="profilePreferences.displayProfile"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Display Profile
          </label>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="flex items-center mb-5">
          <input
            id="profilePreferences.displaySongs"
            {...register("profilePreferences.displaySongs", {
              required: false,
            })}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
          />

          <label
            htmlFor="profilePreferences.displaySongs"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Display Songs
          </label>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="flex items-center mb-5">
          <input
            id="profilePreferences.displayContactEmail"
            {...register("profilePreferences.displayContactEmail", {
              required: false,
            })}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
          />

          <label
            htmlFor="profilePreferences.displayContactEmail"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Display Contact Email
          </label>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="flex items-center mb-5">
          <input
            id="profilePreferences.featuredProfile"
            {...register("profilePreferences.featuredProfile", {
              required: false,
            })}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
          />
          <label
            htmlFor="profilePreferences.featuredProfile"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Featured Profile
          </label>
        </div>
      </div>
    </>
  );
}
