import React from "react";
import { DatePicker } from "antd";
import { useFormContext, Controller } from "react-hook-form";

export default function Vendor() {
  const {
    register,

    control,
  } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">Vendor</h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="vendor.accountNumber"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Account Number
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("vendor.accountNumber", {
                required: false,
              })}
              id="vendor.accountNumber"
              autoComplete="vendor.accountNumber"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="vendor.subscriptionRenewalDate"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Subscription Renewal Date
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Controller
                control={control}
                name="vendor.subscriptionRenewalDate"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    style={{
                      borderRadius: "0.4rem",
                      cursor: "pointer",
                      fontSize: "17px",
                      padding: "0.4rem",
                    }}
                    className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onBlur={onBlur}
                    onChange={onChange}
                    selected={value}
                    inputRef={ref}
                    placeholder="Renewal Date"
                  />
                )}
              />
            </span>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="vendor.description"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="vendor.description"
              {...register("vendor.description", {
                required: false,
              })}
              rows="3"
              className="focus:ring-yellow-300 focus:border-yellow-300 max-w-lg focus:ring-0 shadow-sm block w-full focus:outline-none p-2 sm:text-sm border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
