import { ErrorMessage } from "@hookform/error-message";
import { Select, Popconfirm } from "antd";
import { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import genreOptions from "../../../CommonOptions/genreOptions";
import EnumFlagTagsFormField from "../../../EnumFlagTagsFormField";
import { Input } from "../../../FormComponents";
import FormFieldArray from "../../../FormFieldArray";
import ArtisticContactInfo from "./ArtisticContactInfo";

export default function ArtisticInfoForm() {
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    getValues
  } = useFormContext();

  const question1 = useRef({});
  question1.current = watch("artisticInfo.interviewQuestion1", "");

  const question2 = useRef({});
  question2.current = watch("artisticInfo.interviewQuestion2", "");

  const question3 = useRef({});
  question3.current = watch("artisticInfo.interviewQuestion3", "");

  const songworksCamp = useRef({});
  songworksCamp.current = watch("artisticInfo.proSongworks", "");

  const rwg = useRef({});
  rwg.current = watch("artisticInfo.regionalWritersGroup.accepted", "");

  const sbn = useRef({});
  sbn.current = watch("artisticInfo.songbirdNorth.accepted", "");

  const manager = useRef({});
  manager.current = watch("artisticInfo.manager.accepted", "");

  const publisher = useRef({});
  publisher.current = watch("artisticInfo.publisher.accepted", "");

  const { Option } = Select;

  const [isRwgSelected, setRwgValue] = useState(false);
  const [isSongbirdNorthSelected, setSongbirdNorthValue] = useState(false);
  const [isProSongWorksSelected, setProSongWorksValue] = useState(false);

  const [isManagerSelected, setManagerValue] = useState(getValues('artisticInfo.manager') !== undefined);
  const [isPublisherSelected, setPublisherValue] = useState(getValues('artisticInfo.publisher') !== undefined);

  const managerHandler = () => {
    setManagerValue(!isManagerSelected);
    if (!!isManagerSelected) {
      unregister("artisticInfo.manager");
    }
  };

  const publisherHandler = () => {
    setPublisherValue(!isPublisherSelected);
    if (!!isPublisherSelected) {
      unregister("artisticInfo.publisher");
    }
  };

  const interestedInOptions = [
    "Web Cowriting",
    "Face to Face",
    "Co Writing",
    "Networking",
    "Pitching Songs to Artists",
    "Pitching Songs to TV/Film/etc",
    "Singing with a Label",
    "Getting a Publishing Deal"
  ];

  const writeOptions = [
    "By Myself",
    "For Myself",
    "With Others",
    "For Others"
  ];

  const prefersToWriteOptiosn = [
    "Lyrics",
    "Melody",
    "Patterns",
    "Arrangements",
    "Beats",
    "Topline"
  ];

  return (
    <div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Artistic Information
        </h3>
        {/* <p className="mt-1 text-sm text-gray-500">
          Use a permanent address where you can receive mail.
        </p> */}
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="artisticInfo.name"
            className="block text-sm font-medium text-gray-700"
          >
            Artistic Name
          </label>
          <input
            type="text"
            {...register("artisticInfo.name", { required: false })}
            id="artisticInfo.name"
            autoComplete="artisticInfo.name"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="artisticInfo.yearsExperience"
            className="block text-sm font-medium text-gray-700"
          >
            Years of Experience
          </label>

          <select
            id="artisticInfo.yearsExperience"
            {...register("artisticInfo.yearsExperience", {
              required: false,
            })}
            autoComplete="artisticInfo.yearsExperience"
            defaultValue=""
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          >
            <option value="" disabled>
              Select Years of Experience
            </option>
            <option value="0-1">0-1</option>
            <option value="2-5">2-5</option>
            <option value="6-10">6-10</option>
            <option value="11-15">11-15</option>
            <option value="16-20">16-20</option>
            <option value="20+">20+</option>
          </select>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray label="Bands" fieldName="artisticInfo.bands">
            <Input name="name" type="text" label="Name"></Input>
            <Input
              name="membersCount"
              type="number"
              label="MembersCount"
            ></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.biography"
            className="block text-sm font-medium text-gray-700"
          >
            Biography
          </label>

          <textarea
            id="artisticInfo.biography"
            {...register("artisticInfo.biography", {
              required: false,
            })}
            rows="3"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
          <p className="mt-2 text-sm text-gray-500 mb-0">
            Write a few sentences about yourself.
          </p>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray label="Awards" fieldName="artisticInfo.awards">
            <Input name="name" type="text" label="Award Name"></Input>
            <Input name="year" type="year" label="Award Year"></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.genres"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Genres
          </label>
          <div className=" sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="artisticInfo.genres"
              render={({ field: { onChange, value } }) => (
                <Select
                  mode="tags"
                  allowClear
                  style={{ padding: "2px" }}
                  bordered={false}
                  placeholder="Please select"
                  onChange={onChange}
                  className="mt-1 w-full focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md border border-gray-300 block transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={value}
                >
                  {genreOptions.map((option, idx) => (
                    <Option key={idx} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray label="Labels" fieldName="artisticInfo.labels">
            <Input name="name" type="text" label="Name"></Input>
            <Input name="year" type="year" label="Year"></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray label="Albums" fieldName="artisticInfo.albums">
            <Input name="name" type="text" label="Name"></Input>
            <Input name="year" type="year" label="Year"></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray
            label="Song Placements"
            fieldName="artisticInfo.songPlacements"
          >
            <Input name="title" type="text" label="Title"></Input>
            <Input name="year" type="year" label="Year"></Input>
            <Input name="details" type="text" label="Details"></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray
            label="Songs Recorded"
            fieldName="artisticInfo.songsRecorded"
          >
            <Input name="title" type="text" label="Title"></Input>
            <Input name="year" type="year" label="Year"></Input>
            <Input name="details" type="text" label="Details"></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <FormFieldArray
            label="Other Credits"
            fieldName="artisticInfo.otherCredits"
          >
            <Input name="title" type="text" label="Title"></Input>
            <Input name="year" type="year" label="Year"></Input>
            <Input name="details" type="text" label="Details"></Input>
          </FormFieldArray>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.performingRightsOrganization"
            className="block text-sm font-medium text-gray-700"
          >
            Professional Rights Organization (PRO)
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.performingRightsOrganization"
              {...register("artisticInfo.performingRightsOrganization", {
                required: false,
              })}
              autoComplete="artisticInfo.performingRightsOrganization"
              defaultValue=""
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            >
              <option value="" disabled>
                Select PRO
              </option>
              <option value="SOCAN">SOCAN</option>
              <option value="ASCAP">ASCAP</option>
              <option value="BMI">BMI</option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="artisticInfo.performingRightsOrganization"
              className="mb-0 text-red-500"
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.proSongworks"
            className="block text-sm font-medium text-gray-700"
          >
            Have you participated in the Pro Songworks Camp before?
          </label>
          <div className="mt-1 sm:mt-2 sm:col-span-2">
            <input
              type="radio"
              className="form-radio text-yellow-400 focus:ring-yellow-400"
              checked={isProSongWorksSelected}
              onChange={() => setProSongWorksValue(true)}
            />
            <span className="ml-2">Yes</span>

            <input
              type="radio"
              className="form-radio text-yellow-400 ml-4 focus:ring-yellow-400"
              checked={!isProSongWorksSelected}
              onChange={() => setProSongWorksValue(false)}
            />
            <span className="ml-2">No</span>
          </div>

          {isProSongWorksSelected ? (
            <div className="sm:col-span-6">
              <FormFieldArray
                label="Pro SongWorks"
                fieldName="artisticInfo.proSongWorks"
                shouldUnregister="true"
              >
                <Input name="name" type="text" label="Name"></Input>
                <Input name="date" type="date" label="Start Date"></Input>
                <Input name="city" type="text" label="City"></Input>
              </FormFieldArray>
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.regionalWritersGroup.accepted"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Are you a participant of the Regional Writers Group (RWG)?
          </label>
          <div className="mt-1 mb-2 sm:mt-2 sm:col-span-2">
            <input
              type="radio"
              className="form-radio text-yellow-400 focus:ring-yellow-400"
              checked={isRwgSelected}
              onChange={() => setRwgValue(true)}
            />
            <span className="ml-2">Yes</span>

            <input
              type="radio"
              className="form-radio text-yellow-400 ml-4 focus:ring-yellow-400"
              checked={!isRwgSelected}
              onChange={() => setRwgValue(false)}
            />
            <span className="ml-2">No</span>
          </div>

          {isRwgSelected ? (
            <>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="artisticInfo.regionalWritersGroup"
                  {...register("artisticInfo.regionalWritersGroup", {
                    required: "This field is mandatory",
                  })}
                  autoComplete="artisticInfo.regionalWritersGroup"
                  defaultValue=""
                  // onChange={(e) => props.setAns1(e.target.value)}
                  className="text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                  <option value="" disabled>
                    Region
                  </option>
                  <option value="alberta">Alberta</option>
                  <option value="britishColumbia">British Columbia</option>
                  <option value="manitoba">Manitoba</option>
                  <option value="newBrunswick">New Brunswick</option>
                  <option value="newfoundland">Newfoundland & Labrador</option>
                  <option value="novaScotia">Nova Scotia</option>
                  <option value="northwestTerritories">
                    Northwest Territories
                  </option>
                  <option value="nunavut">Nunavut</option>
                  <option value="ontario">Ontario</option>
                  <option value="princeEdward">Prince Edward Island</option>
                  <option value="quebec">Quebec</option>
                  <option value="saskatchewan">Saskatchewan</option>
                  <option value="yukonTerritory">Yukon Territory</option>
                  <option value="outside">Outside Canada</option>
                </select>
              </div>
            </>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.songbirdNorth.accepted"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Have you participated in SongBird North (SBN) concert series before?
          </label>
          <div className="mt-1 sm:mt-2 sm:col-span-2">
            <input
              type="radio"
              className="form-radio text-yellow-400 focus:ring-yellow-400"
              checked={isSongbirdNorthSelected}
              onChange={() => setSongbirdNorthValue(true)}
            />
            <span className="ml-2">Yes</span>

            <input
              type="radio"
              className="form-radio text-yellow-400 ml-4 focus:ring-yellow-400"
              checked={!isSongbirdNorthSelected}
              onChange={() => setSongbirdNorthValue(false)}
            />
            <span className="ml-2">No</span>
          </div>

          {isSongbirdNorthSelected ? (
            <FormFieldArray
              label="Songbird North"
              fieldName="artisticInfo.songbirdNorth"
              shouldUnregister="true"
            >
              <Input name="name" type="text" label="Name"></Input>
              <Input name="date" type="date" label="Start Date"></Input>
              <Input name="city" type="text" label="City"></Input>
            </FormFieldArray>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.personalQuote"
            className="block text-sm font-medium text-gray-700"
          >
            Personal Quote
          </label>

          <input
            type="text"
            {...register("artisticInfo.personalQuote", {
              required: false,
            })}
            id="artisticInfo.personalQuote"
            autoComplete="artisticInfo.personalQuote"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="artisticInfo.influences"
            className="block text-sm font-medium text-gray-700"
          >
            Influences
          </label>

          <input
            type="text"
            {...register("artisticInfo.influences", {
              required: false,
            })}
            id="artisticInfo.influences"
            autoComplete="artisticInfo.influences"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm col-span-3">
          <EnumFlagTagsFormField name="artisticInfo.interestedIn" label="Interested In" placeholder="Please Select" options={interestedInOptions}/>
        </div>

        <div className="sm col-span-3">
          <EnumFlagTagsFormField name="artisticInfo.iWrite" label="I Write" placeholder="Please Select" options={writeOptions}/>
        </div>

        <div className="sm col-span-3">
          <EnumFlagTagsFormField name="artisticInfo.prefersToWrite" label="Prefers to Write" placeholder="Please Select" options={prefersToWriteOptiosn}/>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="artisticInfo.soundsLike"
            className="block text-sm font-medium text-gray-700"
          >
            Sounds Like
          </label>

          <input
            type="text"
            {...register("artisticInfo.soundsLike", {
              required: false,
            })}
            id="artisticInfo.soundsLike"
            autoComplete="artisticInfo.soundsLike"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="artisticInfo.favoriteSingerWriter"
            className="block text-sm font-medium text-gray-700"
          >
            Favourite Singer / Songwriter
          </label>

          <input
            type="text"
            {...register("artisticInfo.favoriteSingerWriter", {
              required: false,
            })}
            id="artisticInfo.favoriteSingerWriter"
            autoComplete="artisticInfo.favoriteSingerWriter"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.interviewQuestion1"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Interview Question 1
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interviewQuestion1"
              {...register("artisticInfo.interviewQuestion1", {
                required: false,
              })}
              autoComplete="artisticInfo.interviewQuestion1"
              defaultValue=""
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            >
              <option value="" disabled>
                Select Interview Question
              </option>
              <option value="qstn1">What inspires you to create music?</option>
              <option value="qstn2">
                Do you have a process to your songwriting or when creating
                music?
              </option>
              <option value="qstn3">
                How did you get your start as a creator in the industry?
              </option>
              <option value="qstn4">
                How has your music evolved since you first became a
                recording/performing artist?
              </option>
              <option value="qstn5">
                Do you write for other recording/performing artists?
              </option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="artisticInfo.interviewQuestion1"
              className="mb-0 text-red-500"
            />
          </div>
        </div>
        {question1.current ? (
          <div className="sm:col-span-6">
            <label
              htmlFor="artisticInfo.interviewAnswer1"
              className="block text-sm font-medium text-gray-700 sm:mt-px"
            >
              Answer
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("artisticInfo.interviewAnswer1", {
                  required: false,
                })}
                id="artisticInfo.interviewAnswer1"
                autoComplete="artisticInfo.interviewAnswer1"
                className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="artisticInfo.interviewAnswer1"
                className="mb-0 text-red-500"
              />
            </div>
          </div>
        ) : null}

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.interviewQuestion2"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Interview Question 2
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interviewQuestion2"
              {...register("artisticInfo.interviewQuestion2", {
                required: false,
              })}
              autoComplete="artisticInfo.interviewQuestion2"
              defaultValue=""
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            >
              <option value="" disabled>
                Select Interview Question
              </option>
              <option value="qstn6">
                Do you tend to write for one genre, or do you find your music
                crosses genre lines?
              </option>
              <option value="qstn7">
                Have you faced any major economic, social or political hurdles
                as a music creator?
              </option>
              <option value="qstn8">
                Do you have any musical influences who have influenced your
                style, or who you give a "nod" to whenever possible?
              </option>
              <option value="qstn9">
                If you could collaborate with any other music creator, who would
                that be?
              </option>
              <option value="qstn10">
                How did you learn your craft – was it a “formal” or “informal”
                music education?
              </option>
              <option value="qstn11">
                Do you have any advice for upcoming songwriters and creators who
                are looking to break further into the creative scene?
              </option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="artisticInfo.interviewQuestion2"
              className="mb-0 text-red-500"
            />
          </div>
        </div>
        {question2.current ? (
          <div className="sm:col-span-6">
            <label
              htmlFor="artisticInfo.interviewQuestion2"
              className="block text-sm font-medium text-gray-700 sm:mt-px"
            >
              Answer
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("artisticInfo.interviewAnswer2", {
                  required: false,
                })}
                id="artisticInfo.interviewAnswer2"
                autoComplete="artisticInfo.interviewAnswer2"
                className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="artisticInfo.interviewAnswer2"
                className="mb-0 text-red-500"
              />
            </div>
          </div>
        ) : null}

        <div className="sm:col-span-6">
          <label
            htmlFor="artisticInfo.interviewQuestion3"
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            Interview Question 3
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interviewQuestion3"
              {...register("artisticInfo.interviewQuestion3", {
                required: false,
              })}
              autoComplete="artisticInfo.interviewQuestion3"
              defaultValue=""
              // onChange={(e) => props.setAns3(e.target.value)}
              className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            >
              <option value="" disabled>
                Select Interview Question
              </option>
              <option value="qstn12">
                What is your fondest musical memory or favourite piece of music
                you’ve written?
              </option>
              <option value="qstn13">
                What is the most important “tool” you need when creating, eg.
                GarageBand, google docs, your cell phone, Pro Tools, or a pad of
                paper?
              </option>
              <option value="qstn14">
                Do you ever compose for film/tv/video games? What's that like?
              </option>
              <option value="qstn15">How can S.A.C. help you?</option>
              <option value="qstn16">
                If the music community could do one thing better what would it
                be?
              </option>
              <option value="qstn17">
                What do you see in the future for songwriting and music creators
                like yourself?
              </option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="artisticInfo.interviewQuestion3"
              className="mb-0 text-red-500"
            />
          </div>
        </div>
        {question3.current ? (
          <div className="sm:col-span-6">
            <label
              htmlFor="artisticInfo.interviewAnswer3"
              className="block text-sm font-medium text-gray-700 sm:mt-px"
            >
              Answer
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("artisticInfo.interviewAnswer3", {
                  required: false,
                })}
                id="artisticInfo.interviewAnswer3"
                autoComplete="artisticInfo.interviewAnswer3"
                className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="artisticInfo.interviewAnswer3"
                className="mb-0 text-red-500"
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="mt-5">
        <div className="flex items-center">
          {isManagerSelected ? (
            <Popconfirm
              title="Are you sure you want to remove manager info?"
              okText="Yes"
              cancelText="No"
              onConfirm={managerHandler}
            >
              <input
                id="artisticInfo.manager.accepted"
                type="checkbox"
                readOnly
                checked={isManagerSelected}
                className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
              />
            </Popconfirm>
          ) : (
            <input
              id="artisticInfo.manager.accepted"
              type="checkbox"
              checked={isManagerSelected}
              onChange={managerHandler}
              className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
            />
          )}
          <label
            htmlFor="artisticInfo.manager.accepted"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Manager / Booking Contact
          </label>
        </div>
      </div>

      {isManagerSelected ? (
        <ArtisticContactInfo
          heading="Manager / Booking Contact"
          firstName="artisticInfo.manager.firstName"
          lastName="artisticInfo.manager.lastName"
          email="artisticInfo.manager.email"
          phone="artisticInfo.manager.phone"
          companyName="artisticInfo.manager.companyName"
        />
      ) : null}

      <div className="mt-5">
        <div className="flex items-center">
          {isPublisherSelected ? (
            <Popconfirm
              title="Are you sure you want to remove manager info?"
              okText="Yes"
              cancelText="No"
              onConfirm={publisherHandler}
            >
              <input
                id="artisticInfo.publisher.accepted"
                type="checkbox"
                readOnly
                checked={isPublisherSelected}
                className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
              />
            </Popconfirm>
          ) : (
            <input
              id="artisticInfo.publisher.accepted"
              type="checkbox"
              checked={isPublisherSelected}
              onChange={publisherHandler}
              className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
            />
          )}
          <label
            htmlFor="artisticInfo.publisher.accepted"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Publisher Contact
          </label>
        </div>
      </div>

      {isPublisherSelected ? (
        <ArtisticContactInfo
          heading="Publisher Contact"
          firstName="artisticInfo.publisher.firstName"
          lastName="artisticInfo.publisher.lastName"
          email="artisticInfo.publisher.email"
          phone="artisticInfo.publisher.phone"
          companyName="artisticInfo.publisher.companyName"
        />
      ) : null}
    </div>
  );
}
