// import BillingAddress from "../../../CommonArea/CommonComponents/UserProfileComponents/BillingAddress";
// import UserProfileSavedCards from "../../../CommonArea/CommonComponents/UserProfileComponents/UserProfileSavedCards";

export default function BillingDetails() {
  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Billing Information
        </h3>
      </div>
      {/* <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-6 mt-3">
        <div className="grid grid-cols-1 gap-4 lg:col-span-9">
          <UserProfileSavedCards />
        </div>
        <div className="grid grid-cols-1 gap-4 lg:col-span-9">
          <BillingAddress />
        </div>
      </div> */}
    </>
  );
}
