import React from "react";
import { Select } from "antd";
import { useFormContext, Controller } from "react-hook-form";
import AvatarUpload from "../../../../CommonArea/CommonComponents/AvatarUpload";

const pronouns = [
  {
    label: "He / Him",
    value: "He / Him",
  },
  {
    label: "She / Her",
    value: "She / Her",
  },
  {
    label: "They / Them",
    value: "They / Them",
  },
  {
    label: "Ze / Zir",
    value: "Ze / Zir",
  },
  {
    label: "Non-Binary",
    value: "Non-Binary",
  },
  {
    label: "Agender",
    value: "Agender",
  },
  {
    label: "Gender Fluid",
    value: "Gender Fluid",
  },
  {
    label: "Gender Nonconfirming",
    value: "Gender Nonconfirming",
  },
  {
    label: "Two Spirit",
    value: "Two Spirit",
  },
  {
    label: "Prefer Not to Say",
    value: "Prefer Not to Say",
  },
];

export default function AdminRegistrationContact() {
  const {
    register,

    control,
  } = useFormContext();

  const { Option } = Select;

  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className=" space-y-6 pt-5 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900 ">
          Contact Information
        </h3>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5">
        <label
          htmlFor="profilePictureUrl"
          className="block text-sm font-medium text-gray-700"
        >
          Profile Picture
        </label>
        <AvatarUpload placeholderText="Upload Image" title="Upload image" />
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="salutation"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Salutation
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="salutation"
            {...register("salutation", {
              required: false,
            })}
            autoComplete="salutation"
            defaultValue=""
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Salutation
            </option>
            <option value="mr">Mr.</option>
            <option value="ms">Ms.</option>
            <option value="mrs">Mrs.</option>
            <option value="miss">Miss</option>
            <option value="mz">Mz.</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="firstName"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          First Name
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("firstName", {
              required: false,
            })}
            autoComplete="firstName"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="lastName"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Last Name
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("lastName", {
              required: false,
            })}
            id="lastName"
            autoComplete="lastName"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="pronouns"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Pronouns
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Controller
            control={control}
            name="pronouns"
            render={({ field: { onChange, value } }) => (
              <Select
                mode="tags"
                tagRender={(props) => <div>{props.label}</div>}
                style={{ padding: "2px" }}
                bordered={false}
                onInputKeyDown={(v) => onChange("")}
                onSelect={(v) => {
                  if (v.length) {
                    onChange(v);
                  }
                }}
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                value={value}
              >
                {pronouns.map((pronoun, i) => (
                  <Option key={i} value={pronoun.value}>
                    {pronoun.label}
                  </Option>
                ))}
              </Select>
            )}
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="dateOfBirth"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Date of Birth
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <input
              type="text"
              {...register("dateOfBirth", {
                required: false,
              })}
              id="dob"
              readOnly
              autoComplete="dob"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </span>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="emailAddress"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Email Address
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("emailAddress", {
              required: false,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "The email is incorrect",
              },
            })}
            id="emailAddress"
            autoComplete="emailAddress"
            readOnly
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="mobile"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Mobile
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="number"
            id="mobile"
            {...register("contactDetail.mobile", {
              required: false,
              minLength: {
                value: 10,
                message: "Mobile number should be atleast 10 digits",
              },
              maxLength: {
                value: 35,
                message: "Mobile number cannot exceed 35 digits",
              },
            })}
            autoComplete="mobile"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="businessPhone"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Business Phone
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="number"
            {...register("contactDetail.businessPhone", {
              required: false,
              minLength: 10,
              maxLength: 35,
            })}
            id="businessPhone"
            autoComplete="businessPhone"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="homePhone"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Home Phone
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="number"
            {...register("contactDetail.Phone", {
              required: false,
              minLength: 10,
              maxLength: 35,
            })}
            id="homePhone"
            autoComplete="homePhone"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="addressDetail.line1"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Street Address
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("addressDetail.line1", {
              required: false,
            })}
            id="addressDetail.line1"
            autoComplete="addressDetail.line1"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="addressDetail.line2"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Address Line 2
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("addressDetail.line2", { required: false })}
            id="addressDetail.line2"
            autoComplete="addressDetail.line2"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="city"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          City
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("addressDetail.city", {
              required: false,
            })}
            id="city"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="province"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Province / State
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("addressDetail.province", {
              required: false,
            })}
            id="province"
            className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="country"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Country
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="country"
            {...register("addressDetail.country", {
              required: false,
            })}
            autoComplete="country"
            defaultValue=""
            // onChange={(e) => props.setCountry(e.target.value)}
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Country
            </option>
            <option value="canada">Canada</option>
            <option value="usa">United States</option>
            <option value="mexico">Mexico</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="postalCode"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Postal Code
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("addressDetail.postalCode", {
              required: false,
            })}
            id="postalCode"
            autoComplete="postalCode"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="website"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Contact Website
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <input
              type="text"
              {...register("contactDetail.website", { required: false })}
              id="website"
              autoComplete="website"
              className="focus:ring-yellow-300 flex-1 block w-full focus:ring-0 focus:outline-none focus:border-yellow-400 min-w-0  rounded-md sm:text-sm border-gray-300"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
