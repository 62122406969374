import React, { useState } from "react";
import { Select } from "antd";
import SubgenreTooltip from "./common/Tooltip/SubgenreTooltip";
import { Controller, useFormContext } from "react-hook-form";

const Subgenre = () => {
  const { control } = useFormContext();

  const [subgenreTooltip, setSubgenreTooltip] = useState(false);
  const showSubgenreTooltip = () => {
    setSubgenreTooltip(true);
  };
  const closeSubgenreTooltip = () => {
    setSubgenreTooltip(false);
  };

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="biography"
        className="block text-sm font-medium leading-5 text-gray-700 mb-1"
      >
        Subgenre
        <SubgenreTooltip
          subgenreTooltip={subgenreTooltip}
          showSubgenreTooltip={showSubgenreTooltip}
          closeSubgenreTooltip={closeSubgenreTooltip}
        />
      </label>

      <Controller
        control={control}
        name="subgenre"
        render={({ field: { onChange, value } }) => (
          <Select
            mode="tags"
            allowClear
            style={{ padding: "2px" }}
            bordered={false}
            placeholder="Please select"
            onChange={onChange}
            className="mt-1 w-full focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md border border-gray-300 block transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            value={value}
          ></Select>
        )}
      />
      {/* <Select
        size="large"
        mode="tags"
        onChange={handleChange}
        style={{ width: "100%" }}
      ></Select> */}
    </div>
  );
};

export default Subgenre;
