export default function ArtisticQuickLinkInfo(props) {
  const artisticInfo = props.artisticInfo;

  const data = {
    fields: {
      "Artistic Name": artisticInfo?.name,
      "Years Of Experience": artisticInfo?.yearsExperience,

      Genre: artisticInfo?.genres?.map((genre, i) => <p key={i}>{genre}</p>),
      Label: artisticInfo?.label,

      "Professional Rights Organization (PRO)":
        artisticInfo?.performingRightsOrganization,

      "Regional Writers Group Participant (RWG) Region":
        artisticInfo?.regionalWritersGroup,

      "Personal Quote": artisticInfo?.personalQuote,
      Influences: artisticInfo?.influences,
      "Interested In": artisticInfo?.interestedIn,
      "I Write": artisticInfo?.iWrite,
      "Prefer To Write": artisticInfo?.prefersToWrite,
      "Sounds Like": artisticInfo?.soundsLike,
      "Favourite Singer": artisticInfo?.favoriteSingerWriter,
    },

    bandInfo: {
      "Band Name": artisticInfo?.bands?.map((band, i) => (
        <p key={i}>{band.name}</p>
      )),
      // "Band Name": ["The Tragically Hip", "lorem"],
      "Band Members": artisticInfo?.bands?.map((band, i) => (
        <p key={i}>{band.membersCount}</p>
      )),
    },
    awardInfo: {
      "Award Name": artisticInfo?.awards?.map((award, i) => (
        <p key={i}>{award.name}</p>
      )),
      "Award Year": artisticInfo?.awards?.map((award, i) => (
        <p key={i}>{award.year}</p>
      )),
    },

    albumInfo: {
      "Album Name": artisticInfo?.albums?.map((album, i) => (
        <p key={i}>{album.name}</p>
      )),
      "Album Year": artisticInfo?.albums?.map((album, i) => (
        <p key={i}>{album.year}</p>
      )),
    },
    songPlacementInfo: {
      "Song Placement Title": artisticInfo?.songPlacements?.map(
        (songPlacement, i) => <p key={i}>{songPlacement.title}</p>
      ),
      "Song Placement Year": artisticInfo?.songPlacements?.map(
        (songPlacement, i) => <p key={i}>{songPlacement.year}</p>
      ),
      "Song Placement Details": artisticInfo?.songPlacements?.map(
        (songPlacement, i) => <p key={i}>{songPlacement.details}</p>
      ),
    },

    songRecordedInfo: {
      "Song Recorded Title": artisticInfo?.songsRecorded?.map(
        (songRecorded, i) => <p key={i}>{songRecorded.title}</p>
      ),
      "Song Recorded Year": artisticInfo?.songsRecorded?.map(
        (songRecorded, i) => <p key={i}>{songRecorded.year}</p>
      ),
      "Song Recorded Details": artisticInfo?.songsRecorded?.map(
        (songRecorded, i) => <p key={i}>{songRecorded.details}</p>
      ),
    },

    otherCreditsInfo: {
      "Other Credit Title": artisticInfo?.otherCredits?.map(
        (otherCredit, i) => <p key={i}>{otherCredit.title}</p>
      ),

      "Other Credit Year": artisticInfo?.otherCredits?.map((otherCredit, i) => (
        <p key={i}>{otherCredit.year}</p>
      )),
      "Other Credit Details": artisticInfo?.otherCredits?.map(
        (otherCredit, i) => <p key={i}>{otherCredit.details}</p>
      ),
    },

    songBirdNorthInfo: {
      "SongBird North Participant (SBN) Name": artisticInfo?.songbirdNorth?.map(
        (songbirdNorth, i) => <p key={i}>{songbirdNorth.name}</p>
      ),
      "SongBird North Participant (SBN) Start Date":
        artisticInfo?.songbirdNorth?.map((songbirdNorth, i) => (
          <p key={i}>{songbirdNorth.date}</p>
        )),
      "SongBird North Participant (SBN) City": artisticInfo?.songbirdNorth?.map(
        (songbirdNorth, i) => <p key={i}>{songbirdNorth.city}</p>
      ),
    },

    proSongworksCampInfo: {
      "Pro Song Works Camp Name": artisticInfo?.proSongWorks?.map(
        (proSongWork, i) => <p key={i}>{proSongWork.name}</p>
      ),
      "Pro Song Works Camp Start Date": artisticInfo?.proSongWorks?.map(
        (proSongWork, i) => <p key={i}>{proSongWork.date}</p>
      ),
      "Pro Song Works Camp Start City": artisticInfo?.proSongWorks?.map(
        (proSongWork, i) => <p key={i}>{proSongWork.city}</p>
      ),
    },
  };

  return (
    <div className="flex overflow-hidden bg-white">
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            <div className="bg-white overflow-hidden sm:rounded-lg overflow-y-scroll">
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    {Object.keys(data?.fields)?.map((field) => (
                      <span key={field}>
                        {data?.fields[field] && (
                          <div key={field} className="sm:col-span-1">
                            <>
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.fields[field]}
                              </dd>
                            </>
                          </div>
                        )}
                      </span>
                    ))}
                  </dl>

                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 ">
                    {Object.keys(data?.bandInfo)?.map((field) => (
                      <span key={field}>
                        {data?.bandInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.bandInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>

                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 ">
                    {Object.keys(data?.awardInfo)?.map((field) => (
                      <span key={field}>
                        {data?.awardInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.awardInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>

                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 ">
                    {Object.keys(data?.albumInfo)?.map((field) => (
                      <span key={field}>
                        {data?.albumInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.albumInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 ">
                    {Object.keys(data?.songPlacementInfo)?.map((field) => (
                      <span key={field}>
                        {data?.songPlacementInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.songPlacementInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 ">
                    {Object.keys(data?.songRecordedInfo)?.map((field) => (
                      <span key={field}>
                        {data?.songRecordedInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.songRecordedInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 ">
                    {Object.keys(data?.otherCreditsInfo)?.map((field) => (
                      <span key={field}>
                        {data?.otherCreditsInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.otherCreditsInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 ">
                    {Object.keys(data?.songBirdNorthInfo)?.map((field) => (
                      <span key={field}>
                        {data?.songBirdNorthInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.songBirdNorthInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 ">
                    {Object.keys(data?.proSongworksCampInfo)?.map((field) => (
                      <span key={field}>
                        {data?.proSongworksCampInfo[field] && (
                          <>
                            <div
                              key={field}
                              className="sm:col-span-1 pt-3 border-t"
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                {field}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data?.proSongworksCampInfo[field]}
                              </dd>
                            </div>
                          </>
                        )}
                      </span>
                    ))}
                  </dl>
                  {/* </dl> */}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
