import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Upload, UploadProps } from "antd";
import { UploadFileStatus } from "antd/lib/upload/interface";
import { useState } from "react";
import toast from "react-hot-toast";
import { userStore } from "../../../../store/userStore";
import avatar from "../../Assets/avatar.png";

interface ComponentProps {
  placeholderText: string;
}

type HandleChange = UploadProps["onChange"];
type BeforeUpload = UploadProps["beforeUpload"];

function AvatarUpload(props: ComponentProps): JSX.Element {
  const [status, setStatus] = useState<UploadFileStatus>();
  const imageUrl = userStore((state) => state.profileImageUrl);

  const beforeUpload: BeforeUpload = (file: File) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("Image must be smaller than 5MB!");
    }
    return isLt5M;
  };

  const handleChange: HandleChange = (info) => {
    const updatedStatus = info.file.status;
    const response = info.file.response;

    setStatus(updatedStatus);
    if (updatedStatus === "error") {
      toast.error(response?.responseStatus?.message);
    } else if (updatedStatus === "done") {
      toast.success("Uploaded successfully");
      userStore.setState({ profileImageUrl: response?.["profileImageUrl"] });
    }
  };

  const uploadButton = (
    <div>
      {status === "uploading" && <LoadingOutlined />}
      {status === "error" && <ExclamationCircleOutlined />}
      <div style={{ marginTop: 8 }}>
        {status !== "uploading" ? props.placeholderText : ""}
      </div>
    </div>
  );

  const [imgUrl, setImgUrl] = useState(imageUrl);
  const [errored, setErrored] = useState(false);

  const onError = () => {
    if (!errored) {
      setImgUrl(avatar);
      setErrored(true);
    }
  };

  return (
    <Upload
      accept="image/*"
      action={`${process.env.REACT_APP_API_URL}/users/me/profileImage`}
      beforeUpload={beforeUpload}
      disabled={status === "uploading"}
      listType="picture-card"
      maxCount={1}
      withCredentials={true}
      onChange={handleChange}
      showUploadList={false}
    >
      {imgUrl && status !== "uploading" ? (
        <img
          src={imgUrl}
          onError={onError}
          alt="avatar"
          style={{ width: "100%", height: "100%" }}
          className="object-cover"
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
}

export default AvatarUpload;
