import { Route, Switch } from "react-router-dom";
import SongDetail from "../../../../CommonArea/CommonComponents/RegisterNewSong/SongDetail";
import SongVaultTables from "./SongVaultTables";

export default function AdminSongVault() {
  return (
    <Switch>
      <Route path="/admin/services/songVault">
        <SongVaultTables />
      </Route>
      <Route path="/admin/services/songVault/registerSong">
        <SongDetail />
      </Route>
    </Switch>
  );
}
