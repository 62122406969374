import React from "react";
import MemberID from "./MemberProfile/MemberID";
import "../../../../../App.css";
import MessageBroadcastDrawer from "./MessageBroadcastDrawer";

export default function SlideOvers(props) {
  return (
    <div>
      <MessageBroadcastDrawer
        isMessageBroadcastOpen={props.isMessageBroadcastOpen}
        closeMessageBroadcastOverlay={props.closeMessageBroadcastOverlay}
      />

      <MemberID
        isUserProfileOpen={props.isUserProfileOpen}
        closeOverlay={props.closeOverlay}
        isMessageBoxOpen={props.isMessageBoxOpen}
      />
    </div>
  );
}
