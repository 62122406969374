import { Switch, Route } from "react-router";
import AdminSongLibrary from "./AdminSongLibrary";
import AdminSongVault from "./AdminSongVault";

import ServicesHome from "./ServicesHome";

export default function Services() {
  return (
    <div>
      <Switch>
        <Route exact path="/admin/services">
          <ServicesHome />
        </Route>
        <Route path="/admin/services/songLibrary">
          <AdminSongLibrary />
        </Route>
        <Route path="/admin/services/songVault">
          <AdminSongVault />
        </Route>
      </Switch>
    </div>
  );
}
