import { Select } from "antd";
import { DateTime } from "luxon";
import { Controller, useFormContext } from "react-hook-form";
import { primaryCountries, secondaryCountries } from "../../../../../store/countries";
import AvatarUpload from "../../AvatarUpload";

export default function PersonalInfoForm() {
  const { register, getValues, control } = useFormContext();

  const dateOfBirth = new DateTime.fromFormat(
    getValues("dateOfBirth"),
    "yyyy-MM-dd"
  ).toLocaleString();

  const { Option } = Select;

  const pronouns = [
    {
      label: "He / Him",
      value: "He / Him",
    },
    {
      label: "She / Her",
      value: "She / Her",
    },
    {
      label: "They / Them",
      value: "They / Them",
    },
    {
      label: "Ze / Zir",
      value: "Ze / Zir",
    },
    {
      label: "Non-Binary",
      value: "Non-Binary",
    },
    {
      label: "Agender",
      value: "Agender",
    },
    {
      label: "Gender Fluid",
      value: "Gender Fluid",
    },
    {
      label: "Gender Nonconfirming",
      value: "Gender Nonconfirming",
    },
    {
      label: "Two Spirit",
      value: "Two Spirit",
    },
    {
      label: "Prefer Not to Say",
      value: "Prefer Not to Say",
    },
  ];

  return (
    <div className="">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Personal Information
        </h3>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label
            htmlFor="profilePhoto"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Profile Photo
          </label>
          <AvatarUpload placeholderText="Upload Image" title="Upload image" />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="salutation"
            className="block text-sm font-medium text-gray-700"
          >
            Salutation
          </label>

          <select
            id="salutation"
            {...register("salutation", {
              required: false,
            })}
            defaultValue=""
            className=" mt-1 focus:outline-none focus:border focus:border-yellow-300 focus:ring-yellow-400 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Salutation
            </option>
            <option value="mr">Mr.</option>
            <option value="ms">Ms.</option>
            <option value="miss">Miss</option>
            <option value="mrs">Mrs.</option>
            <option value="mz">Mz.</option>
          </select>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="pronouns"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Pronouns
          </label>

          <div className=" sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="pronouns"
              render={({ field: { onChange, value } }) => (
                <Select
                  mode="tags"
                  tagRender={(props) => <div>{props.label}</div>}
                  style={{ padding: "2px" }}
                  bordered={false}
                  placeholder="Please select"
                  onInputKeyDown={(v) => onChange("")}
                  onSelect={(v) => {
                    if (v.length) {
                      onChange(v);
                    }
                  }}
                  className="mt-1 w-full focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md border border-gray-300 block transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={value}
                >
                  {pronouns.map((pronoun, i) => (
                    <Option key={i} value={pronoun.value}>
                      {pronoun.label}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            {...register("firstName", {
              required: false,
            })}
            id="firstName"
            autoComplete="firstName"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            {...register("lastName", {
              required: false,
            })}
            id="lastName"
            autoComplete="lastName"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="dob"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <input
            type="text"
            {...register("dateOfBirth", {
              required: true,
            })}
            id="dob"
            readOnly
            value={dateOfBirth}
            autoComplete="dob"
            className="shadow-sm focus:ring-yellow-400 bg-gray-50 focus:border-yellow-400  block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="text"
            {...register("emailAddress", {
              required: true,
            })}
            id="email"
            readOnly
            autoComplete="email"
            className="shadow-sm focus:ring-yellow-400 bg-gray-50 focus:border-yellow-400  block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="addressDetail.line1"
            className="block text-sm font-medium text-gray-700"
          >
            Street address
          </label>
          <input
            type="text"
            {...register("addressDetail.line1", {
              required: false,
            })}
            id="addressDetail.line1"
            autoComplete="addressDetail.line1"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="addressDetail.line2"
            className="block text-sm font-medium text-gray-700"
          >
            Address Line 2
          </label>
          <input
            type="text"
            {...register("addressDetail.line2", {
              required: false,
            })}
            id="addressDetail.line2"
            autoComplete="street-address"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="addressDetail.city"
            className="block text-sm font-medium text-gray-700"
          >
            City
          </label>
          <input
            type="text"
            {...register("addressDetail.city", {
              required: false,
            })}
            id="addressDetail.city"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="province"
            className="block text-sm font-medium text-gray-700"
          >
            Province
          </label>

          <select
            id="province"
            {...register("province")}
            defaultValue=""
            // onChange={(e) => props.setAns1(e.target.value)}
            className=" mt-1 focus:outline-none focus:border focus:border-yellow-300 focus:ring-yellow-400 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Province
            </option>
            {/* <option value="any">Any...</option> */}
            <option value="alberta">Alberta</option>
            <option value="britishColumbia">British Columbia</option>
            <option value="manitoba">Manitoba</option>
            <option value="newBrunswick">New Brunswick</option>
            <option value="newfoundland">Newfoundland & Labrador</option>
            <option value="northwestTerritories">Northwest Territories</option>
            <option value="novaScotia">Nova Scotia</option>
            <option value="nunavut">Nunavut</option>
            <option value="ontario">Ontario</option>
            <option value="princeEdward">Prince Edward Island</option>
            <option value="quebec">Quebec</option>
            <option value="saskatchewan">Saskatchewan</option>
            <option value="yukonTerritory">Yukon</option>
            <option value="outside">Outside Canada</option>
          </select>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="addressDetail.country"
            className="block text-sm font-medium text-gray-700"
          >
            Country
          </label>
          <select
            id="addressDetail.country"
            {...register("addressDetail.country", {
              required: false,
            })}
            autoComplete="addressDetail.country"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          >
            <optgroup>
              {primaryCountries.map(v => (<option value={v}>{v}</option>))}
            </optgroup>
            <optgroup>
              {secondaryCountries.map(v => (<option value={v}>{v}</option>))}
            </optgroup>
          </select>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="addressDetail.postalCode"
            className="block text-sm font-medium text-gray-700"
          >
            Postal Code
          </label>
          <input
            type="text"
            {...register("addressDetail.postalCode", {
              required: false,
            })}
            id="addressDetail.postalCode"
            autoComplete="addressDetail.postalCode"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="contactDetail.mobile"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile
          </label>
          <input
            type="text"
            {...register("contactDetail.mobile", {
              required: false,
              minLength: 10,
              maxLength: 35,
            })}
            id="contactDetail.mobile"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="contactDetail.businessPhone"
            className="block text-sm font-medium text-gray-700"
          >
            Business Phone
          </label>
          <input
            type="text"
            {...register("contactDetail.businessPhone", {
              required: false,
              minLength: 10,
              maxLength: 35,
            })}
            id="contactDetail.businessPhone"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="contactDetail.phone"
            className="block text-sm font-medium text-gray-700"
          >
            Home Phone
          </label>
          <input
            type="text"
            {...register("contactDetail.phone", {
              required: false,
              minLength: 10,
              maxLength: 35,
            })}
            id="contactDetail.phone"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="contactDetail.website"
            className="block text-sm font-medium text-gray-700"
          >
            Contact Website
          </label>

          <input
            type="text"
            {...register("contactDetail.website", { required: false })}
            id="contactDetail.website"
            className="shadow-sm focus:ring-yellow-400 focus:border-yellow-400 hover:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
          />
        </div>
      </div>
    </div>
  );
}
