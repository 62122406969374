import { Disclosure } from "@headlessui/react";
import { Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import { userStore } from "../../../../store/userStore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar(props) {
  const navigation = props.navigation;

  return (
    <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-black overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <img
          className="h-8 w-auto"
          src={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/wp-content/uploads/2020/07/songwritersca_logotypegold.png`}
          alt="Songwriters.ca"
        />
      </div>
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 space-y-1 bg-black" aria-label="Sidebar">

          {/*
          *********************
          *  Filtered disabled menu options temporarily to have them not show up
          *********************
          */}
          
          {navigation.filter(item => item.state !== 'Unavailable').filter(item => item.accountType === userStore().user.accountType).map((item) =>
            item.state !== 'Disabled' ? (
              !item.children ? (
                <div key={item.name}>
                  <NavLink
                    exact
                    to={item.pathname}
                    className={classNames(
                      item.current
                        ? "bg-gray-50 text-white hover:text-white "
                        : "bg-transparent text-white hover:text-white hover:bg-yellow-300 hover:opacity-90",
                      "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
                    )}
                    activeClassName="bg-yellow-400 text-white hover:bg-opacity-100"
                    activeStyle={{ color: "white" }}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-white"
                          : "text-white group-hover:text-white ",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                </div>
              ) : (
                <Disclosure as="div" key={item.name} className="space-y-1">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-white"
                            : "bg-transparent text-white hover:bg-yellow-400 hover:text-white",
                          "group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md focus:outline-none"
                        )}
                      >
                        <item.icon
                          className="text-white group-hover:text-white mr-3 h-6 w-6"
                          aria-hidden="true"
                        />
                        {item.name}
                        <svg
                          className={classNames(
                            open ? "text-white rotate-90" : "text-white",
                            "ml-auto h-5 w-5 transform group-hover:text-white transition-colors ease-in-out duration-150"
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children.map((subItem) => (
                          <NavLink
                            key={subItem.name}
                            activeClassName="bg-yellow-400 text-white hover:bg-opacity-100"
                            activeStyle={{ color: "white" }}
                            to={subItem.pathname}
                            className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-white rounded-md hover:text-white hover:bg-yellow-400"
                          >
                            {subItem.name}
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )
            ) : (
              <Tooltip key={item.name} title="Coming Soon" placement="rightBottom">
                <NavLink
                  onClick={(e) => e.preventDefault()}
                  to={item.pathname}
                  className={classNames(
                    item.current
                      ? "bg-gray-50 text-white hover:text-white "
                      : "bg-transparent  hover:text-white cursor-default",
                    "group w-full flex items-center pl-2 py-2 text-gray-400 text-sm font-medium rounded-md cursor-not-allowed"
                  )}
                  activeClassName="bg-yellow-400 text-white hover:bg-opacity-100"
                  activeStyle={{ color: "white" }}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-white"
                        : "text-white group-hover:text-white ",
                      "mr-3 h-6 w-6 text-gray-400"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              </Tooltip>
            )
          )}
        </nav>
      </div>
    </div>
  );
}
