import React from "react";
import { Switch } from "react-router-dom";
import Membership from "./Membership";
import Operations from "./Operations";
import Subscribers from "./Subscribers";
import { NavLink, Route } from "react-router-dom";
import "../../../../App.css";

export default function BillingPayments() {
  return (
    <div>
      <div className="flex-shrink-0 flex items-center ">
        <nav className="hidden sm:flex items-center text-sm leading-5 font-medium">
          <div className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
            Home
          </div>
          <svg
            className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <div className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
            Billing & Payments
          </div>
        </nav>
      </div>
      <div className="flex-shrink-0 flex py-1 items-center text-3xl">
        Billing & Payments
      </div>
      <div className=" inline-block ">
        <div className="hidden sm:block mt-1">
          <nav className="flex">
            <NavLink
              exact
              to="/admin/billing"
              activeClassName="bg-yellow-400 hover:bg-opacity-100"
              activeStyle={{ color: "white" }}
              className="bg-gray-200 mx-3 text-gray-600 shadow font-medium hover:bg-yellow-400 hover:bg-opacity-50 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Membership Packages
            </NavLink>

            <NavLink
              to="/admin/billing/credits"
              activeClassName="bg-yellow-400 hover:bg-opacity-100"
              activeStyle={{ color: "white" }}
              className="bg-gray-200 text-gray-600 shadow font-medium hover:bg-yellow-400 hover:bg-opacity-50 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Credits & Refunds
            </NavLink>

            <NavLink
              to="/admin/billing/invoices"
              activeClassName="bg-yellow-400 hover:bg-opacity-100"
              activeStyle={{ color: "white" }}
              className="bg-gray-200 mx-3 text-gray-600 shadow font-medium hover:bg-yellow-400 hover:bg-opacity-50 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Invoices
            </NavLink>

            <NavLink
              to="/admin/billing/payments"
              activeClassName="bg-yellow-400 hover:bg-opacity-100"
              activeStyle={{ color: "white" }}
              className="bg-gray-200  text-gray-600 shadow font-medium hover:bg-yellow-400 hover:bg-opacity-50 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Payments
            </NavLink>

            <NavLink
              to="/admin/billing/subscriptions"
              activeClassName="bg-yellow-400 hover:bg-opacity-100"
              activeStyle={{ color: "white" }}
              className=" bg-gray-200 text-gray-600 mx-3 shadow font-medium hover:bg-yellow-400 hover:bg-opacity-50 w-400 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Subscriptions
            </NavLink>
            <NavLink
              to="/admin/billing/operations"
              activeClassName="bg-yellow-400 hover:bg-opacity-100"
              activeStyle={{ color: "white" }}
              className="bg-gray-200 text-gray-600 shadow font-medium hover:bg-yellow-400 hover:bg-opacity-50 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Operations
            </NavLink>
          </nav>
        </div>
      </div>

      <div className="py-4">
        <Switch>
          <Route exact path="/admin/billing" component={Membership} />
          <Route path="/admin/billing/subscriptions" component={Subscribers} />
          <Route path="/admin/billing/operations" component={Operations} />
        </Switch>
      </div>

      <div className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
        <span className="bg-gray-200 text-gray-600 shadow cursor-pointer font-medium hover:bg-yellow-400 inline-flex items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
          Create Membership Package
        </span>
      </div>
    </div>
  );
}
