import React from "react";

export default function Subscribers() {
  return (
    <div>
      <table className="min-w-full divide-y divide-gray-200 table-auto border-2 border-gray-200">
        <thead>
          <tr>
            <th className=" px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider">
              Subscription Title
            </th>
            <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider">
              Price
            </th>
            <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider">
              Currency
            </th>
            <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-xs leading-4 text-gray-500 uppercase tracking-wider">
              Unsubscribe
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              SongVault
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              $20
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              CDN
            </td>
            <td className="px-6 border-l-2 border-t-2 border-gray-200 text-center py-4 whitespace-no-wrap font-bold text-sm leading-5 text-red-500">
              X
            </td>
          </tr>
          <tr className="bg-white">
            <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              Member Content
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              $0
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              CDN
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 font-bold text-red-500">
              X
            </td>
          </tr>
          <tr className="bg-white">
            <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              SongPitch
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              $15
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              CDN
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 font-bold text-red-500">
              X
            </td>
          </tr>
          <tr className="bg-white">
            <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              Service G
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              $15
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
              CDN
            </td>
            <td className="px-6 border-l-2 border-gray-200 text-center py-4 whitespace-no-wrap text-sm leading-5 font-bold text-red-500">
              X
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
