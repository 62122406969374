import { useFormContext } from "react-hook-form";

export default function CommonContactInfo(props) {
  const { register } = useFormContext();

  const data = {
    heading: props.heading,
    firstName: props.firstName,
    lastName: props.lastName,
    companyName: props.companyName,
    email: props.email,
    phone: props.phone,
  };
  return (
    <div>
      <div className="pt-5 space-y-6 sm:pt-10 sm:space-y-5 border-t border-gray-200">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          {props.heading}
        </h3>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
          <label
            htmlFor={data.firstName}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            First Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register(`${data.firstName}`, {
                required: false,
                shouldUnregister: true,
              })}
              id={data.firstName}
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
          <label
            htmlFor={data.lastName}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Last Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register(`${data.lastName}`, {
                required: false,
                shouldUnregister: true,
              })}
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
          <label
            htmlFor={data.companyName}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Company Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register(`${data.companyName}`, {
                required: false,
                shouldUnregister: true,
              })}
              id={data.companyName}
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
          <label
            htmlFor={data.email}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Email
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register(`${data.email}`, {
                required: false,
                shouldUnregister: true,
              })}
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
          <label
            htmlFor={data.phone}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Phone
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="tel"
              {...register(`${data.phone}`, {
                required: false,
                shouldUnregister: true,

                maxLength: {
                  value: 12,
                  message: "Enter a correct phone number",
                },
                minLength: {
                  value: 6,
                  message: "Enter a correct phone number",
                },
              })}
              id={data.phone}
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
