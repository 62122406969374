import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
// import moment from "moment";
// import { DatePicker } from "antd";

const CreateMembercardDetails = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // function disabledDate(current) {
  //   return current && current < moment().endOf("day");
  // }

  // const [data, setData] = useState({
  //   createMember: {
  //     cardInfo: {
  //       cardCvc: "",
  //       cardExpiry: "",
  //       // cardName: "",
  //       cardNumber: "",
  //       focus: "",
  //     },
  //   },
  // });

  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardFocus, setCardFocus] = useState();

  // const handleInputChange = (e) => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //   });
  //   console.log(data);
  // };

  // const handleInputFocus = (e) => {
  //   setData({
  //     ...data,
  //     focus: e.target.name,
  //   });
  // };

  return (
    <div className="mt-5">
      <fieldset className="sm:flex items-center justify-between">
        <legend className="block text-sm font-medium text-gray-700">
          Card Details
        </legend>

        <div className="rounded-md -mt-16 -space-y-px">
          <div>
            <label htmlFor="createMember.cardInfo.cardName" className="sr-only">
              Name on Card
            </label>
            <input
              type="text"
              {...register("createMember.cardInfo.cardName", {
                required: "This field is mandatory",
                shouldUnregister: true,
              })}
              id="createMember.cardInfo.cardName"
              className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Name on Card"
              onChange={(e) => setCardName(e.target.value)}
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="createMember.cardInfo.cardName"
              className="mb-0 text-red-500"
            />
          </div>

          <div>
            <label
              htmlFor="createMember.cardInfo.cardNumber"
              className="sr-only"
            >
              Card number
            </label>
            <input
              type="number"
              // name="number"
              {...register("createMember.cardInfo.cardNumber", {
                required: "This field is mandatory",
                shouldUnregister: true,
                pattern: {
                  value:
                    /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/,
                  message: "Enter a valid card number",
                },
              })}
              id="createMember.cardInfo.cardNumber"
              className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none  bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Card number"
              onChange={(e) => setCardNumber(e.target.value)}
              // onFocus={(e) => setCardNumber(e.target.value)}
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="createMember.cardInfo.cardNumber"
              className="mb-0 text-red-500"
            />
          </div>

          <div className="flex -space-x-px">
            <div className="w-1/2 flex-1 min-w-0">
              <label
                htmlFor="createMember.cardInfo.cardExpiry"
                className="sr-only"
              >
                Expiration date
              </label>
              {/* <Controller
                control={control}
                name="createMember.cardInfo.cardExpiry"
                rules={{
                  required: {
                    value: true,
                    message: "This field is mandatory",
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    style={{
                      borderBottomLeftRadius: "0.4rem",
                      cursor: "pointer",
                      padding: "0.4rem",
                    }}
                    className="w-full"
                    onBlur={onBlur}
                    onChange={onChange}
                    checked={value}
                    inputRef={ref}
                    picker="month"
                    placeholder="Expiry Date"
                    disabledDate={disabledDate}
                  />
                )}
              /> */}

              <input
                type="text"
                maxLength="4"
                placeholder="MMYY"
                {...register("createMember.cardInfo.cardExpiry", {
                  required: "This field is mandatory",
                  shouldUnregister: true,
                })}
                className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                onChange={(e) => setCardExpiry(e.target.value)}
              />

              <ErrorMessage
                as="p"
                errors={errors}
                name="createMember.cardInfo.cardExpiry"
                className="mb-0 text-red-500"
              />
            </div>
            <div className="flex-1 min-w-0">
              <label
                htmlFor="createMember.cardInfo.cardCvc"
                className="sr-only"
              >
                CVC
              </label>
              <input
                type="text"
                name="cvc"
                maxLength="3"
                {...register("createMember.cardInfo.cardCvc", {
                  required: "This field is mandatory",
                  shouldUnregister: true,
                })}
                id="createMember.cardInfo.cardCvc"
                className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="CVC"
                onChange={(e) => setCardCvc(e.target.value)}
                onFocus={(e) => setCardFocus(e.target.value)}
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="createMember.cardInfo.cardCvc"
                className="mb-0 text-red-500"
              />
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-0">
          <Cards
            cvc={cardCvc}
            expiry={cardExpiry}
            focused={cardFocus}
            name={cardName}
            number={cardNumber}
          />
        </div>
      </fieldset>
      <fieldset className="-mt-8 flex">
        <legend className="block text-sm font-medium text-gray-700">
          Billing address
        </legend>
        <div className="mt-1 rounded-md shadow-sm -space-y-px">
          <div>
            <label
              htmlFor="createMember.billingAddress.addressLine1"
              className="sr-only"
            >
              Address Line 1
            </label>
            <input
              type="text"
              {...register("createMember.billingAddress.addressLine1", {
                required: "This field is mandatory",
                shouldUnregister: true,
              })}
              id="createMember.billingAddress.addressLine1"
              className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Address (Line 1)"
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="createMember.billingAddress.addressLine1"
              className="mb-0 text-red-500"
            />
          </div>

          <div>
            <label
              htmlFor="createMember.billingAddress.addressLine2"
              className="sr-only"
            >
              Address Line 2
            </label>
            <input
              type="text"
              {...register("createMember.billingAddress.addressLine2", {
                required: false,
              })}
              id="createMember.billingAddress.addressLine2"
              className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Address (Line 2)"
            />
          </div>

          <div className="flex -space-x-px">
            <div className="w-1/2 flex-1 min-w-0">
              <label
                htmlFor="createMember.billingAddress.city"
                className="sr-only"
              >
                City
              </label>
              <input
                type="text"
                {...register("createMember.billingAddress.city", {
                  required: "This field is mandatory",
                  shouldUnregister: true,
                })}
                id="createMember.billingAddress.city"
                className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="City"
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="createMember.billingAddress.city"
                className="mb-0 text-red-500"
              />
            </div>

            <div className="flex-1 min-w-0">
              <label
                htmlFor="createMember.billingAddress.province"
                className="sr-only"
              >
                Province
              </label>
              <input
                type="text"
                {...register("createMember.billingAddress.province", {
                  required: "This field is mandatory",
                  shouldUnregister: true,
                })}
                id="createMember.billingAddress.province"
                className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="Province"
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="createMember.billingAddress.province"
                className="mb-0 text-red-500"
              />
            </div>
          </div>

          <div className="flex -space-x-px">
            <div className="w-1/2 flex-1 min-w-0">
              <label
                htmlFor="createMember.billingAddress.country"
                className="sr-only"
              >
                Country
              </label>
              <select
                id="createMember.billingAddress.country"
                {...register("createMember.billingAddress.country", {
                  required: "This field is mandatory",
                  shouldUnregister: true,
                })}
                defaultValue=""
                className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              >
                <option value="" disabled>
                  Country
                </option>
                <option value="canada">Canada</option>
                <option value="usa">USA</option>
              </select>
              <ErrorMessage
                as="p"
                errors={errors}
                name="createMember.billingAddress.country"
                className="mb-0 text-red-500"
              />
            </div>
            <div>
              <label
                htmlFor="createMember.billingAddress.postalCode"
                className="sr-only"
              >
                Postal code
              </label>
              <input
                type="text"
                {...register("createMember.billingAddress.postalCode", {
                  required: "This field is mandatory",
                  shouldUnregister: true,
                })}
                id="createMember.billingAddress.postalCode"
                className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="Postal code"
              />
              <ErrorMessage
                as="p"
                errors={errors}
                name="createMember.billingAddress.postalCode"
                className="mb-0 text-red-500"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="createMember.billingAddress.phone"
              className="sr-only"
            >
              Phone
            </label>
            <input
              type="number"
              {...register("createMember.billingAddress.phone", {
                required: "This field is mandatory",
                shouldUnregister: true,
                pattern: {
                  value: /^[0-9]/,
                  message: "Mobile number pattern is not correct",
                },
                minLength: {
                  value: 6,
                  message: "Mobile number should be more than 6 digits",
                },
                maxLength: {
                  value: 10,
                  message: "Mobile number cannot exceed 10 digits",
                },
              })}
              id="createMember.billingAddress.phone"
              className="focus:ring-yellow-500 bg-white focus:border-yellow-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Phone Number"
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="createMember.billingAddress.phone"
              className="mb-0 text-red-500"
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default CreateMembercardDetails;
