import { Radio } from "antd";
import { Controller, useFormContext } from "react-hook-form";

export default function CoAuthorNotice(props) {
  const { control } = useFormContext();

  return (
    <div className="sm:col-span-4">
      <label
        htmlFor="publisherResidency"
        className="block text-sm font-medium text-gray-700"
      >
        Would you like to email a copy of the registration to the co-author?
      </label>
      <div className="mt-2">
        <Controller
          control={control}
          name={props.fieldName}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Radio.Group
              onBlur={onBlur}
              onChange={onChange}
              selected={value}
              inputRef={ref}
              style={{ width: "100%", fontSize: "14px" }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}> No</Radio>
            </Radio.Group>
          )}
        />
      </div>
    </div>
  );
}
