import { Link } from "react-router-dom";

export default function Announcements(props) {
  const announcements = props.announcements;
  return (
    <section>
      {/* <i className="far fa-info-circle"></i> */}
      <div className="rounded-lg bg-white overflow-hidden shadow ">
        <div className="p-6">
          <h2
            className="text-base font-medium text-gray-900"
            id="announcements-title"
          >
            Announcements
          </h2>
          <div className="flow-root mt-5 ">
            <ul className="-my-5 divide-y divide-gray-200 ">
              {announcements.map((announcement) => (
                <li key={announcement.id} className="py-5 ">
                  <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                    <h3 className="text-sm font-semibold text-gray-800">
                      <Link
                        to={announcement.pathname}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className=" hover:text-gray-800 focus:outline-none text-gray-800"
                      >
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        {announcement.title}
                      </Link>
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                      {announcement.preview}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {Array.isArray(announcements) && announcements.length ? (
            <div className="mt-6">
              <Link
                to="/member"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="w-full  hover:text-gray-800 flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white"
              >
                View all
              </Link>
            </div>
          ) : (
            <p>No announcements yet.</p>
          )}
        </div>
      </div>
    </section>
  );
}
