import React, { useState } from "react";
import { Radio } from "antd";
import { Controller, useFormContext } from "react-hook-form";

import PublisherResidencyTooltip from "../Tooltip/PublisherResidencyTooltip";

export default function PublishserResidency(props) {
  const { control } = useFormContext();

  const [isniTooltip, setIsniTooltip] = useState(false);
  const showIsniTooltip = () => {
    setIsniTooltip(true);
  };
  const closeIsniTooltip = () => {
    setIsniTooltip(false);
  };
  return (
    <div className="sm:col-span-3 ">
      <label
        htmlFor="isQuebec"
        className="block text-sm font-medium text-gray-700"
      >
        Is the Publisher based in Quebec?
        <PublisherResidencyTooltip
          isniTooltip={isniTooltip}
          showIsniTooltip={showIsniTooltip}
          closeIsniTooltip={closeIsniTooltip}
        />
      </label>
      <div className="mt-2">
        <Controller
          control={control}
          name={props.fieldName}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Radio.Group
              onBlur={onBlur}
              onChange={onChange}
              selected={value}
              inputRef={ref}
              style={{ width: "100%", fontSize: "14px" }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}> No</Radio>
            </Radio.Group>
          )}
        />
      </div>
    </div>
  );
}
