import React from "react";
import { Drawer } from "antd";
export default function ExplicitContentTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="The Parental Advisory (in French: Avertissement parental) is written above Explicit Content (formerly Explicit Lyrics). This advisory was created in the United States in 1985 by the Recording Industry Association of America following lobbying by the Parents Music Resource Center. (source Wikipedia)..."
    >
      <i className="far fa-info-circle" onClick={props.showExplicitTooltip}></i>
      <Drawer
        title="Explicit"
        width={520}
        placement="right"
        closable={false}
        onClose={props.showExplicitTooltip}
        visible={props.explicitTooltip}
      >
        <h1>Description</h1>
        <p>
          The Parental Advisory (in French: Avertissement parental) is written
          above Explicit Content (formerly Explicit Lyrics). This advisory was
          created in the United States in 1985 by the Recording Industry
          Association of America following lobbying by the Parents Music
          Resource Center. (source: Wikipedia)
        </p>

        <h1>Use</h1>
        <p>
          This is a mandatory field. However, this is subjective information
          that is often used for marketing purposes, in particular to mark the
          radical nature of a project. There is no formal legal obligation
          relating to this mention and many independent record labels choose to
          ignore it. It is, however, required by certain platforms or
          aggregators.
        </p>
      </Drawer>
      {/* <ReactTooltip
      type="light"
      effect="solid"
      className="w-80 border-2 border-gray-300 ml-5"
    /> */}
    </span>
  );
}
