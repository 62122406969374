import { useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Radio, Popconfirm } from "antd";
import CommonContactInfo from "./AdminCommonFields/CommonContactInfo";
import FormFieldArray from "../../../../../CommonArea/CommonComponents/FormFieldArray";
import { Input } from "../../../../../CommonArea/CommonComponents/FormComponents";

export default function ArtisticPro() {
  const { register, watch, control, unregister } = useFormContext();

  const manager = useRef({});
  manager.current = watch("artisticInfo.manager.accepted", "");

  const publisher = useRef({});
  publisher.current = watch("artisticInfo.publisher.accepted", "");

  const question1 = useRef({});
  question1.current = watch("artisticInfo.interview.question1", "");

  const question2 = useRef({});
  question2.current = watch("artisticInfo.interview.question2", "");

  const question3 = useRef({});
  question3.current = watch("artisticInfo.interview.question3", "");

  const songworksCamp = useRef({});
  songworksCamp.current = watch("artisticInfo.isProSongworks", "");

  const rwg = useRef({});
  rwg.current = watch("artisticInfo.isRegionalWritersGroup", "");

  const sbn = useRef({});
  sbn.current = watch("artisticInfo.isSongbirdNorth", "");

  const [isManagerSelected, setManagerValue] = useState(true);
  const [isPublisherSelected, setPublisherValue] = useState(true);

  const managerHandler = () => {
    setManagerValue(!isManagerSelected);
    if (!!isManagerSelected) {
      unregister("artisticInfo.manager");
    }
  };

  const publisherHandler = () => {
    setPublisherValue(!isPublisherSelected);
    if (!!isPublisherSelected) {
      unregister("artisticInfo.publisher");
    }
  };

  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.isProSongworks"
            className="block text-sm font-medium text-gray-700"
          >
            Pro Songworks Camp
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="artisticInfo.isProSongworks"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Radio.Group
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                >
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              )}
            />
          </div>
        </div>

        {songworksCamp.current === "yes" ? (
          <FormFieldArray
            label="Pro SongWorks"
            fieldName="artisticInfo.proSongWorks"
            shouldUnregister="true"
          >
            <Input name="name" type="text" label="Name"></Input>
            <Input name="date" type="date" label="Start Date"></Input>
            <Input name="city" type="text" label="City"></Input>
          </FormFieldArray>
        ) : null}

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.isRegionalWritersGroup"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Regional Writers Group (RWG)
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="artisticInfo.isRegionalWritersGroup"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Radio.Group
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                >
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              )}
            />
          </div>
        </div>

        {rwg.current === "yes" ? (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
            <label
              htmlFor="artisticInfo.regionalWritersGroup"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            ></label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <select
                id="artisticInfo.regionalWritersGroup"
                {...register("artisticInfo.regionalWritersGroup", {
                  required: false,
                })}
                autoComplete="artisticInfo.regionalWritersGroup"
                defaultValue=""
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              >
                <option value="" disabled>
                  Select Regional Writers Group
                </option>
                <option value="canada">Canada</option>
                <option value="usa">U.S.A</option>
                <option value="england">England</option>
              </select>
            </div>
          </div>
        ) : null}

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.isSongbirdNorth"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Songbird North (SBN)/Celebration Series
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="artisticInfo.isSongbirdNorth"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Radio.Group
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                >
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              )}
            />
          </div>
        </div>

        {sbn.current === "yes" ? (
          <FormFieldArray
            label="Songbird North"
            fieldName="artisticInfo.songbirdNorth"
            shouldUnregister="true"
          >
            <Input name="name" type="text" label="Name"></Input>
            <Input name="date" type="date" label="Start Date"></Input>
            <Input name="city" type="text" label="City"></Input>
          </FormFieldArray>
        ) : null}

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.interview.question1"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Interview Question 1
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interview.question1"
              {...register("artisticInfo.interview.question1", {
                required: false,
              })}
              autoComplete="artisticInfo.interview.question1"
              defaultValue=""
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select Interview Question
              </option>
              <option value="qstn1">What inspires you to create music?</option>
              <option value="qstn2">
                Do you have a process to your songwriting or when creating
                music?
              </option>
              <option value="qstn3">
                How did you get your start as a creator in the industry?
              </option>
              <option value="qstn4">
                How has your music evolved since you first became a
                recording/performing artist?
              </option>
              <option value="qstn5">
                Do you write for other recording/performing artists?
              </option>
            </select>
          </div>
        </div>
        {question1.current ? (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label
              htmlFor="artisticInfo.interview.answer1"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Answer
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("artisticInfo.interview.answer1", {
                  required: false,
                })}
                id="artisticInfo.interview.answer1"
                autoComplete="artisticInfo.interview.answer1"
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        ) : null}

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.interview.question2"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Interview Question 2
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interview.question2"
              {...register("artisticInfo.interview.question2", {
                required: false,
              })}
              autoComplete="artisticInfo.interview.question2"
              defaultValue=""
              // onChange={(e) => props.setAns2(e.target.value)}
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select Interview Question
              </option>
              <option value="qstn6">
                Do you tend to write for one genre, or do you find your music
                crosses genre lines?
              </option>
              <option value="qstn7">
                Have you faced any major economic, social or political hurdles
                as a music creator?
              </option>
              <option value="qstn8">
                Do you have any musical influences who have influenced your
                style, or who you give a "nod" to whenever possible?
              </option>
              <option value="qstn9">
                If you could collaborate with any other music creator, who would
                that be?
              </option>
              <option value="qstn10">
                How did you learn your craft – was it a “formal” or “informal”
                music education?
              </option>
              <option value="qstn11">
                Do you have any advice for upcoming songwriters and creators who
                are looking to break further into the creative scene?
              </option>
            </select>
          </div>
        </div>
        {question2.current ? (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label
              htmlFor="artisticInfo.interview.question2"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Answer
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("artisticInfo.interview.answer2", {
                  required: false,
                })}
                id="artisticInfo.interview.answer2"
                autoComplete="artisticInfo.interview.answer2"
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        ) : null}

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.interview.question3"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Interview Question 3
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="artisticInfo.interview.question3"
              {...register("artisticInfo.interview.question3", {
                required: false,
              })}
              autoComplete="artisticInfo.interview.question3"
              defaultValue=""
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select Interview Question
              </option>
              <option value="qstn12">
                What is your fondest musical memory or favourite piece of music
                you’ve written?
              </option>
              <option value="qstn13">
                What is the most important “tool” you need when creating, eg.
                GarageBand, google docs, your cell phone, Pro Tools, or a pad of
                paper?
              </option>
              <option value="qstn14">
                Do you ever compose for film/tv/video games? What's that like?
              </option>
              <option value="qstn15">How can S.A.C. help you?</option>
              <option value="qstn16">
                If the music community could do one thing better what would it
                be?
              </option>
              <option value="qstn17">
                What do you see in the future for songwriting and music creators
                like yourself?
              </option>
            </select>
          </div>
        </div>
        {question3.current ? (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label
              htmlFor="artisticInfo.interview.answer3"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Answer
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("artisticInfo.interview.answer3", {
                  required: false,
                })}
                id="artisticInfo.interview.answer3"
                autoComplete="artisticInfo.interview.answer3"
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        ) : null}

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.manager"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Manager/Booking Contact
          </label>

          <div className="flex sm:mt-0 mt-5">
            {isManagerSelected ? (
              <Popconfirm
                title="Are you sure you want to remove manager info?"
                okText="Yes"
                cancelText="No"
                onConfirm={managerHandler}
              >
                <input
                  id="artisticInfo.manager"
                  {...register("artisticInfo.manager.accepted", {
                    required: false,
                  })}
                  checked={isManagerSelected}
                  readOnly
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
                />
              </Popconfirm>
            ) : (
              <input
                id="artisticInfo.manager.accepted"
                {...register("artisticInfo.manager.accepted", {
                  required: false,
                })}
                checked={isManagerSelected}
                onChange={managerHandler}
                type="checkbox"
                className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
              />
            )}
            <label
              htmlFor="artisticInfo.manager.accepted"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              Add Manager/Booking Contact
            </label>
          </div>
        </div>

        {isManagerSelected ? (
          <CommonContactInfo
            heading="Manager / Booking Contact"
            firstName="artisticInfo.manager.firstName"
            lastName="artisticInfo.manager.lastName"
            email="artisticInfo.manager.email"
            phone="artisticInfo.manager.phone"
            companyName="artisticInfo.manager.companyName"
          />
        ) : null}

        {/* //Publisher Info */}

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="artisticInfo.publisher.accepted"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Publisher Contact
          </label>
          <div className="flex sm:mt-0 mt-5">
            {isPublisherSelected ? (
              <Popconfirm
                title="Are you sure you want to remove manager info?"
                okText="Yes"
                cancelText="No"
                onConfirm={publisherHandler}
              >
                <input
                  id="artisticInfo.publisher"
                  {...register("artisticInfo.publisher.accepted", {
                    required: false,
                  })}
                  checked={isPublisherSelected}
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
                />
              </Popconfirm>
            ) : (
              <input
                id="artisticInfo.publisher"
                {...register("artisticInfo.publisher.accepted", {
                  required: false,
                })}
                checked={isPublisherSelected}
                onChange={publisherHandler}
                type="checkbox"
                className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
              />
            )}
            <label
              htmlFor="artisticInfo.publisher"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              Add Publisher Contact
            </label>
          </div>
        </div>

        {isPublisherSelected ? (
          <CommonContactInfo
            heading="Publisher Contact"
            firstName="artisticInfo.publisher.firstName"
            lastName="artisticInfo.publisher.lastName"
            email="artisticInfo.publisher.email"
            phone="artisticInfo.publisher.phone"
            companyName="artisticInfo.publisher.companyName"
          />
        ) : null}
      </div>
    </div>
  );
}
