import React from "react";
import { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import MemberDetail from "./MemberDetail";
import SendMessage from "../SendMessage";

export default function MemberID(props) {
  // const isUserProfileOpen = props.isUserProfileOpen
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);

  return (
    <div className=" z-50 ">
      <div className="inset-0 overflow-hidden">
        <div className=" inset-0 overflow-hidden">
          <section className="z-50 absolute inset-y-0 right-0 pt-24 pl-10 max-w-full flex sm:pl-16">
            <Transition
              show={props.isUserProfileOpen}
              enter="transform transition ease-in-out duration-200 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen mt-24 h-full max-w-2xl">
                <div className="flex flex-col rounded-lg bg-white overflow-y-scroll">
                  <header className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between ">
                      <h2 className="text-lg leading-7 font-medium text-gray-900">
                        Profile
                      </h2>
                      <div className="h-7 flex items-center">
                        <button
                          aria-label="Close panel"
                          onClick={() => props.closeOverlay()}
                          className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                        >
                          {/* <!-- Heroicon name: x --> */}
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </header>
                  {/* <!-- Main --> */}
                  <div className="divide-y divide-gray-200">
                    <div className="pb-6">
                      <div className="bg-yellow-400 rounded-lg h-24 sm:h-20 lg:h-28"></div>
                      <div className="-mt-12 flow-root px-4 space-y-6 sm:-mt-8 sm:flex sm:items-end sm:px-6 sm:space-x-6 lg:-mt-15">
                        <div>
                          <div className="-m-1 flex">
                            <div className="inline-flex rounded-lg overflow-hidden border-4 border-white">
                              <img
                                className="flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="profile"
                              />

                              {/* // src="https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80" alt="" /> */}
                            </div>
                          </div>
                        </div>
                        <div className="space-y-5 sm:flex-1">
                          <div>
                            <div className="flex items-center space-x-2.5">
                              <h3 className="font-bold text-xl leading-7 text-gray-900 sm:text-2xl sm:leading-8">
                                Arun Chaturvedi
                              </h3>
                              {/* <span aria-label="Online" className="bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full"></span> */}
                            </div>
                            <p className="text-sm leading-5 text-gray-500">
                              @arunChaturvedi
                            </p>
                          </div>
                          <div className="flex flex-wrap">
                            <span className="flex-shrink-0 w-full inline-flex rounded-md sm:flex-1">
                              <button
                                type="button"
                                onClick={() => setIsMessageBoxOpen(true)}
                                className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-300  focus:outline-none focus:border-blue-300 focus-indigo active:bg-white active:text-gray-700 transition ease-in-out duration-150"
                              >
                                Message
                              </button>
                            </span>
                            <span className="mt-3 flex-1 w-full inline-flex rounded-md sm:mt-0 sm:ml-3">
                              <button
                                type="button"
                                onClick={() => setIsMessageBoxOpen(false)}
                                className="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                              >
                                Details
                              </button>
                            </span>
                            <span className="mt-3 ml-3 inline-flex rounded-md sm:mt-0">
                              <div className="relative inline-block text-left">
                                <button
                                  type="button"
                                  onClick={() =>
                                    setIsDropdownOpen(!isDropdownOpen)
                                  }
                                  className="inline-flex items-center p-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-400 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  {/* <!-- Heroicon name: dots-vertical --> */}
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                  </svg>
                                </button>

                                <Transition
                                  show={isDropdownOpen}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md">
                                    <div className="rounded-md bg-white border border-gray-300">
                                      <div
                                        className="py-1"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="options-menu"
                                      >
                                        <Link
                                          to="/admin/accountManagement/userProfile"
                                          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                          role="menuitem"
                                        >
                                          View profile
                                        </Link>
                                        {/* <a href="/" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Copy profile link</a> */}
                                      </div>
                                    </div>
                                  </div>
                                </Transition>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      {isMessageBoxOpen ? <SendMessage /> : <MemberDetail />}
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </section>
        </div>
      </div>
    </div>
  );
}
