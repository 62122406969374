import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { useQuery } from "../../../../utils/use-query";
import Logo from "../../Assets/songwritersca.png";

export default function ResetPassword(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [submitting, setSubmitting] = useState(false);
  const query = useQuery();
  const history = useHistory();

  const onSubmit = (data) => {
    setSubmitting(true);
    const payload = {
      resetToken: query.get("token"),
      newPassword: data.resetPassword.password,
    };
    axios
      .post("/users/password/reset", payload)
      .then(() => {
        toast.success("Congratulations! Your password has been changed.");
        history.push("/");
      })
      .finally(() => setSubmitting(false));
  };

  const password = useRef({});
  password.current = watch("resetPassword.password", "");

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="py-12 bg-yellow-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            <div>
              <svg
                className="w-10 text-yellow-500"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 225 225"
              >
                <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                  <g>
                    <path
                      id="Layer0_0_1_STROKES"
                      className="st0"
                      d="M173.8,151.5l13.6-13.6 M35.4,89.9l29.1-29 M89.4,34.9v1 M137.4,187.9l-0.6-0.4     M36.6,138.7l0.2-0.2 M56.1,169.1l27.7-27.6 M63.8,111.5l74.3-74.4 M87.1,188.1L187.6,87.6 M110.8,114.5l57.8-57.8"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-yellow-500 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
          >
            Set New Password
          </h2>
          <div className="mt-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-8">
                <div className="flex justify-between items-center">
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    New Password
                  </div>
                </div>
                <input
                  {...register("resetPassword.password", {
                    required: "You must specify a password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                    maxLength: {
                      value: 64,
                      message: "Password must have at most 64 characters",
                    },
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
                      message:
                        "Please, include a mixture of both uppercase and lowercase letters, letters and numbers and at least one special character.",
                    },
                  })}
                  className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500"
                  type="password"
                  placeholder="Password"
                />
                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="resetPassword.password"
                  className="text-red-500"
                >
                  {({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type}>{message}</p>
                    ))
                  }
                </ErrorMessage>

                <p className="mt-2 text-sm text-gray-500">
                  Minimum 8 characters and maximum 64 characters. <br/>
                  Include at least upper case English letter. <br />
                  Include at least lower case English letter. <br />
                  Include at least one number. <br />
                  Include at least one special character # ? ! @ $ % ^ & * - or space.
                </p>
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center">
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    Confirm Password
                  </div>
                </div>
                <input
                  className="w-full py-2 border-b border-gray-300 focus:outline-none rounded-lg focus:border-yellow-500"
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    required: "This field is mandatory",
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                  })}
                />

                <ErrorMessage
                  as="p"
                  errors={errors}
                  name="confirmPassword"
                  className=" text-red-500"
                >
                  {({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type}>{message}</p>
                    ))
                  }
                </ErrorMessage>
              </div>
              <div className="mt-10">
                <input
                  type="submit"
                  className="bg-yellow-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-yellow-600
                                shadow-lg"
                  value="Continue"
                  disabled={submitting}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex sidebarBgImg items-center justify-center bg-yellow-100 flex-1 min-h-screen">
        <div className="max-w-xs opacity-90 transform duration-200 hover:scale-110 cursor-pointer">
          <img className="h-18 w-auto" src={Logo} alt="Workflow" />
        </div>
      </div>
    </div>
  );
}
