import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function SubgenreTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Usually in free text as it should to be read. Make sure to enter this information in a constant manner, always using the same format and spelling..."
    >
      <i className="far fa-info-circle" onClick={props.showSubgenreTooltip}></i>
      <Drawer
        title="Subgenre"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeSubgenreTooltip}
        visible={props.subgenreTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          Usually in free text as it should to be read. Make sure to enter this
          information in a constant manner, always using the same format and
          spelling.
        </p>
        <h1>Description</h1>
        <p>The subSubgenre is used to specify a Subgenre.</p>
        <h1>Use</h1>
        <p>
          In MetaMusic's model, it is possible to add subSubgenres for tracks,
          but not for products. Unlike the Subgenre, the subSubgenre offers
          greater flexibility. With some exceptions, you can use the subSubgenre
          of your choice.
        </p>
        <p>
          It is recommended to use the indicative list of Subgenres to specify a
          Subgenre that proves difficult to establish under the same indicative
          list. For example, in the case of uncertainty between pop and rock,
          use one as the Subgenre and the other as the subSubgenre.
        </p>
        <p>
          In the specific case of the World Traditions Subgenre, it is strongly
          recommended to use a subSubgenre to specify the country or territory
          of origin.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
