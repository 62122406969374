import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function ISRCTooltip(props) {
  return (
    <span
      className="hover:text-yellow-400 pl-1"
      data-tip="The ISNI code consists of four sequences of four characters for a total of 16. We do not use spaces or hyphens..."
    >
      <i className="far fa-info-circle" onClick={props.showIsniTooltip}></i>

      <Drawer
        title={props.title}
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeIsniTooltip}
        visible={props.isniTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          The ISNI code consists of four sequences of four characters for a
          total of 16. We do not use spaces or hyphens
        </p>

        <h1>Description</h1>
        <p>
          The International Standard Name Identifier (ISNI) is the ISO
          27729:2012 standard (TC 46/SC 9 assignment subcommittee). ISO is the
          International Standard Organisation whose mission is to develop and
          publish international standards for all sectors.
        </p>
        <p>
          The ISNI is a unique and public identifier for a contributor to a
          creative work. It can be for the main artist or for a composer, but it
          can also be for any other legal or natural entity (for example, a
          sound engineer or a record label). The purpose of the ISNI identifier
          is to become a standard bridge identifier that allows the creation of
          a stable hub between various databases (for example between Wikidata,
          VIAF and MusicBrainz). The ISNI database is free and crowdsourced.
          Ultimately, an ISNI is a permalink to an identity on the web of data.
          See for example http://isni.org/isni/000000011... .
        </p>
        <h1>Use</h1>
        <p>
          You should retrieve your ISNI if one has already been assigned to you
          by visiting the site http://www.isni.org/search
        </p>
        <p>
          In Québec and other francophone regions, metaD.media is responsible
          for assigning ISNI for the music industry http://isnimusique.ca\
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
