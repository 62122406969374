import { BadgeCheckIcon } from "@heroicons/react/outline";
import { useState } from "react";
import SongLibraryTable from "../../../../../ProfilePanel/MemberComponents/SongLibrary/RegisteredSongLibraryTable";

const action = {
  icon: BadgeCheckIcon,
  name: "Public Songs Library",
  pathname: "/publicProfile/recentActivity",
  iconForeground: "text-purple-700",
  iconBackground: "bg-purple-50",
};

export default function SongLibraryQuickLink(props) {
  const [isExpanded, setExpanded] = useState(false);

  return (
    // <div>
    <div className="relative group shadow bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-yellow-500">
      <button
        className="outline-none focus:outline-none text-left"
        onClick={() => {
          setExpanded(!isExpanded);
        }}
      >
        <div className="mt-8">
          <h3 className="text-lg font-medium">
            <span className="absolute inset-0" aria-hidden="true" />
            {action.name}
          </h3>
          {!isExpanded ? (
            <>
              <p className="mt-2 text-sm text-gray-500">
                {/* Description */}
              </p>

              <span
                className="pointer-events-none text-lg absolute animate-bounce top-6 right-6 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              >
                <i className="fas fa-arrow-down"></i>
              </span>
            </>
          ) : (
            <span
              className="pointer-events-none text-lg absolute animate-bounce top-6 right-6 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            >
              <i className="fas fa-arrow-up"></i>
            </span>
          )}
        </div>
      </button>
      {isExpanded ? (
        <section>
          <SongLibraryTable />
        </section>
      ) : null}
    </div>
  );
}
