import { useState } from "react";
import SocialMedia from "./SocialMedia";
import Education from "./Education";
import ArtisticInformation from "./AdminArtisticInfo";
import OrganizationProfile from "./OrganizationProfile";
import AdminRegistrationAccountInfo from "./AdminRegistrationAccountInfo";
import AdminRegistrationContact from "./AdminRegistrationContact";
import Funder from "./Funder";
import ResourceLeader from "./ResourceLeader";
import Sponsor from "./Sponsor";
import Donor from "./Donor";
import Vendor from "./Vendor";
import Staff from "./Staff";
import Board from "./Board";
import { useForm, FormProvider } from "react-hook-form";
import AdminRegistrationCommunication from "./AdminRegistrationCommunication";
import AdminNotes from "./AdminNotes";
import ProfileCompleted from "../../../../CommonArea/Modals/ProfileCompleted";
import ProfilePreferences from "./ProfilePreferences";

export default function CreateNewAccount() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const methods = useForm();

  const onSubmit = () => {
    handleModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="space-y-6 bg-white rounded-lg divide-y divide-gray-200">
          <div>
            <AdminRegistrationAccountInfo />

            <AdminRegistrationContact />

            <Education />

            <SocialMedia />

            <ArtisticInformation />

            <ProfilePreferences />

            <ResourceLeader />

            <Board />

            <OrganizationProfile />

            <Funder />

            <Sponsor />

            <Donor />

            <Vendor />

            <Staff />

            <AdminNotes />

            <AdminRegistrationCommunication />

            <div className="pt-5">
              <div className="flex justify-end">
                <input
                  type="submit"
                  className="ml-3 bg-gray-200 text-gray-800 shadow font-medium hover:bg-yellow-400 inline-flex items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                  value="Create Profile"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* Success Modal */}
      <ProfileCompleted
        open={isModalOpen}
        setOpen={handleModal}
        message="Profile has been created successfully."
        button="Done"
        action="/admin/accountManagement"
      />
    </FormProvider>
  );
}

