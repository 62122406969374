import { Switch, Route } from "react-router-dom";
import Dashboard from "./AdminComponents/Dashboard";
import AdminProfile from "./AdminComponents/AdminProfile";
import BillingPayments from "./AdminComponents/BillingPayments";
import AccountManagement from "./AdminComponents/AccountManagement";
import adminProfile from "../CommonArea/Assets/adminProfile.jpg";
import Services from "./AdminComponents/Services";
import MainScreen from "../CommonArea/CommonComponents/MainScreen";

import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import Actions from "./AdminComponents/AccountManagement/MembersTable/Actions";

const navigation = [
  { name: "Dashboard", icon: HomeIcon, pathname: "/admin" },
  {
    name: "My Profile",
    icon: UsersIcon,
    pathname: "/admin/adminProfile",
  },
  {
    name: "Account Management",
    icon: FolderIcon,
    children: [
      { name: "Manage Accounts", pathname: "/admin/accountManagement" },
      {
        name: "Account Registration",
        pathname: "/admin/accountManagement/accountRegistrations",
      },
      {
        name: "Recent Activities",
        pathname: "/admin/accountManagement/recentActivities",
      },
      {
        name: "Account Verification",
        pathname: "/admin/accountManagement/accountVerification",
      },
      {
        name: "Promo Code Creation",
        pathname: "/admin/accountManagement/promoCodeCreation",
      },
      {
        name: "Account Profiles",
        pathname: "/admin/accountManagement/accountProfiles",
      },
      { name: "Access Log", pathname: "/admin/accountManagement/accessLog" },
      { name: "Groups", pathname: "/admin/accountManagement/groups" },
      {
        name: "Account Photos",
        pathname: "/admin/accountManagement/accountPhotos",
      },
      {
        name: "Account Renewal Notification",
        pathname: "/admin/accountManagement/accountRenewalNotification",
      },
      {
        name: "Membership Types",
        pathname: "/admin/accountManagement/membershipTypes",
      },
      {
        name: "User Levels / Roles",
        pathname: "/admin/accountManagement/roles",
      },
    ],
  },
  {
    name: "Billing & Payment",
    icon: CalendarIcon,
    children: [
      { name: "Payments", pathname: "/admin/billing" },
      { name: "Accounting", pathname: "/admin/billing/accounting" },
      { name: "Reconciliations", pathname: "/admin/billing/reconciliations" },
      { name: "Deposits", pathname: "/admin/billing/deposits" },
      { name: "Reports", pathname: "/admin/billing/reports" },
      {
        name: "Accounts Receivable",
        pathname: "/admin/billing/accountsReceivable",
      },
      { name: "Accounts Payable", pathname: "/admin/billing/accountsPayable" },
      { name: "Expenses", pathname: "/admin/billing/expenses" },
      { name: "Donations", pathname: "/admin/billing/donations" },
      { name: "Funders", pathname: "/admin/billing/funders" },
      { name: "Sponsors", pathname: "/admin/billing/sponsors" },
      {
        name: "Credit Memos & Refunds",
        pathname: "/admin/billing/creditMemos",
      },
      { name: "Invoices & Payments", pathname: "/admin/billing/invoices" },
      { name: "Quick Payment", pathname: "/admin/billing/quickPayment" },
      { name: "Online Payments  ", pathname: "/admin/billing/onlinePayments" },
    ],
  },
  {
    name: "Content",
    icon: InboxIcon,
    children: [
      { name: "Content Home", pathname: "/admin/content" },
      { name: "Content Pages", pathname: "/admin/content/contentPages" },
      { name: "Documents", pathname: "/admin/content/documents" },
      { name: "Audio Files", pathname: "/admin/content/audioFiles" },
      { name: "Video Files", pathname: "/admin/content/videoFiles" },
      { name: "File Cabinet", pathname: "/admin/content/fileCabinet" },
      { name: "Member Homepage", pathname: "/admin/content/memberHomepage" },
    ],
  },
  {
    name: "Communications",
    icon: ChartBarIcon,
    children: [
      { name: "Communications Home", pathname: "/admin/communications" },
      { name: "Blog", pathname: "/admin/communications/blog" },
      { name: "Calendar", pathname: "/admin/communications/calendar" },
      { name: "Contact Us", pathname: "/admin/communications/contactUs" },
      {
        name: "Email Broadcast",
        pathname: "/admin/communications/emailBroadcast",
      },
      {
        name: "Facebook Social Plug Ins",
        pathname: "/admin/communications/facebookSocialPlugIns",
      },
      { name: "Rss", pathname: "/admin/communications/rss" },
      {
        name: "Scheduled News Articles",
        pathname: "/admin/communications/scheduledNewArticles",
      },
      { name: "Text Message", pathname: "/admin/communications/textMessage" },
      {
        name: "Upcoming Events",
        pathname: "/admin/communications/upcomingEvents",
      },
      {
        name: "Questionnaire",
        pathname: "/admin/communications/questionnaire",
      },
      { name: "Email Queue", pathname: "/admin/communications/emailQueue" },
      {
        name: "Social Media Analytics",
        pathname: "/admin/communications/socialMediaAnalytics",
      },
      {
        name: "Pending Items Alert",
        pathname: "/admin/communications/pendingItemsAlert",
      },
    ],
  },
  {
    name: "Governance",
    icon: InboxIcon,
    children: [
      { name: "Governance Home", pathname: "/admin/governance" },
      {
        name: "Board Member Only",
        pathname: "/admin/governance/boardMemberOnly",
      },
      { name: "Official Voting", pathname: "/admin/governance/officialVoting" },
      { name: "Folder Viewer", pathname: "/admin/governance/folderViewer" },
      { name: "Questionnaire", pathname: "/admin/governance/questionnaire" },
    ],
  },

  {
    name: "Events",
    icon: InboxIcon,
    children: [
      { name: "Events Home", pathname: "/admin/events" },
      {
        name: "Event Registration",
        pathname: "/admin/events/eventRegistrations",
      },
      { name: "Media Assets", pathname: "/admin/events/mediaAssets" },
    ],
  },

  {
    name: "Services",
    icon: InboxIcon,
    children: [
      { name: "Services Home", pathname: "/admin/services" },
      { name: "SongLibrary", pathname: "/admin/services/songLibrary" },
      { name: "SongVault", pathname: "/admin/services/songVault" },
      { name: "SongPitch", pathname: "/admin/services/songPitch" },
      { name: "Writers Room", pathname: "/admin/services/writersRoom" },
      {
        name: "SongWriter Agreements",
        pathname: "/admin/services/songWriterAgreements",
      },
    ],
  },

  {
    name: "Programs",
    icon: InboxIcon,
    children: [
      { name: "Programs Home", pathname: "/admin/programs" },
      { name: "Pro SongWorks", pathname: "/admin/programs/proSongWorks" },
      {
        name: "Regional Writers Groups",
        pathname: "/admin/programs/regionalWritersGroup",
      },
      { name: "Songbird North", pathname: "/admin/programs/songbirdNorth" },
      {
        name: "Celebration Series Songbird North",
        pathname: "/admin/programs/celebrationSeries",
      },
      {
        name: "Add Mentorship under Program",
        pathname: "/admin/programs/addMentorship",
      },
      {
        name: "Add Subscription button to Services",
        pathname: "/admin/programs/addSubscriptionButton",
      },
    ],
  },
];

export default function AdminPanel() {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MainScreen
        mainScreen={
          <Switch>
            <Route exact path="/admin">
              <Dashboard />
            </Route>

            <Route path="/admin/adminProfile">
              <AdminProfile adminProfilePic={adminProfile} />
            </Route>

            <Route path="/admin/accountManagement">
              <AccountManagement />
            </Route>

            <Route path="/admin/actions">
              <Actions />
            </Route>

            <Route path="/admin/billing">
              <BillingPayments />
            </Route>

            <Route path="/admin/services">
              <Services />
            </Route>
          </Switch>
        }
        navigation={navigation}
        // profileLink="/admin/adminProfile"
      />
    </div>
  );
}
