export default function HeaderSvg() {
  return (
    <>
      <svg
        className="flex-shrink-0"
        width={2390}
        height={316}
        viewBox="0 0 1750 308"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity=".75"
          d="M1465.84 308L16.816 0H1750v308h-284.16z"
          fill="#F59E0B"
        />
        <path
          opacity=".75"
          d="M1733.19 0L284.161 308H0V0h1733.19z"
          fill="#F59E0B"
        />
      </svg>
    </>
  );
}
