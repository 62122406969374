import { Button, Steps } from "antd";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { userStore } from "../../../../../store/userStore";
import Logo from "../../../Assets/songwritersca.png";
import AssociateInfoForm from "../../UserInfoForms/AssociateInfoForm.jsx/index.jsx";
import ArtisticInfoForm from "../../UserInfoForms/MemberInfoForms/ArtisticInfoForm";
import PersonalInfoForm from "../../UserInfoForms/MemberInfoForms/PersonalInfoForm";
import SocialMediaForm from "../../UserInfoForms/MemberInfoForms/SocialMediaForm";

const ProfileWiz = () => {
  const [loading, setLoading] = useState(false);

  const [current, setCurrent] = useState(0);

  const user = userStore((state) => state.user);

  const methods = useForm({ defaultValues: user });

  const history = useHistory();

  const { handleSubmit } = methods;

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = async (data) => {
    if (current < steps.length - 1) {
      setCurrent(current + 1);
      return;
    }
    setLoading(true);
    await userStore.getState().updateUser(data).then(() => {
      history.push('/')
    }).finally(() => {
      setLoading(false);
    });
  };

  const accountType = userStore((state) => state.user.accountType);

  const { Step } = Steps;

  const memberSteps = [
    {
      title: "Personal Information",
      content: (
        <PersonalInfoForm
        />
      ),
    },
    {
      title: "Artistic Information",
      content: (
        <ArtisticInfoForm
        />
      ),
    },
    {
      title: "Social Media",
      content: (
        <SocialMediaForm
        />
      ),
    },
  ];

  const associateSteps = [
    {
      title: "Associate Information",
      content: (
        <AssociateInfoForm
        />
      ),
    },
    {
      title: "Social Media",
      content: (
        <SocialMediaForm
        />
      ),
    },
  ];

  const steps =
    (accountType === "Member" && memberSteps) ||
    (accountType === "Associate" && associateSteps);

  // ...(false ? ["falsy"] : []),

  return (
    <>
      <FormProvider {...methods}>
        <div className="sidebarBgImg min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          {/* <div className="w-8/12 bg-white m-auto border shadow p-8 rounded-xl "> */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg sm:m-auto sm:w-8/12 ">
            {/* <div className=" m-auto "> */}
            <div className="px-4 py-5 sm:px-6 ">
              <div className="box pb-6 ">
                <div className="items-center text-3xl border-b border-gray-200 pb-5 mb-5">
                  <img className="h-8 w-auto" src={Logo} alt="Workflow" />
                </div>
                <div className=" m-auto">
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action my-5">
                  {current > 0 && (
                    <Button disabled={loading} onClick={prev} size="middle">
                      Previous
                    </Button>
                  )}
                  <Button type="primary" htmlType="submit" loading={loading} size="middle">
                    {current < steps.length - 1 ? 'Next': 'Done'}
                  </Button>              
                </div>
              </form>
            </div>
            {/* </div> */}
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default ProfileWiz;
