import { useRef } from "react";
import { DatePicker } from "antd";
import { useFormContext, Controller } from "react-hook-form";
import StaffCheckbox from "./StaffCheckbox";
import StaffDatePicker from "./StaffDatePicker";

export default function Staff() {
  const { register, control, watch } = useFormContext();

  const workplaceSafety = useRef({});
  workplaceSafety.current = watch(
    "staff.policies.workplaceSafety.accepted",
    ""
  );

  const codeOfConduct = useRef({});
  codeOfConduct.current = watch("staff.policies.codeOfConduct.accepted", "");

  const harrasmentViolence = useRef({});
  harrasmentViolence.current = watch(
    "staff.policies.harrasmentViolence.accepted",
    ""
  );

  const diversity = useRef({});
  diversity.current = watch("staff.policies.diversity.accepted", "");

  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">Staff</h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.education"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Education
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("staff.education", {
                required: false,
              })}
              id="staff.education"
              autoComplete="staff.education"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.jobTitle"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Job Title
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("staff.jobTitle", {
                required: false,
              })}
              id="staff.jobTitle"
              autoComplete="staff.jobTitle"
              className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.startDate"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Start Date
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Controller
                control={control}
                name="staff.startDate"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    style={{
                      borderRadius: "0.4rem",
                      cursor: "pointer",
                      fontSize: "17px",
                      padding: "0.4rem",
                    }}
                    className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onBlur={onBlur}
                    onChange={onChange}
                    selected={value}
                    inputRef={ref}
                  />
                )}
              />
            </span>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.endDate"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            End Date
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Controller
                control={control}
                name="staff.endDate"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    style={{
                      borderRadius: "0.4rem",
                      cursor: "pointer",
                      fontSize: "17px",
                      padding: "0.4rem",
                    }}
                    className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onBlur={onBlur}
                    onChange={onChange}
                    selected={value}
                    inputRef={ref}
                  />
                )}
              />
            </span>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.resumeUrl"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Resume
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="staff.resumeUrl"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span className="text-yellow-400"> Upload a file</span>
                    <input
                      id="staff.resumeUrl"
                      type="file"
                      className="sr-only"
                      {...register("staff.resumeUrl", {
                        required: false,
                      })}
                    />
                  </label>
                  <p className="pl-1 text-gray-600">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">DOCX, PDF up to 10MB</p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.jobDescriptionUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Job Description
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="staff.jobDescriptionUrls"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span className="text-yellow-400"> Upload a file</span>
                    <input
                      id="staff.jobDescriptionUrls"
                      type="file"
                      className="sr-only"
                      {...register("staff.jobDescriptionUrls", {
                        required: false,
                      })}
                    />
                  </label>
                  <p className="pl-1 text-gray-600">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">DOCX, PDF up to 10MB</p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.employementContractUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Employement Contract
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="staff.employementContractUrls"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span className="text-yellow-400"> Upload a file</span>
                    <input
                      id="staff.employementContractUrls"
                      type="file"
                      className="sr-only"
                      {...register("staff.employementContract", {
                        required: false,
                      })}
                    />
                  </label>
                  <p className="pl-1 text-gray-600">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">DOCX, PDF up to 10MB</p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="staff.emergencyContactUrl"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Emergency Contact Information
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="staff.emergencyContactUrl"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span className="text-yellow-400"> Upload a file</span>
                    <input
                      id="staff.emergencyContactUrl"
                      type="file"
                      className="sr-only"
                      {...register("staff.emergencyContactUrl", {
                        required: false,
                      })}
                    />
                  </label>
                  <p className="pl-1 text-gray-600">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">DOCX, PDF up to 10MB</p>
              </div>
            </div>
          </div>
        </div>

        <StaffCheckbox
          heading="Employee has read and understood the Workplace Safety Policy."
          fieldName="staff.policies.workplaceSafety.accepted"
        />

        {workplaceSafety.current ? (
          <StaffDatePicker fieldName="staff.policies.workplaceSafety.date" />
        ) : null}

        <StaffCheckbox
          heading="Employee has read and understood the Code of Conduct."
          fieldName="staff.policies.codeOfConduct.accepted"
        />

        {codeOfConduct.current ? (
          <StaffDatePicker fieldName="staff.policies.codeOfConduct.date" />
        ) : null}

        <StaffCheckbox
          heading="Employee has read and understood the Anti-harrassment and Violence
          in the Workplace Policy."
          fieldName="staff.policies.harrasmentViolence.accepted"
        />

        {harrasmentViolence.current ? (
          <StaffDatePicker fieldName="staff.policies.harrasmentViolence.date" />
        ) : null}

        <StaffCheckbox
          heading="Employee has read and understood the Diversity, Equity and
          Inclusion Policy."
          fieldName="staff.policies.diversity.accepted"
        />
        {diversity.current ? (
          <StaffDatePicker fieldName="staff.policies.diversity.date" />
        ) : null}
      </div>
    </div>
  );
}
