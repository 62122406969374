export const message = {
  width: "50rem",
  title: "Important Information",
  content: (
    <div>
      <p>
        S.A.C. is thrilled to share the beta launch of our brand new digital
        platform! You may notice things look different at{" "}
        <a
          href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}`}
          target="_blank"
          rel="noreferrer"
        >
          songwriters.ca
        </a>
        .
      </p>
      <p>
        S.A.C. invites members to test access their accounts with limited
        features following the instructions below and to provide feedback on any
        bugs, errors, or issues they encounter.
      </p>
      <p>
        If you have an S.A.C. account - either active or expired within the past
        10 years - to access your account on the new Member Portal (
        <a href={`${process.env.PUBLIC_URL}`}>app.songwriters.ca</a>), you will
        first have to reset your password.
      </p>
      <p>
        Here’s how you can access your membership account online step-by-step:
      </p>
      <ol style={{ listStyle: "outside decimal", marginLeft: "1rem" }}>
        <li>Go to songwriters.ca</li>
        <li>Click on “Log In”.</li>
        <li>
          Click on “Forgot Password” and type in the email you registered for
          your membership with, and click ‘Submit’.
        </li>
        <li>
          Check your email and click the ‘Reset’ link, you will be navigated
          back to the log-in page.
        </li>
        <li>Type in your new password and click ‘Save’.</li>
        <li>
          Log in with your new password and existing email to access your test
          account.
        </li>
      </ol>
      <p>What is a Test Account?</p>
      <p>
        Any changes you make to your profile in this testing stage will not be
        saved in the Member Portal when it goes live. You will be required to
        reset your account and profile once the Portal launches. S.A.C. will
        notify you every step of the way with further instructions when this
        round of testing is complete.
      </p>
      <p>Here is what you have access to right now:</p>
      <ul style={{ listStyle: "disc", marginLeft: "1rem" }}>
        <li>Building Your Profile</li>
        <li>Updating Your Profile and User Settings</li>
        <li>Member Search</li>
      </ul>
      <p>
        Please use{" "}
        <a
          href="https://forms.gle/w89qd1D3nv7FuZTJ6"
          target="_blank"
          rel="noreferrer"
        >
          this form
        </a>{" "}
        to report any technical difficulties you experience with our website, or
        provide suggestions for improvement.
      </p>
      <b>FAQ</b>
      <ul>
        <li>
          <p>
            Q: I tried to login with my email and received the following error
            message: “This user is inactive”.
            <br />
            A: Rest assured! Your account is in fact active! We are in the
            process of migrating all our members’ accounts to a new Member
            Portal environment. When your information has been migrated, you’ll
            be notified via email. Stay tuned!
          </p>
        </li>
        <li>
          <p>
            Q: I would like to sign up for S.A.C. Membership. What should I do?{" "}
            <br />
            A: Because you’re in the test environment, use this{" "}
            <a
              href="https://forms.gle/WZGAme7Gtv2yfUTq6"
              target="_blank"
              rel="noreferrer"
            >
              temporary link{" "}
            </a>
            to sign up or renew your actual membership.
          </p>
        </li>
        <li>
          <p>
            Q: I would like to submit a SongVault application. What are my next
            steps?
            <br />
            A: SongVault applications are still being accepted by mail with your
            songs on a CD or USB Flash Drive. Instructions to submit and mailing
            address can be found on the application form{" "}
            <a
              href="https://drive.google.com/file/d/1bawdw_mkMiAFpFHc6lAgFt9R3dHPo8Tb/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </p>
        </li>
      </ul>
    </div>
  ),
};
