/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

export default function ProfileCompleted(props) {
  // const handleResetPassword = props.handleResetPassword;

  const data = {
    open: props.open,
    setOpen: props.setOpen,
    message: props.message,
    button: props.button,
    action: props.action,
  };

  const history = useHistory();

  const handleDone = () => {
    history.push(data.action);
  };

  return (
    <Transition.Root show={data.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={data.open}
        onClose={data.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Profile Completed
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{data.message}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 ">
                <button
                  type="button"
                  className="bg-gray-200 w-full inline-flex justify-center text-gray-800 shadow font-medium hover:bg-yellow-400 items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                  onClick={() => handleDone()}
                >
                  {data.button}
                </button>
                {/* <button
                  type="button"
                  className="bg-gray-200 w-full inline-flex justify-center text-gray-800 shadow font-medium hover:bg-yellow-400 items-center px-5 p-2 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                  onClick={() => handleResetPassword()}
                  // ref={cancelButtonRef}
                >
                  Do it Later
                </button> */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
