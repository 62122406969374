import { Route, Switch } from "react-router-dom";
import SongDetail from "../../../../CommonArea/CommonComponents/RegisterNewSong/SongDetail";
import SongLibraryTables from "./SongLibraryTables";

export default function AdminSongLibrary() {
  return (
    <>
      <Switch>
        <Route path="/admin/services/songLibrary">
          <SongLibraryTables />
        </Route>
        <Route path="/admin/services/upload">
          <SongDetail />
        </Route>
      </Switch>
    </>
  );
}
