import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import "./App.css";
import AdminPanel from "./Components/AdminPanel";
import ForgotPassword from "./Components/CommonArea/Authentication/ForgotPassword";
import ResetPassword from "./Components/CommonArea/Authentication/ForgotPassword/ResetPassword";
import Login from "./Components/CommonArea/Authentication/Login";
import Signup from "./Components/CommonArea/Authentication/Signup";
import EmailVerification from "./Components/CommonArea/Authentication/Signup/EmailVerification";
import EmailVerificationSteps from "./Components/CommonArea/Authentication/Signup/EmailVerification/EmailVerificationSteps";
import CreateMemberPayment from "./Components/CommonArea/CommonComponents/CreateMemberAccount/CreateMemberPayment";
import ProfileWiz from "./Components/CommonArea/CommonComponents/CreateMemberAccount/CreateMemberWizard";
import PublicPreview from "./Components/CommonArea/CommonComponents/PublicPreview";
import PageNotFound from "./Components/CommonArea/PageNotFound.jsx";
import ProfilePanel from "./Components/ProfilePanel";
import { authStore } from "./store";
import { userStore } from "./store/userStore";

const authGuard = async (to, from, next) => {
  const authStoreState = authStore.getState;

  if (
    authStoreState().authState === "Loading" ||
    authStoreState().user == null
  ) {
    await authStoreState().verifyAuth();
  }

  const authenticated = () => authStoreState().authState === "Authenticated";

  if (to.meta.auth) {
    authenticated() ? next() : next.redirect("/login");
  } else if (to.meta.noAuth) {
    authenticated() ? next.redirect("/") : next();
  } else {
    next();
  }
};

const onboardingGuard = async (to, from, next) => {
  const onboardingUrl = "/onboarding";
  const isOnboardingUrl = to.location.pathname === onboardingUrl;
  const userStoreState = userStore.getState;

  if (to.meta.noAuth) next();

  if (userStoreState().loading || userStoreState().user == null) {
    await userStoreState().fetchUser();
  }

  const user = userStoreState().user;

  if (user.isOnboardingCompleted || user.role === 'Administrator') {
    isOnboardingUrl ? next.redirect("/") : next();
  } else {
    isOnboardingUrl ? next() : next.redirect(onboardingUrl);
  }
};

export default function App() {
  return (
    <div>
      <Router>
        <GuardProvider guards={[authGuard, onboardingGuard]}>
          <Switch>
            <GuardedRoute
              path="/login"
              component={Login}
              meta={{ noAuth: true }}
            />

            <GuardedRoute path="/signup" meta={{ noAuth: true }}>
              <Signup />
            </GuardedRoute>

            <GuardedRoute path="/forgotPassword" meta={{ noAuth: true }}>
              <ForgotPassword />
            </GuardedRoute>

            <Route path="/resetPassword">
              <ResetPassword />
            </Route>
            <Route path="/emailVerification">
              <EmailVerification />
            </Route>
            <Route path="/verify">
              <EmailVerificationSteps />
            </Route>

            <Route
              path="/events"
              component={() => {
                window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE_URL}/events`;
                return null;
              }}
            />

            <GuardedRoute
              path="/onboarding"
              render={(props) => <CreateMemberPayment {...props} />}
              meta={{ auth: true }}
            />

            <GuardedRoute
              path="/profileWiz"
              render={(props) => <ProfileWiz {...props} />}
              meta={{ auth: true }}
            />
            <GuardedRoute
              path="/admin"
              component={AdminPanel}
              meta={{ auth: true }}
            />

            <GuardedRoute
              path="/editdetails"
              component={ProfilePanel}
              meta={{ auth: true }}
            />

            <GuardedRoute
              path="/songvault"
              component={ProfilePanel}
              meta={{ auth: true }}
            />

            <GuardedRoute
              path="/songlibrary"
              component={ProfilePanel}
              meta={{ auth: true }}
            />

            <Route path="/users" component={PublicPreview} />

            <GuardedRoute
              path="/"
              component={ProfilePanel}
              meta={{ auth: true }}
            />

            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </GuardProvider>
      </Router>
    </div>
  );
}
