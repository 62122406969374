import React, { useState } from "react";
import { Select } from "antd";
import GenreTooltip from "./common/Tooltip/GenreTooltip";
import { Controller, useFormContext } from "react-hook-form";
import genreOptions from "../../../CommonOptions/genreOptions";

const Genre = () => {
  const { control } = useFormContext();
  const { Option } = Select;

  const [genreTooltip, setGenreTooltip] = useState(false);
  const showGenreTooltip = () => {
    setGenreTooltip(true);
  };
  const closeGenreTooltip = () => {
    setGenreTooltip(false);
  };

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="genres"
        className="block text-sm font-medium leading-5 text-gray-700 mb-1"
      >
        Genre
        <GenreTooltip
          genreTooltip={genreTooltip}
          showGenreTooltip={showGenreTooltip}
          closeGenreTooltip={closeGenreTooltip}
        />
      </label>
      <Controller
        control={control}
        name="genre"
        render={({ field: { onChange, value } }) => (
          <Select
            mode="multiple"
            allowClear
            style={{ padding: "2px" }}
            bordered={false}
            placeholder="Please select"
            onChange={onChange}
            className="mt-1 w-full focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-3 rounded-md border border-gray-300 block transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            value={value}
          >
            {genreOptions.map((option, idx) => (
              <Option key={idx} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        )}
      />
    </div>
  );
};

export default Genre;
