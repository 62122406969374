import { Controller, useFormContext } from "react-hook-form";
import { EnumField } from "./model";
import { Switch } from "antd";

interface Props {
  fields: EnumField[];
  renderCondition: (v: EnumField) => boolean;
  name: string;
  loading: boolean;
}

interface EnumFieldWithId extends EnumField {
  id: number;
}

const EnumFlagFormComponent = (props: Props) => {
  const values = props.fields.map<EnumFieldWithId>((v, i) => ({ ...v, id: i }));

  const { control } = useFormContext();
  const defaultValue = 0;

  const isChecked = (id: number, fieldValue: number): boolean => {
    const permissionVal = 1 << id;
    const permission = fieldValue ?? defaultValue;
    return (permissionVal & permission) === permissionVal;
  };

  const onChange = (
    checked: boolean,
    id: number,
    currentValue: number,
    controllerOnChange: (val: number) => void
  ): void => {
    let permission = currentValue ?? defaultValue;

    if (checked) {
      permission += 1 << id;
      controllerOnChange(permission);
    } else {
      permission -= 1 << id;
      controllerOnChange(permission);
    }
  };

  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          {values.filter(props.renderCondition).map(v => (
            <div className="py-4 flex items-center justify-between" key={v.id}>
              <div className="flex flex-col">
                <div className="text-sm font-medium text-gray-900">
                  {v.title}
                </div>
                <div className="text-sm text-gray-500">{v.description}</div>
              </div>
              <Switch
                checked={isChecked(v.id, field.value)}
                onChange={(val) =>
                  onChange(val, v.id, field.value, field.onChange)
                }
                loading={props.loading}
              ></Switch>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default EnumFlagFormComponent;
