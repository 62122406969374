import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function IPNTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="The IPN consists of eight digits (01234567) without spaces or hyphens."
    >
      <i className="far fa-info-circle" onClick={props.showIpn}></i>

      <Drawer
        title="IPN#"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeIpn}
        visible={props.ipn}
      >
        <h1>Entry Rule</h1>
        <p>
          The IPN consists of eight digits (01234567) without spaces or hyphens.
        </p>
        <h1>Description</h1>
        <p>
          The International Performer Number (IPN) is a proprietary identifier
          for performers under SCAPR and recorded in the International
          Performers Database [IPD]. It is mainly used for internal
          administrative tasks of Collective Management Organizations for
          performers. It is possible that its use will become more widespread in
          the future.
        </p>
        <h1>Use</h1>
        <p>
          To obtain your IPN, you must regsister as a member with a Collective
          Management Organization for performers who is a member of SCAPR, such
          as Artisti.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
