import { Drawer, Collapse } from "antd";
import ReactTooltip from "react-tooltip";

const { Panel } = Collapse;
function callback(key) {
}
export default function RoleTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Author of the lyrics of a song, the libretto of an opera, or a musical..."
    >
      <i className="far fa-info-circle" onClick={props.showRoleTooltip}></i>

      <Drawer
        title="Role"
        width={520}
        placement="right"
        closable={true}
        destroyOnClose={true}
        onClose={props.closeRoleTooltip}
        visible={props.roleTooltip}
      >
        <Collapse onChange={callback} ghost>
          <Panel header="Role-Lyricist(A)" className="font-xl" key="1">
            <h1>Description</h1>
            <p>
              Author of the lyrics of a song, the libretto of an opera, or a
              musical.
            </p>
            <h1>Use</h1>
            <p>
              This is a mandatory field. This field is left empty if the work
              does not have lyrics. The lyricist is the specific author of a
              song’s lyrics.
            </p>
          </Panel>

          <Panel header="Role-Composer(C)" key="2">
            <h1>Description</h1>
            <p>Composer(s) of a musical work.</p>
            <h1>Use</h1>
            <p>This is a mandatory field.</p>
          </Panel>

          <Panel header="Role-Songwriter(AC)" key="3">
            <h1>Description</h1>
            <p>
              Use when the composer and lyricist of a work are the same person.
            </p>
            <h1>Use</h1>
            <p>
              This is a mandatory field when the author and composer are the
              same person.
            </p>
          </Panel>

          <Panel header="Role-Adapter(AD)" key="4">
            <h1>Description</h1>
            <p>Person who adapts a literary text into song lyrics.</p>
            <h1>Use</h1>
            <p>This field is mandatory when there has been an adaptation.</p>
          </Panel>

          <Panel header="Role-Arranger(AR)" key="5">
            <h1>Description</h1>
            <p>Arranger of an existing composition.</p>
            <h1>Use</h1>
            <p>This field is mandatory when there has been an arrangement.</p>
          </Panel>
          <Panel header="Role-Translator(TR)" key="6">
            <h1>Description</h1>
            <p>
              Translator of the lyrics of a song, the libretto of an opera, or a
              musical.
            </p>
            <h1>Use</h1>
            <p>This field is mandatory when there has been a translation.</p>
          </Panel>
        </Collapse>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
