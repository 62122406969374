import { useRef } from "react";
import { DatePicker } from "antd";
import "../../../../../App.css";
import { useFormContext, Controller } from "react-hook-form";
import AvatarUpload from "../../../../CommonArea/CommonComponents/AvatarUpload";
import { primaryCountries, secondaryCountries } from "../../../../../store/countries";

export default function OrganizationProfile() {
  const { register, watch, control } = useFormContext();

  const contactPerson = useRef({});
  contactPerson.current = watch("organization.selfContact", "");

  return (
    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
      <h3 className="text-xl leading-6 font-medium text-gray-900">
        Organization Profile
      </h3>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.logoUrl"
          className="block text-sm font-medium text-gray-700"
        >
          Logo
        </label>
        <AvatarUpload placeholderText="Upload Image" title="Upload image" />
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.name"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Company / Business Name
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("organization.name", {
              required: false,
            })}
            id="organization.name"
            autoComplete="organization.name"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.headOfficeAddress.line1"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Head Office Address
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("organization.headOfficeAddress.line1", {
              required: false,
            })}
            id="organization.headOfficeAddress.line1"
            autoComplete="organization.headOfficeAddress.line1"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.headOfficeAddress.line2"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Head Office Address (Line 2)
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("organization.headOfficeAddress.line2", {
              required: false,
            })}
            id="organization.headOfficeAddress.line2"
            autoComplete="organization.headOfficeAddress.line2"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.headOfficeAddress.postalCode"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Postal Code
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("organization.headOfficeAddress.postalCode", {
              required: false,
            })}
            id="organization.headOfficeAddress.postalCode"
            autoComplete="organization.headOfficeAddress.postalCode"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.headOfficeAddress.city"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          City
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("organization.headOfficeAddress.city", {
              required: false,
            })}
            id="organization.headOfficeAddress.city"
            autoComplete="organization.headOfficeAddress.city"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.headOfficeAddress.province"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Province
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register("organization.headOfficeAddress.province", {
              required: false,
            })}
            id="organization.headOfficeAddress.province"
            autoComplete="organization.headOfficeAddress.province"
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.headOfficeAddress.country"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Country
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="organization.headOfficeAddress.country"
            {...register("organization.headOfficeAddress.country", {
              required: false,
            })}
            autoComplete="organization.headOfficeAddress.country"
            defaultValue=""
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <optgroup>
              {primaryCountries.map(v => (<option value={v}>{v}</option>))}
            </optgroup>
            <optgroup>
              {secondaryCountries.map(v => (<option value={v}>{v}</option>))}
            </optgroup>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.status"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Company Status
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            id="organization.status"
            {...register("organization.status", {
              required: false,
            })}
            autoComplete="organization.status"
            defaultValue=""
            // onChange={(e) => props.setSalutation(e.target.value)}
            className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            <option value="" disabled>
              Select Company Status
            </option>
            <option value="for-profit">For-Profit</option>
            <option value="non-profit">Non-Profit</option>
            <option value="charity">Charity</option>
          </select>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.startDate"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Start Date
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <Controller
              control={control}
              name="organization.startDate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  style={{
                    borderRadius: "0.4rem",
                    cursor: "pointer",
                    fontSize: "17px",
                    padding: "0.4rem",
                  }}
                  className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                  inputRef={ref}
                />
              )}
            />
          </span>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.websiteUrl"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Company Website
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="text-sm max-w-lg flex rounded-md shadow-sm">
            <input
              type="text"
              {...register("organization.websiteUrl", {
                required: false,
              })}
              id="organization.websiteUrl"
              autoComplete="organization.websiteUrl"
              className="focus:ring-yellow-300 flex-1  block w-full focus:ring-0 focus:outline-none focus:border-yellow-400 min-w-0 rounded-md sm:text-sm border-gray-300"
            />
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.description"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Description
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <textarea
            id="organization.description"
            {...register("organization.description", {
              required: false,
            })}
            rows="3"
            className="focus:ring-yellow-300 text-sm max-w-lg shadow-sm block w-full focus:outline-none p-2 sm:text-sm border-gray-300 focus:border-yellow-400 rounded-md"
          />

          <p className="mt-2 text-sm text-gray-500">
            Write a few sentences about yourself.
          </p>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="organization.selfContact"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Contact Person
        </label>
        <div className="flex mt-2">
          <input
            id="organization.selfContact"
            {...register("organization.selfContact", {
              required: false,
            })}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-yellow-500 transition duration-150 ease-in-out rounded focus:ring-yellow-400"
          />
          <label
            htmlFor="organization.selfContact"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            I'm the Contact Person
          </label>
        </div>
      </div>

      {!contactPerson.current ? (
        <div className="pt-5 space-y-6 sm:pt-10 sm:space-y-5 border-t border-gray-200">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Enter Contact Details
          </h3>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
            <label
              htmlFor="organization.contact.firstName"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              First Name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("organization.contact.firstName", {
                  required: false,
                  shouldUnregister: true,
                })}
                id="organization.contact.firstName"
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
            <label
              htmlFor="organization.contact.lastName"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Last Name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("organization.contact.lastName", {
                  required: false,
                  shouldUnregister: true,
                })}
                id="organization.contact.lastName"
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
            <label
              htmlFor="organization.contact.title"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Title
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("organization.contact.title", {
                  required: false,
                  shouldUnregister: true,
                })}
                id="organization.contact.title"
                className="focus:ring-yellow-300 text-sm max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
            <label
              htmlFor="organization.contact.email"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Email
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                {...register("organization.contact.email", {
                  required: false,
                  shouldUnregister: true,
                })}
                id="organization.contact.email"
                className="focus:ring-yellow-300 text-sm max-w-lg block focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t border-gray-200 sm:pt-5">
            <label
              htmlFor="organization.contact.phone"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Phone
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="tel"
                {...register("organization.contact.phone", {
                  required: false,
                  shouldUnregister: true,
                  maxLength: 12,
                  minLength: 6,
                })}
                id="organization.contact.phone"
                className="focus:ring-yellow-300 text-sm max-w-lg block focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
