import { Controller, useFormContext } from "react-hook-form";

import React from "react";
import { Radio } from "antd";

const WorkType = () => {
  const { control } = useFormContext();


  return (
    <div className="sm:col-span-3 ">
      <span className="block text-sm font-medium text-gray-700">Work Type</span>
      <div className="mt-2">
        <Controller
          control={control}
          name="workType"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Radio.Group
              onBlur={onBlur}
              onChange={onChange}
              selected={value}
              inputRef={ref}
              style={{ width: "100%", fontSize: "14px" }}
            >
              <Radio value={"originalWork"}>Original Work</Radio>
              <Radio value={"adaptation"}> Adaptation</Radio>
            </Radio.Group>
          )}
        />
      </div>
    </div>
  );
};

export default WorkType;
