import { Route, Switch } from "react-router-dom";
import SongDetail from "../../../CommonArea/CommonComponents/RegisterNewSong/SongDetail";
import SongLibraryTables from "./SongLibraryTables";

export default function SongLibrary() {
  return (
    <>
      <Switch>
        <Route path="/songLibrary">
          <SongLibraryTables />
        </Route>
        <Route path="/upload">
          <SongDetail />
        </Route>
      </Switch>
    </>
  );
}
