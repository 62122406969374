import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function ISRCTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="Each owner creates their own 12-digit ISRC using their Registrant Code. The pattern of the ISRC is as follows: CA-Y01-13-02401 (12 characters) – Country (CA) – First owner (Y01) – Year of release (13) – Sequence of five digits (02401).
                      Depending on the various metadata entry software and interfaces available on the market, the 12 characters are usually entered without spaces or hyphens..."
    >
      <i className="far fa-info-circle" onClick={props.showIsrc}></i>

      <Drawer
        title="ISRC#"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeIsrc}
        visible={props.isrc}
      >
        <h1>Entry Rule</h1>
        <p>
          Each owner creates their own 12-digit ISRC using their Registrant
          Code. The pattern of the ISRC is as follows: CA-Y01-13-02401 (12
          characters) – Country (CA) – First owner (Y01) – Year of release (13)
          – Sequence of five digits (02401). Depending on the various metadata
          entry software and interfaces available on the market, the 12
          characters are usually entered without spaces or hyphens.
        </p>
        <h1>Description</h1>
        <p>
          The International Standard Recording Code (ISRC) is the ISO 3901:2019
          standard (TC 46/SC 9 assignment subcommittee). ISO is the
          International Standard Organisation whose mission is to develop and
          publish international standards for all sectors.
        </p>
        <p>The ISRC is used to identify sound recordings (tracks).</p>
        <p>
          The Registrant Code is a prerequisite for the ISRC and is used to
          identify and distinguish the first owner. It consists of three
          alphanumerical characters that follow the two-character country code.
          Using this code, first owners can henceforth create their own ISRC
          codes.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
