import SearchBar from "../../../../CommonArea/CommonComponents/SearchBar";
import SongTable from "../../../../CommonArea/CommonComponents/Table";
import { Space, Popconfirm, Tooltip } from "antd";

export default function AdminRecentActivities() {
  const columns = [
    {
      title: "Transaction",
      key: "transaction",
      dataIndex: "transaction",
    },
    {
      title: "Date / Time",
      key: "dateTime",
      dataIndex: "dateTime",
    },
    {
      title: "Invoice / Receipt #",
      key: "invoice",
      dataIndex: "invoice",
    },
    {
      title: "Payment System",
      key: "paymentSystem",
      dataIndex: "paymentSystem",
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
    },
    {
      title: "Refund",
      key: "refund",
      dataIndex: "refund",
      render: () => (
        <Space size="middle" className="text-lg">
          <span className="text-green-500">
            <Popconfirm
              title="Are you sure you would like to make a refund for this account?"
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topLeft" title="Refund">
                <i className="fas fa-check"></i>
              </Tooltip>
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: `1`,
      transaction: `Memberhsip Purchased`,
      dateTime: `2018-05-20 1:30`,
      username: `Zoe`,
      invoice: `9282287`,
      paymentSystem: `Card`,
      amount: `$153`,
    },
    {
      key: `2`,
      transaction: `Memberhsip Purchased`,
      dateTime: `2018-05-20 1:30`,
      username: `Zoe`,
      invoice: `9282287`,
      paymentSystem: `Card`,
      amount: `$153`,
    },
    {
      key: `3`,
      transaction: `Memberhsip Purchased`,
      dateTime: `2018-05-20 1:30`,
      username: `Zoe`,
      invoice: `9282287`,
      paymentSystem: `Card`,
      amount: `$153`,
    },
  ];
  return (
    <div>
      <div className=" space-y-6 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Recent Activities
        </h3>
      </div>

      <div className="flex justify-between ">
        <div className="w-full">
          <SearchBar />
        </div>
      </div>
      <SongTable columns={columns} data={data} />
    </div>
  );
}
