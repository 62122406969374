import { useFormContext } from "react-hook-form";

export default function ResourceLeader() {
  const { register } = useFormContext();

  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Resouce Leader
        </h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="resourceLeader.specialization"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Specialization
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("resourceLeader.specialization", {
                required: false,
              })}
              id="resourceLeader.specialization"
              autoComplete="resourceLeader.specialization"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="resourceLeader.eventCodes"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Event Codes
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("resourceLeader.eventCodes", {
                required: false,
              })}
              id="resourceLeader.eventCodes"
              autoComplete="resourceLeader.eventCodes"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="resourceLeader.leaderAgreementsUrls"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Leader Agreements
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg flex justify-center px-6 py-3 border border-gray-300 rounded-md bg-white">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="resourceLeader.leaderAgreementsUrls"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span className="text-yellow-400"> Upload a file</span>
                      <input
                        id="resourceLeader.leaderAgreementsUrls"
                        type="file"
                        className="sr-only"
                        {...register("resourceLeader.leaderAgreementsUrls", {
                          required: false,
                        })}
                        multiple
                      />
                    </label>
                    <p className="pl-1 text-gray-600">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">DOCX, PDF up to 10MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
