import axios from 'axios';
import create from 'zustand'
import { userStore } from './userStore';

type AuthenticationStatus = 'Authenticated' | 'UnAuthenticated' | 'Loading';

type UserDetails = {
    username: string;
    displayName: string;
    profileUrl: string;
    onboardingCompleted: boolean;
}

type AuthStore = {
    authState: AuthenticationStatus;
    user: UserDetails | null;
    login: (payload: Object) => Promise<void>;
    verifyAuth: () => Promise<void>;
    logout: () => void;
};

export const authStore = create<AuthStore>(set => ({
    authState: 'Loading',
    user: null,
    login: async (payload: Object) => {
        try {
            const resp = await axios.post("/auth", { ...payload, RememberMe: true });
            userStore.getState().reset();
            set({authState: "Authenticated", user: resp.data})
        }
        catch {
            set({authState: "UnAuthenticated", user: null})
        }
    },
    verifyAuth: async () => {
        try {
            const resp = await axios.create().get("/auth", { withCredentials: true });
            set({ authState: "Authenticated", user: resp.data });
        }
        catch {
            set({authState: 'UnAuthenticated', user: null});
        }
    },
    logout: async () => {
        await axios.post('/auth/logout');
        set({authState: 'UnAuthenticated', user: null})
    }
}));