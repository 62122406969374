import { useEffect, useState } from "react";
import EmailVerificationStatus from "./EmailVerificationStatus";
import VerifyingEmail from "./VerifyingEmail";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../../../../utils/use-query";
import axios from "axios";

type VerificationStatus = "Verifying" | "Success" | "Error";

export default function EmailVerificationSteps() {
  const [status, setStatus] = useState<VerificationStatus>("Verifying");

  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const token = query.get("token");
    token ? makeVerificationRequest(token) : history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeVerificationRequest = (token: string) => {
    const payload = {
      registrationCompletionToken: token,
    };
    axios
      .post("/users/registration/complete", payload)
      .then(() => setStatus("Success"))
      .catch(() => setStatus("Error"));
  };

  return (
    <>
      <div className="sidebarBgImg min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
          <RenderVerificationStatusComponent status={status} />
        </div>
      </div>
    </>
  );
}

const RenderVerificationStatusComponent = (props: {
  status: VerificationStatus;
}): JSX.Element => {
  const checkIcon = (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
    </div>
  );
  const exclamationIcon = (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
    </div>
  );

  switch (props.status) {
    case "Verifying":
      return <VerifyingEmail />;
    case "Success":
      return (
        <EmailVerificationStatus
          statusIcon={checkIcon}
          messageHeading="Account Verification Successful"
          messageBody="Congratulations! your account has been verified successfully."
        />
      );
    case "Error":
      return (
        <EmailVerificationStatus
          statusIcon={exclamationIcon}
          messageHeading="Account Verification Failed"
          messageBody="We are sorry, we are failed to verify your account."
        />
      );
  }
};
