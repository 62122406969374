import { useFormContext } from "react-hook-form";

export default function StaffCheckbox(props) {
  const { register } = useFormContext();

  const data = {
    heading: props.heading,
    fieldName: props.fieldName,
  };

  return (
    <div className="sm:grid sm:grid-cols-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <div className="flex items-center">
        <span className="flex-grow">
          <input
            id={data.fieldName}
            {...register(`${data.fieldName}`, {
              required: false,
            })}
            type="checkbox"
            className="focus:ring-yellow-400 form-checkbox h-4 w-4 text-yellow-500 mr-2 transition duration-150 ease-in-out rounded"
          />
          {data.heading}
        </span>
      </div>
    </div>
  );
}
