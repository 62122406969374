import React from "react";
import { Drawer } from "antd";
import ReactTooltip from "react-tooltip";

export default function ISWCTooltip(props) {
  return (
    <span
      className="pl-1 hover:text-yellow-400"
      data-tip="The ISWC code consists of the letter “T” followed by nine digits and one final numeric check digit. For example: T-012345678-1 (a total of 11 characters). The use of hyphens depends on the various metadata entry software and interfaces available on the market. We recommend not using them..."
    >
      <i className="far fa-info-circle" onClick={props.showIswcTooltip}></i>
      <Drawer
        title="ISWC"
        width={520}
        placement="right"
        closable={false}
        onClose={props.closeIswcTooltip}
        visible={props.iswcTooltip}
      >
        <h1>Entry Rule</h1>
        <p>
          The ISWC code consists of the letter “T” followed by nine digits and
          one final numeric check digit. For example: T-012345678-1 (a total of
          11 characters). The use of hyphens depends on the various metadata
          entry software and interfaces available on the market. We recommend
          not using them.
        </p>
        <h1>Description</h1>
        <p>
          The International Standard Work Code (ISWC) allows the identification
          of music works. It is the ISO 15707:2001 standard (TC 46/SC 9
          assignment subcommittee). ISO is the International Standard
          Organisation whose mission is to develop and publish international
          standards for all sectors.
        </p>
        <p>
          It is also an identifier managed by CISAC. The industry has made
          considerable efforts to connect the ISWC with the ISRCto better link
          musical works to their recordings.
        </p>
        <h1>Use</h1>
        <p>
          This is a mandatory field. The code is used when SOCAN communicates
          with other similar Collective Management Organizations in Canada and
          abroad as well as other third parties such as music publishers:
          http://www.iswc.org/en/faq.html.
        </p>
      </Drawer>
      <ReactTooltip
        type="light"
        effect="solid"
        className="w-80 border-2 border-gray-300 ml-5"
      />
    </span>
  );
}
