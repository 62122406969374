import { useState } from "react";
import { useHistory } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StorageBar() {
  const [progressBarWidth] = useState(70);

  const history = useHistory();
  const checkout = () => {
    history.push("/editDetails/plans");
  };

  return (
    <div className="ml-1 lg:mx-8 flex flex-col">
      <div className="">
        <h1 className="text-xl mt-2">Current Usage</h1>
        <h1>Storage: </h1>
        <p>You are using 21 MB of 30 MB</p>
        <div className="shadow w-full bg-grey-light mt-2 rounded-lg border border-gray-300 bg-gray-100">
          <div className="w-full ">
            <div
              style={{ width: `${progressBarWidth}%` }}
              className={classNames(
                progressBarWidth < 50 && progressBarWidth > "0"
                  ? "border-yellow-40 bg-green-500"
                  : " text-xs leading-none py-1 text-center rounded-lg text-white",

                progressBarWidth >= 50 && progressBarWidth < 100
                  ? "border-transparent bg-yellow-400 text-white hover:border-gray-300"
                  : " text-xs leading-none py-1 text-center rounded-lg text-white",
                progressBarWidth === 100 &&
                  "border-transparent bg-red-500 text-white hover:border-gray-300"
              )}
            >
              {progressBarWidth}%
            </div>
          </div>
        </div>
        {progressBarWidth < 50 && progressBarWidth > "0" ? (
          <p className="mt-2">
            You have more than half of your space limit available.
          </p>
        ) : null}
        {progressBarWidth >= 50 && progressBarWidth < 100 ? (
          <p className="mt-2">
            You have used more than half of your space limit.
          </p>
        ) : null}
        {progressBarWidth === 100 && (
          <p className="mt-2">
            You have no space left. Click “Upgrade today“ to purchase additional
            space or delete the existing songs.
          </p>
        )}
        <span className="inline-flex justify-center rounded-md shadow-sm mt-5 ">
          <button
            type="button"
            onClick={checkout}
            className="bg-gray-200 text-gray-700 shadow font-medium w-full hover:bg-yellow-400 items-center px-5 py-3 border border-gray-300 text-sm leading-5  rounded-md text-cool-gray-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          >
            Upgrade Today
          </button>
        </span>
      </div>
    </div>
  );
}
