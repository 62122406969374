import React from "react";
import { useFormContext } from "react-hook-form";

export default function Education() {
  const { register } = useFormContext();
  return (
    <div className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-5">
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <h3 className="text-xl leading-6 font-medium text-gray-900">
          Education
        </h3>
      </div>

      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="education.institution"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Institution Name
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("education.institution", {
                required: false,
              })}
              id="education.institution"
              autoComplete="education.institution"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="education.studentNumber"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Student ID
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("education.studentNumber", {
                required: false,
              })}
              id="education.studentNumber"
              autoComplete="education.studentNumber"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="education.studyYear"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Year of Study
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="education.studyYear"
              {...register("education.studyYear", {
                required: false,
              })}
              defaultValue=""
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select Year of study
              </option>
              <option value="1">Year 1</option>
              <option value="2">Year 2</option>
              <option value="3">Year 3</option>
              <option value="4">Year 4</option>
              <option value="5">Year 5</option>
              <option value="6">Year 6</option>
              <option value="7">Year 7</option>
              <option value="8">Year 8</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="education.completionYear"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Completion Year
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="education.completionYear"
              {...register("education.completionYear", {
                required: false,
              })}
              defaultValue=""
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Select Completion Year
              </option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
            </select>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="education.studentCardUrl"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Student ID Card Upload
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="education.studentCardUrl"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span className="text-yellow-400"> Upload a file</span>
                    <input
                      id="education.studentCardUrl"
                      name="education.studentCardUrl"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1 text-gray-600">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Required for active Student memberships only.
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="education.degree"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Degree
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              {...register("education.degree", {
                required: false,
              })}
              id="education.degree"
              autoComplete="education.degree"
              className="focus:ring-yellow-300 max-w-lg focus:outline-none focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
