import { Link } from "react-router-dom";

export default function Dashboard(props) {
  const cards = props.cards
  return (
    <>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {/* Card */}
        {cards.map((card) => (
          <div
            key={card.name}
            className="bg-white overflow-hidden shadow rounded-lg"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <card.icon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-5 mt-2 w-0 flex-1">
                  <dl>
                    <dt className="text-base font-medium text-gray-500 truncate">
                      {card.name}
                    </dt>
                    {/* <dd>
                      <div className="text-lg font-medium text-gray-900">
                        {card.amount}
                      </div>
                    </dd> */}
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <Link to={card.pathname} className="font-medium text-gray-800 ">
                  {card.pathDetails}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
