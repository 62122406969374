import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function StudentAccount() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="">
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Institution Name</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <input
              className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              id="education.institution"
              type="text"
              {...register("education.institution", {
                required: "This field is mandatory",
                shouldUnregister: true,
              })}
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="education.institution"
              className="text-red-500 m-0"
            />
          </span>
        </dd>
      </div>

      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Student Number</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <input
              className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              id="education.studentNumber"
              type="text"
              {...register("education.studentNumber", {
                required: "This field is mandatory",
                shouldUnregister: true,
              })}
            />
            <ErrorMessage
              as="p"
              errors={errors}
              name="education.studentNumber"
              className="text-red-500 m-0"
            />
          </span>
        </dd>
      </div>

      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Year of Study</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <select
              id="educatoin.studyYear"
              {...register("education.studyYear", {
                required: "This field is mandatory",
                shouldUnregister: true,
              })}
              defaultValue=""
              className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Year of Study
              </option>
              <option value="1">Year 1</option>
              <option value="2">Year 2</option>
              <option value="3">Year 3</option>
              <option value="4">Year 4</option>
              <option value="5">Year 5</option>
              <option value="6">Year 6</option>
              <option value="7">Year 7</option>
              <option value="8">Year 8</option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="education.studyYear"
              className="text-red-500 m-0"
            />
          </span>
        </dd>
      </div>

      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Completion Year</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <select
              id="education.completionYear"
              {...register("education.completionYear", {
                required: "This field is mandatory",
                shouldUnregister: true,
              })}
              defaultValue=""
              className="focus:outline-none focus:ring-yellow-400 focus:border focus:border-yellow-300 focus:shadow hover:border-yellow-300 p-2 rounded-md border border-gray-300 block w-80 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option value="" disabled>
                Completion Year
              </option>
              <option value="1">2021</option>
              <option value="2">2022</option>
              <option value="3">2023</option>
              <option value="4">2024</option>
              <option value="5">2025</option>
              <option value="6">2026</option>
              <option value="7">2027</option>
              <option value="8">2028</option>
            </select>
            <ErrorMessage
              as="p"
              errors={errors}
              name="education.completionYear"
              className="text-red-500 m-0"
            />
          </span>
        </dd>
      </div>

      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Student Card</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <div className="w-80 flex justify-center px-6 pt-5 pb-6 border border-gray-300 rounded-md bg-white">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-8 w-8 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <label
                  htmlFor="education.studentCard"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-yellow-500 hover:text-yellow-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500"
                >
                  <span>Upload your student ID</span>
                  <input
                    id="education.studentCard"
                    // {...register("education.studentCard", {
                    //   required: "This field is required",
                    // })}
                    type="file"
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
            {/* <ErrorMessage
              as="p"
              errors={errors}
              name="education.studentCard"
              className="text-red-500 m-0"
            /> */}
          </span>
        </dd>
      </div>
    </div>
  );
}
