import React from "react";
import { useState } from "react";

export default function Membership() {

  //Pro
  const [isProStatusOn, setIsProStatusOn] = useState(false);
  const [isProMemberContentOn, setIsProMemberContentOn] = useState(false);
  const [isProSongVaultOn, setIsProSongVaultOn] = useState(false);
  const [isProSongPitchOn, setIsProSongPitchOn] = useState(false);
  const [isProServiceGOn, setIsProServiceGOn] = useState(false);

  // Regular 
  // const [isCalenderOn, setIsCalenderOn] = useState(false);
  const [isRegularStatusOn, setIsRegularStatusOn] = useState(false);
  const [isRegularMemberContentOn, setIsRegularMemberContentOn] = useState(false);
  const [isRegularSongVaultOn, setIsRegularSongVaultOn] = useState(false);
  const [isRegularSongPitchOn, setIsRegularSongPitchOn] = useState(false);
  const [isRegularServiceGOn, setIsRegularServiceGOn] = useState(false);

  // Basic
  const [isBasicStatusOn, setIsBasicStatusOn] = useState(false);
  const [isBasicMemberContentOn, setIsBasicMemberContentOn] = useState(false);
  const [isBasicSongVaultOn, setIsBasicSongVaultOn] = useState(false);
  const [isBasicSongPitchOn, setIsBasicSongPitchOn] = useState(false);
  const [isBasicServiceGOn, setIsBasicServiceGOn] = useState(false);

  return (
    <div>
      <div className="flex flex-col ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 table-auto">
                <thead>
                  <tr>
                    <th className=" px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-sm leading-4  ium text-gray-500 uppercase tracking-wider">
                      Membership Title
                    </th>
                    <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-sm leading-4  ium text-gray-500 uppercase tracking-wider">
                      Price
                    </th>
                    <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-sm leading-4  ium text-gray-500 uppercase tracking-wider">
                      Currency
                    </th>
                    <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-sm leading-4  ium text-gray-500 uppercase tracking-wider">
                      Frequency
                    </th>
                    <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-sm leading-4  ium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 border-2 border-gray-200 text-center py-3 bg-gray-50 text-left text-sm leading-4  ium text-gray-500 uppercase tracking-wider">
                      Activated Subscriptions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white">
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      Pro
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      Complimentary
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      CDN
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      <select id="location"
                        className="form-select text-center block text-sm text-cool-gray-600 leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-md sm:leading-5"
                      >
                        <option defaultValue>Annual</option>
                        <option>Bi-Annual</option>
                        <option>Lifetime</option>
                      </select>
                    </td>
                    <td className="px-6   py-4 whitespace-no-wrap text-sm leading-5 font-medium">
                      <div
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isProStatusOn}
                          tabIndex="0"
                          onClick={() => setIsProStatusOn(!isProStatusOn)}
                          className={`${isProStatusOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isProStatusOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      </div>
                    </td>
                    <td className="px-2   py-5  whitespace-no-wrap text-sm  flex justify-around text-gray-500">
                      <div
                        className="flex "
                      >
                        <span
                          role="checkbox"
                          aria-checked={isProMemberContentOn}
                          tabIndex="0"
                          onClick={() => setIsProMemberContentOn(!isProMemberContentOn)}
                          className={`${isProMemberContentOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isProMemberContentOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      Member Content
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isProSongVaultOn}
                          tabIndex="0"
                          onClick={() => setIsProSongVaultOn(!isProSongVaultOn)}
                          className={`${isProSongVaultOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isProSongVaultOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      SongVault
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isProSongPitchOn}
                          tabIndex="0"
                          onClick={() => setIsProSongPitchOn(!isProSongPitchOn)}
                          className={`${isProSongPitchOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isProSongPitchOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      SongPitch
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isProServiceGOn}
                          tabIndex="0"
                          onClick={() => setIsProServiceGOn(!isProServiceGOn)}
                          className={`${isProServiceGOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isProServiceGOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      Service G
                      </div>
                    </td>
                  </tr>

                  <tr className="bg-gray-50">
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      Regular
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      $60
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      CDN
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      <select id="location"
                        className="form-select text-center block text-sm text-cool-gray-600 leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-md sm:leading-5"
                      >
                        <option defaultValue>Annual</option>
                        <option>Bi-Annual</option>
                        <option>Lifetime</option>
                      </select>
                    </td>
                    <td className="px-6  py-4 whitespace-no-wrap text-sm leading-5 font-medium">
                      <div
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isRegularStatusOn}
                          tabIndex="0"
                          onClick={() => setIsRegularStatusOn(!isRegularStatusOn)}
                          className={`${isRegularStatusOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isRegularStatusOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      </div>
                    </td>
                    <td className="px-2  py-5 whitespace-no-wrap text-sm  flex   justify-around text-gray-500">
                      <div
                        className="flex "
                      >
                        <span
                          role="checkbox"
                          aria-checked={isRegularMemberContentOn}
                          tabIndex="0"
                          onClick={() => setIsRegularMemberContentOn(!isRegularMemberContentOn)}
                          className={`${isRegularMemberContentOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isRegularMemberContentOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      Member Content
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isRegularSongVaultOn}
                          tabIndex="0"
                          onClick={() => setIsRegularSongVaultOn(!isRegularSongVaultOn)}
                          className={`${isRegularSongVaultOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isRegularSongVaultOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      SongVault
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isRegularSongPitchOn}
                          tabIndex="0"
                          onClick={() => setIsRegularSongPitchOn(!isRegularSongPitchOn)}
                          className={`${isRegularSongPitchOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isRegularSongPitchOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      SongPitch
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isRegularServiceGOn}
                          tabIndex="0"
                          onClick={() => setIsRegularServiceGOn(!isRegularServiceGOn)}
                          className={`${isRegularServiceGOn ? 'bg-yellow-400' : 'bg-gray-200'}  relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isRegularServiceGOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      Service G
                      </div>
                    </td>
                  </tr>

                  <tr className="bg-white">
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      Basic
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      Free
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      CDN
                    </td>
                    <td className="px-6  text-center py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      <select id="location"
                        className="form-select text-center block text-sm text-cool-gray-600 leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-md sm:leading-5"
                      >
                        <option defaultValue>Annual</option>
                        <option>Bi-Annual</option>
                        <option>Lifetime</option>
                      </select>
                    </td>
                    <td className="px-6   py-4 whitespace-no-wrap text-sm leading-5 font-medium">
                      <div
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isBasicStatusOn}
                          tabIndex="0"
                          onClick={() => setIsBasicStatusOn(!isBasicStatusOn)}
                          className={`${isBasicStatusOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isBasicStatusOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      </div>
                    </td>
                    <td className="px-2   py-5  whitespace-no-wrap text-sm  flex justify-around text-gray-500">
                      <div
                        className="flex "
                      >
                        <span
                          role="checkbox"
                          aria-checked={isBasicMemberContentOn}
                          tabIndex="0"
                          onClick={() => setIsBasicMemberContentOn(!isBasicMemberContentOn)}
                          className={`${isBasicMemberContentOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isBasicMemberContentOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      Member Content
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isBasicSongVaultOn}
                          tabIndex="0"
                          onClick={() => setIsBasicSongVaultOn(!isBasicSongVaultOn)}
                          className={`${isBasicSongVaultOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isBasicSongVaultOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      SongVault
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isBasicSongPitchOn}
                          tabIndex="0"
                          onClick={() => setIsBasicSongPitchOn(!isBasicSongPitchOn)}
                          className={`${isBasicSongPitchOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isBasicSongPitchOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      SongPitch
                      </div>
                      <div
                        className="flex"
                      >
                        <span
                          role="checkbox"
                          aria-checked={isBasicServiceGOn}
                          tabIndex="0"
                          onClick={() => setIsBasicServiceGOn(!isBasicServiceGOn)}
                          className={`${isBasicServiceGOn ? 'bg-yellow-400' : 'bg-gray-200'} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                        >
                          <span
                            aria-hidden="true"
                            className={`${isBasicServiceGOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                          ></span>
                        </span>
                      Service G
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
