import { SearchIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";

export default function SearchBar() {
  const { register } = useForm();

  return (
    <>
      <label htmlFor="search_field" className="sr-only">
        Search
      </label>
      <div className="relative w-full text-gray-400 focus-within:text-gray-600 flex text-sm">
        <input
          {...register("search")}
            className="shadow-sm focus:ring-yellow-400 text-md focus:border-yellow-400 hover:border-yellow-300 block w-full border-gray-300 rounded-md"
          
          placeholder="Search"
          type="search"
        />
        <button className="bg-gray-300 text-cool-gray-600 py-2 px-2 mx-1 rounded-lg focus:outline-none hover:bg-yellow-400 hover:text-white">
          <SearchIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </>
  );
}
